import { API_URLS }  from '../../apiUrls';
import { ActionType } from '../actionTypes';
import { makeHeaders, makeParams, fetchData } from '../../utils/apiUtils';
import { RootState, HotelNote, ThunkResult, ThunkDispatchType, FetchReturn } from '..';

export const fetchHotelNotes = (leader: number): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    const params = makeParams({leader});

    return fetchData(API_URLS.HOTEL_NOTES + params, { headers, }).then((res: FetchReturn): void  => {
      if (res.status === 200) {
        dispatch({ type: ActionType.FETCHED_HOTEL_NOTES, hotelNotes: res.data });
      }
    });
  };

export const createHotelNote = (note: HotelNote): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    // Copy all HotelNote data except remove id and only take the ID of the hotel
    const hotelId = note.hotel_data.id;
    const { id, hotel_data, ...newNote } = note;
  
    // If an hotel was selected, add the ID
    // If an hotel was selected, add the hotel data
    let body = '';
    if (hotelId > 0) {
      body = JSON.stringify({...newNote, hotel: hotelId});
    } else {
      body = JSON.stringify({...newNote, hotel_data});
    }
    
    return fetchData(API_URLS.HOTEL_NOTES, { headers, method: 'POST', body}).then((res: FetchReturn): void  => {
      if (res.status === 201) {
        dispatch({ type: ActionType.CREATED_HOTEL_NOTE, hotelNote: res.data });
      }
    });
  };

export const updateHotelNote = (note: HotelNote): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify(note);
    return fetchData(API_URLS.HOTEL_NOTES + note.id + '/',
      { headers, method: 'PATCH', body }).then((res: FetchReturn): void => {
      if (res.status === 200) {
        dispatch({ type: ActionType.UPDATED_HOTEL_NOTE, hotelNote: res.data});
      }
    });
  };

export const deleteHotelNote = (noteId: number): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);

    return fetchData(API_URLS.HOTEL_NOTES + noteId + '/', { headers, method: 'DELETE'})
      .then((res: FetchReturn): void => {
        dispatch({ type: ActionType.DELETED_HOTEL_NOTE, hotelNoteId: noteId});
      });
  };