const CF_DIST = import.meta.env.VITE_CF_DIST;

export const WHITE_LOGO_SRC = `https://${CF_DIST}/static-images/new-logo-white.png`;
export const NAVY_LOGO_SRC = `https://${CF_DIST}/static-images/new-logo-navy.png`;
export const NEW_LEADER_SRC = `https://${CF_DIST}/app/static-files/NewLeader.svg`;
export const NEW_AIRLINE_SRC = `https://${CF_DIST}/app/static-files/airlines/NewAirline.png`;
export const NEW_HOTEL_SRC = `https://${CF_DIST}/app/static-files/hotels/NewHotel.png`;
export const ONBOARD_COMPLETE_SRC = `https://${CF_DIST}/static-images/onboard-complete.png`;
export const ONBOARD_PROFILES_SRC = `https://${CF_DIST}/static-images/no-profile-notes.png`;
export const ONBOARD_TASKLIST_SRC = `https://${CF_DIST}/static-images/onboarding-tasklist.png`;
export const MAINTENANCE_GRAPHIC = `https://${CF_DIST}/static-images/maintenance.jpg`;
export const NO_LEADER_AVATAR_SRC = `https://${CF_DIST}/app/static-files/NoLeaderAvatar.png`;
export const NO_NOTES_GRAPHIC_SRC = `https://${CF_DIST}/app/static-files/empty-profile-category.svg`;

export const TODO_ALL_COMPLETED_SRC = 
  `https://${CF_DIST}/app/static-files/empty-todo-list.svg`;
export const DOWNLOAD_ON_APP_STORE_SRC = 
  `https://${CF_DIST}/app/static-files/DownloadOnAppStore.png`;
export const DOWNLOAD_ON_PLAY_STORE_SRC = 
  'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png';

export const ONBOARD_ANALYTICS =
  `https://${CF_DIST}/app/static-files/onboard-analytics.svg`;
export const ONBOARD_PROFILES =
  `https://${CF_DIST}/app/static-files/onboard-v2-profiles.png`;

export const PROFILE_GRAPHIC = `https://${CF_DIST}/app/static-files/profile-graphic.png`;

export const FIRST_NO_TASKS_LEFT = `https://${CF_DIST}/app/static-files/first-no-tasks-left.png`;
export const FIRST_NO_TASKS_RIGHT = `https://${CF_DIST}/app/static-files/first-no-tasks-right.png`;
export const GOOGLE_LOGO = `https://${CF_DIST}/static-images/google-icon.svg`;
export const GOOGLE_CALENDAR_LOGO = `https://${CF_DIST}/static-images/google-calendar-icon.svg`;
export const GOOGLE_SIGN_IN_BUTTON = `https://${CF_DIST}/static-images/google_sign_in.png`;
export const MS_SIGN_IN_BUTTON = `https://${CF_DIST}/static-images/ms_sign_in.png`;
export const MS_LOGO = `https://${CF_DIST}/static-images/ms-icon.svg`;
export const MS_CALENDAR_LOGO = `https://${CF_DIST}/static-images/ms-calendar-icon.svg`;
export const ZOOM_SIGN_IN_BUTTON = `https://${CF_DIST}/static-images/zoom_sign_in.png`;
export const ZOOM_SHARED_ACCESS_PERMISSIONS = `https://${CF_DIST}/app/static-files/zoom-shared-permission.png`;
export const ZOOM_SHARED_ACCESS_PERMISSIONS_UPDATE = `https://${CF_DIST}/app/static-files/zoom-update-permission.png`;
export const SALESFORCE_SIGN_IN_BUTTON = `https://${CF_DIST}/static-images/salesforce_sign_in.png`;

export const CABINET_LOGO_SECONDARY = `https://${CF_DIST}/static-images/secondary-logo-transparent.png`;
export const CHANGE_EMAIL_GRAPHIC = `https://${CF_DIST}/app/static-files/change_emails.png`;

export const CABINET_ICONS_URL = `https://${CF_DIST}/app/static-files/icons`;

// NOTE: Leaving this here so we remember it exists but are not using it
// const FULL_CALENDAR_SCOPES_IMAGE = `
// ${import.meta.env.VITE_CF_DIST}/app/static-files/google-oauth-calendar-full-scope.jpg
// `;
export const INCREMENTAL_CALENDAR_SCOPES_IMAGE =
  `https://${CF_DIST}/app/static-files/google-oauth-calendar-incremental-scope.jpg`;

export const CAL_ANALYTICS_LOADING = `https://${CF_DIST}/app/static-files/cal-analytics-loading.png`;
