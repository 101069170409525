import { ActionType } from '../actionTypes';
import { MeetingType } from '../schedule/types';

import { CabUIState, MeetingLogColumnHide, MeetingLogColumnSort, MeetingLogDataFilter } from './types';

export const setCabNavbarExpanded = (expanded: boolean) => {
  return { type: ActionType.SET_CAB_NAVBAR_EXPANDED, navbarExpanded: expanded };
};

export const setFetchError = (fetchError: CabUIState["fetchError"]) => {
  return { type: ActionType.SET_FETCH_ERROR, error: fetchError };
};

export const removeFetchError = () => {
  return { type: ActionType.SET_FETCH_ERROR, error: {status_code: 0, message: ""} };
};

export const setMeetingLogFilter = (meetingType: MeetingType, filter: MeetingLogDataFilter) => {
  return { type: ActionType.SET_MEETING_LOG_FILTER, meetingType, filter };
};

export const setMeetingLogColumnSort = (meetingType: MeetingType, sort: MeetingLogColumnSort) => {
  return { type: ActionType.SET_MEETING_LOG_COLUMN_SORT, meetingType, sort };
};

export const setMeetingLogColumnHide = (meetingType: MeetingType, hide: MeetingLogColumnHide) => {
  return { type: ActionType.SET_MEETING_LOG_COLUMN_HIDE, meetingType, hide };
};
