import { ActionType } from '../actionTypes';
import { GlobalMessages, GlobalMessage } from './types';
import { RootAction } from '..';

export const initialState: GlobalMessages = [];

export default function globalMessages(state = initialState, action: RootAction): typeof initialState {
  const newState = state.slice();
  
  switch (action.type) {
    case ActionType.REMOVE_MESSAGE:
      newState.shift();
      return newState;
    
    case ActionType.SEND_MESSAGE: {
      let exists = false;
      state.forEach((message: GlobalMessage): void => {
        if (message.message === action.message.message) {
          exists = true;
        }
      });
      //only add the message if it doesnt already exist!!
      if (!exists) {
        newState.push(action.message);
      }
      return newState;
    }
    default:
      return state;
  }
}