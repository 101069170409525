import { ActionType } from '../actionTypes';

export enum GlobalModalComponentName {
  CABINET_PROMO,
  TRIAL_EXPIRED
}

export interface GlobalModal {
  componentName: GlobalModalComponentName,
  open: boolean
}

export type GlobalModalAction =
 {type: ActionType.OPEN_GLOBAL_MODAL; componentName: GlobalModalComponentName} |
 {type: ActionType.CLOSE_GLOBAL_MODAL};