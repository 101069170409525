import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkAction } from '@reduxjs/toolkit';
import { ThunkDispatchType } from './types';
import { RootAction, RootState } from '.';


export const useWithDispatch = <Args extends unknown[], T>(
  fn: (...args: Args) => ThunkAction<T, RootState, null, RootAction>
) => {
// export const useWithDispatch = <T>(fn: ActionCreator<ThunkAction<T, RootState, null, RootAction>>) => {
// export const useWithDispatch = <T extends string, Payload>(fn: ActionCreatorWithPayload<Payload, T>) => {
  const dispatch = useDispatch<ThunkDispatchType>();

  return useCallback((...args: Args) => dispatch(fn(...args)), [dispatch, fn]);
  // return useCallback((args: Payload) => dispatch(fn(args)), [dispatch, fn]);
};
