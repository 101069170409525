import { API_URLS }  from '../../apiUrls';
import { ActionType } from '../actionTypes';
import { ThunkResult, ThunkDispatchType, FetchReturn } from '../types';
import { makeHeaders, fetchData } from '../../utils/apiUtils';
import { RootState } from '..';
import { BILLING_INTERVAL, TIER } from '../../constants';
import { AccountOwner } from '../auth/types';
import { 
  Organization,
  OrganizationLicense,
  OrganizationSubmission,
  SubscriptionDetails
} from "./types";
import api from '../../api';
import { sendMessage } from '../globalMessages/actions';


export const fetchOrgUserAutocompleteOptions = (q?: string): ThunkResult<Promise<void>> => 
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    return api.fetchOrgUserAutocompleteOptions(q).then((res): void => {
      if (res.status === 200) {
        dispatch({type: ActionType.FETCHED_ORG_USER_AUTOCOMPLETE_OPTIONS, options: res.data});
      }
    });
  };


export const fetchSubscriptionDetails = (): ThunkResult<Promise<void | undefined>> => 
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void | undefined> => {
    
    const headers = await makeHeaders(true);
    
    return fetchData(API_URLS.MY_SUBSCRIPTION_DETAILS, {headers, method: 'GET'})
      .then((res: FetchReturn): void => {
        if (res.status === 200) {
          dispatch({type: ActionType.FETCHED_SUBSCRIPTION_DETAILS, subscriptionDetails: res.data});
        }
      });
  };

export const changeSubscription = (
  newTier: TIER | null, newInterval: BILLING_INTERVAL | null, newQuantity: number | null, 
  newPromoCode: string | null, isPreview = false, prorationDate: string | null = null
): ThunkResult<Promise<FetchReturn<SubscriptionDetails> | undefined>> => 
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<SubscriptionDetails> | undefined> => {
    const body = JSON.stringify({newTier, newInterval, newQuantity, newPromoCode, isPreview, prorationDate});

    const headers = await makeHeaders(true);
    const res = await fetchData<SubscriptionDetails>(
      API_URLS.CHANGE_SUBSCRIPTION, {headers, method: 'POST', body}
    );
    if (res.status === 200) {
      if (!isPreview) {
        dispatch({type: ActionType.CHANGED_SUBSCRIPTION, subscriptionDetails: res.data});
      }
    }
    return res;
  };


function parseFormDataValue(
  value: string | { [id: number]: OrganizationLicense; } | number | number[] | boolean | null | File | AccountOwner
): string | File {
  if (typeof value === "string") {
    return value;
  } else if (typeof value === "boolean" || typeof value === "number") {
    return value.toString();
  } else if (value instanceof File) {
    return value;
  } else {
    try {
      return JSON.stringify(value);
    } catch {
      throw Error("parseFormDataValue cannot parse value");
    }
  }
}

function cleanOrganizationSubmission(submitOrg: OrganizationSubmission): FormData {
  const {logo, logo_file, ...cleanRecord} = submitOrg;
  const formData = new FormData();
  Object.entries(cleanRecord).forEach(entry => {    
    formData.append(entry[0], parseFormDataValue(entry[1]));
  });
  if (logo_file != null) {
    formData.set("logo", logo_file);
  }
  return formData;
}

export const updateOrganization = (
  org: OrganizationSubmission
): ThunkResult<Promise<FetchReturn<Organization> | undefined>> => 
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<FetchReturn<Organization> | undefined> => {
    const hasContentEditor = getState().auth.user?.permissions.CONTENT_EDITOR;
    if (hasContentEditor) {
      const headers = await makeHeaders(true);
      if (headers) {
        delete headers["Content-Type"];
      }
      const cleanData = cleanOrganizationSubmission(org);
      const res = await fetchData(`${API_URLS.ORGANIZATION()}${org.id}/`, {headers, method: 'PATCH', body: cleanData});
      if (res.status === 200) {
        dispatch({type: ActionType.UPDATE_ORGANIZATION, org: res.data});
        return res;
      }
    }
  };

export const processOrganizationLogo = (
  org: OrganizationSubmission
): ThunkResult<Promise<FetchReturn<{data: string}>>> => 
  async (): Promise<FetchReturn<{data: string}>> => {
    const headers = await makeHeaders(true);
    if (headers) {
      delete headers["Content-Type"];
    }
    const cleanData = cleanOrganizationSubmission(org);
    return await fetchData(`${API_URLS.ORGANIZATION_PROCESS_LOGO}`, {headers, method: 'POST', body: cleanData});
  };

export const listOrganizationLicenses = (
  value: number
): ThunkResult<Promise<FetchReturn<{[id: number]: OrganizationLicense}> | undefined>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<{[id: number]: OrganizationLicense}> | undefined> => {
    const data = await api.listOrganizationLicenses(value);
    if (data.status === 200) {
      dispatch(
        {
          type: ActionType.UPDATE_ORGANIZATION_LICENSES,
          licenses: data.data
        }
      );
      return data;
    }
  };

export const createOrganizationLicenses = (
  value: {[id: number]: OrganizationLicense}
): ThunkResult<Promise<FetchReturn<{created: {[id: number]: OrganizationLicense}}, string>>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<{created: {[id: number]: OrganizationLicense}}>> => {
    const res = await api.createOrganizationLicenses(value);
    const state = getState();
    if (res.status === 201) {
      dispatch(
        {
          type: ActionType.UPDATE_ORGANIZATION_LICENSES,
          licenses: {...res.data.created, ...state.organization.licenses }
        }
      );
    }
    
    return res;
  };

export const bulkUpdateOrganizationLicenses = (
  value: { [id: number]: Partial<OrganizationLicense> }
): ThunkResult<Promise<FetchReturn<{updated: {[id: number]: OrganizationLicense}}> | undefined>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<{updated: {[id: number]: OrganizationLicense}}> | undefined> => {
    const hasLicenseTable = getState().auth.user?.permissions.ORGANIZATION_LICENSE_TABLE;
    if (hasLicenseTable) {
      const res = await api.bulkUpdateOrganizationLicenses(value);
      const state = getState();
      
      if (res.status === 200) {
        dispatch({
          type: ActionType.UPDATE_ORGANIZATION_LICENSES,
          licenses: {...state.organization.licenses, ...res.data["updated"]}
        });

        if (state.auth.user?.active_license.id && res.data.updated[state.auth.user?.active_license.id]) {
          dispatch({
            type: ActionType.UPDATED_USER_ACTIVE_LICENSE,
            license: res.data.updated[state.auth.user?.active_license.id],
          });
        }
      }
    
      return res;
    }
  };

export const updateOrganizationLicense = (
  value:Partial<OrganizationLicense> & {
    id: number;
  }
): ThunkResult<Promise<FetchReturn<OrganizationLicense> | undefined>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<FetchReturn<OrganizationLicense> | undefined> => {
    const res = await api.updateOrganizationLicense(value);
    const state = getState();
    
    if (res.status === 200) {
      dispatch({
        type: ActionType.UPDATE_ORGANIZATION_LICENSES,
        licenses: {...state.organization.licenses, [res.data.id]: res.data}
      });

      dispatch({
        type: ActionType.UPDATED_USER_ACTIVE_LICENSE,
        license: res.data
      });
    }
    return res;
  };
  
export const deleteOrganizationLicenses = (
  value: number[]
): ThunkResult<Promise<FetchReturn<{deleted: number[]}>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<FetchReturn<{deleted: number[]}>> => {
    const res = await api.deleteOrganizationLicenses(value);
    const {organization} = getState();
    const updateLicenses = {...organization.licenses};

    if (res.status === 200) {
      res.data["deleted"].forEach(id => delete updateLicenses[id]);

      dispatch(
        {
          type: ActionType.UPDATE_ORGANIZATION_LICENSES,
          licenses: updateLicenses
        }
      );
    }
    return res;
  };


export const fetchOrganization = (id: number): ThunkResult<Promise<FetchReturn<Organization> | undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<FetchReturn<Organization> | undefined> => {
    const res = await api.fetchOrganization(id);
    if (res.status === 200) {
      dispatch({type: ActionType.SET_ORGANIZATION, org: res.data});
      return res;
    }
  };

export const resendLicenseInvite = (
  license: OrganizationLicense
) => async (
  dispatch: ThunkDispatchType, getState: () => RootState
): Promise<FetchReturn<{detail: string}> | undefined> => {
  const res = await api.resendLicenseInvite(license);
  if (res.status === 200) {
    dispatch(sendMessage({
      timeout: 5000,
      message: `Invite to ${license.email} has been resent`,
      position: {
        vertical: "bottom",
        horizontal: "left"
      },
      active: true,
      severity: "success",
      header: "",
      autoDismiss: false
    } ));
  } else {
    dispatch(sendMessage({
      timeout: 5000,
      message: `Invite to ${license.email} was not resent`,
      position: {
        vertical: "bottom",
        horizontal: "left"
      },
      active: true,
      severity: "error",
      header: "",
      autoDismiss: false
    } ));
  }
  return res;
};
