import { ActionType } from '../actionTypes';
import { GlobalModal, GlobalModalComponentName } from './types';
import { RootAction } from '..';

export const initialState: GlobalModal = {
  componentName: GlobalModalComponentName.CABINET_PROMO,
  open: false
};

export default function globalModal(state = initialState, action: RootAction): typeof initialState {  
  switch (action.type) {
    case ActionType.OPEN_GLOBAL_MODAL:
      return {componentName: action.componentName, open: true};
    
    case ActionType.CLOSE_GLOBAL_MODAL:
      return {...state, open: false};

    default:
      return state;
  }
}