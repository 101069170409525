import { ReactElement, useMemo, useState, useCallback, useEffect } from "react";
import {
  Select, SelectProps, MenuItem, ListItemText, AvatarGroup, ListSubheader, Divider, MenuItemProps, styled, Box
} from '@mui/material';
import { CabComponentProps } from "../cabStyled";
import { CabCheckbox } from "../CabCheckbox";
import colors from "../../../colors";
import { CabIcon } from "../CabIcon";
import { CabButton } from "..";
import { IoCalendarOutline, IoChevronDown } from "react-icons/io5";

type SingleValue =  number ;
type MultiValue = number[];

type FlattenValue<T> = T extends MultiValue ? T[number] : SingleValue; 

type Option<T extends SingleValue> = {
  value: T
  label: string
  labelSecondary?: string
  icon: ReactElement
};

type CabExecOptions<T extends (SingleValue | MultiValue)> = T extends SingleValue ? (
  Option<T>[]
) : (
  Option<FlattenValue<T>>[]
);

export interface CabExecPickerProps<T extends (SingleValue | MultiValue)> extends CabComponentProps<SelectProps> {
  size?: SelectProps['size'];
  disabled?: SelectProps['disabled'];
  multiple?: SelectProps['multiple'];
  value: T | null;
  options: CabExecOptions<T>
  onChange?:(value: T) => void;
  placeholder: SelectProps['placeholder'];
  fullWidth?: SelectProps['fullWidth'];
  onCloseWithValues?: (value: T | undefined | null) => void;
  hasDoneButton?: boolean;
  opencalendarsModal?: () => void;
}

export const CabExecPicker = <T extends (SingleValue | MultiValue), >({
  value, onChange, options, disabled, size, multiple, placeholder, fullWidth, sx, overrides, onCloseWithValues,
  hasDoneButton, opencalendarsModal
}: CabExecPickerProps<T>): ReactElement => {
  const props: SelectProps = {
    size,
    disabled,
    IconComponent: iconProps => (<CabIcon {...iconProps} Icon={IoChevronDown} />)
  };

  const [localValue, setLocalValue] = useState<T | undefined | null>(value);
  const [open, setOpen] = useState(false);
  const [allChecked, setAllChecked] = useState(Array.isArray(value) && value.length === options.length);

  const emptyOptions: number[] = [];

  const onCloseChange = useCallback((selectedValues: T) => {
    setLocalValue(selectedValues);
    setAllChecked(Array.isArray(selectedValues) && selectedValues.length === options.length);
  }, [setLocalValue, options.length]);

  const onClose = () => {
    if (onCloseWithValues) {
      onCloseWithValues(localValue);
      setLocalValue(value);
      setAllChecked(Array.isArray(value) && value.length === options.length);
    }
    setOpen(false);
  };

  useEffect(() => {
    setLocalValue(value);
    setAllChecked(Array.isArray(value) && value.length === options.length);
  }, [value, setLocalValue, multiple, options.length]);

  const onChangeSelect = useMemo(() => onChange ? onChange : onCloseChange, [onChange, onCloseChange]);
  const selectValue = onChange ? value  : localValue;

  const handleOpenCalendarManagement = () => {
    if (opencalendarsModal) {
      opencalendarsModal();
    }
    onClose();
  };

  return (
    <SelectStyled
      {...props}
      value={selectValue != null ? selectValue : ''}
      multiple={multiple}
      open={open}
      onOpen={() => setOpen(true)}
      displayEmpty
      disableUnderline
      variant="standard"
      fullWidth={fullWidth}
      onClose={onClose}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
      }}
      // @ts-expect-error MUI issue where the value is type 'unknown'
      renderValue={(selectedValues: T) => (
        <StyledRenderInput>
          {!Array.isArray(selectedValues) ? (
            <>
              <div className="select-value-avatar-group">
                <span key={selectedValues}>{options.find(o => o.value === selectedValues)?.icon}</span>
              </div>
              <span className="select-value-label">{options.find(o => o.value === selectedValues)?.label}</span>
            </>
          ) : selectedValues.length > 0 ? (
            <>
              <div className="select-value-avatar-group">
                <AvatarGroup max={3} spacing={5}>
                  {options.filter((o: CabExecOptions<T>[number]) => selectedValues.includes(o.value)).map(o => o.icon)}
                </AvatarGroup>
              </div>
              <span className="select-value-label">
                {options.length > 1 && options.every(o => selectedValues.includes(o.value))
                  ? 'All Execs'
                  : options.filter(o => selectedValues.includes(o.value)).map(o => o.label).join(', ')}
              </span>
              <span className="select-value-count">({selectedValues.length})</span>
            </>
          ) : null}
          {(selectedValues == null || (typeof selectedValues !== 'number' && selectedValues.length === 0)) && (
            <MenuItemStyled disabled value="">
              <span>{placeholder}</span>
            </MenuItemStyled>
          )}
        </StyledRenderInput>
      )}
      onChange={({ target: { value: newValue } }) => onChangeSelect(newValue as T)}
      sx={sx}
      {...overrides}
    >
      {options.map(option => (
        <MenuItemStyled key={option.value} value={option.value}>
          {multiple && Array.isArray(selectValue) && <CabCheckbox checked={selectValue.includes(option.value)} />}
          {option.icon}
          <ListItemText primary={option.label} secondary={option.labelSecondary} />
        </MenuItemStyled>
      ))}
      {multiple && (
        <div>
          <ListSubheader><Divider /></ListSubheader>
          <MenuItemStyled value={-1} onClick={(e) => {
            e.preventDefault();
            onChangeSelect(!allChecked ? options.map(o => o.value) as T : emptyOptions as T);
          }}>
            <CabCheckbox
              checked={allChecked}
              onChange={({ target: { checked } }) => {
                onChangeSelect(options.map(o => o.value) as T);
              }}
            />
            <ListItemText primary={"Select All"} />
          </MenuItemStyled>
        </div>
      )}
      {hasDoneButton && opencalendarsModal && (
        <div>
          <ListSubheader><Divider /></ListSubheader>
          <Box padding={1} paddingLeft={2} paddingRight={2} display='flex' justifyContent='space-between' gap={1}>
            <CabButton
              onClick={handleOpenCalendarManagement}
              buttonType='tertiary'
              icon={<CabIcon Icon={IoCalendarOutline}/>}
              sx={{width: '50%'}}
            >
              Calendars
            </CabButton>
            <CabButton
              onClick={() => onClose()}
              sx={{width: '50%'}}
            >
              Done
            </CabButton>
          </Box>
        </div>
      )}
    </SelectStyled>
  );
};

const StyledRenderInput = styled('div', { label: 'StyledRenderInput' })(() => ({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  height: 34,
}));

const SelectStyled = styled(
  Select, { label: 'SelectStyled' }
)<CabComponentProps<SelectProps>>(({ theme }) => ({
  minWidth: 160,
  '& .MuiSelect-select': {
    '& .MuiAvatar-root': {
      width: 24,
      height: 24,
      fontSize: 16,
    },
    '&:hover': {
      backgroundColor: colors.black50,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.black800,
        borderWidth: 1,
      },
    },
    '& .select-value-avatar-group': {
      display: 'inline-block',
    },
    '& .select-value-label': {
      marginLeft: 5,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .select-value-count': {
      color: '#aaa',
      fontWeight: 'bolder',
      marginLeft: 6,
      marginBottom: 3,
    },
  }
}));

const MenuItemStyled = styled(
  MenuItem, { label: 'MenuItemStyled' }
)<CabComponentProps<MenuItemProps>>(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? colors.white900 : colors.black900,
  minWidth: 300,
  '& .MuiAvatar-root': {
    width: 28,
    height: 28,
    // fontSize: 22,
    marginRight: 8,
  },
  '& .MuiCheckbox-root': {
    marginRight: 16,
  },
  '& .MuiListItemText-root': {
    color: theme.palette.mode === 'dark' ? colors.white900 : colors.black900,
    '& .MuiTypography-root': {
      color: theme.palette.mode === 'dark' ? colors.white900 : colors.black900,
    }
  },
}));
