import { ReactElement, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { BILLING_INTERVAL, TIER } from '../../../constants';
import PaymentPlan from './PaymentPlan';
import { CabSwitch } from '@CabComponents/CabSwitch';

interface Props {
  planOptions: {
    tierKey: TIER;
    name: string;
    monthlyPrice: number;
    annualPrice: number;
    buttonText: string;
    features: string[];
    monthlyPriceString?: string;
  }[];
  onPlanSelected: (tier: TIER) => void;
  userIsBasic?: boolean;
}

export const CabinetPromo = ({ planOptions, onPlanSelected, userIsBasic }: Props): ReactElement => {
  const [interval, setInterval] = useState(BILLING_INTERVAL.MONTH);

  const handlePlanSelected = (planNumber: number) => {
    onPlanSelected(planOptions[planNumber].tierKey);
  };

  return (
    <Grid container justifyContent='center' spacing={2}>
      <Grid item xs={12}>
        <Typography component="div" variant='h4' fontWeight={700}>
          <Grid component="label" container alignItems="center" justifyContent='center' spacing={1}>
            <Grid item>Monthly</Grid>
            <Grid item>
              <CabSwitch 
                color='primary'
                checked={interval === BILLING_INTERVAL.YEAR} 
                onChange={(e, checked) => setInterval(checked ? BILLING_INTERVAL.YEAR : BILLING_INTERVAL.MONTH)} />
            </Grid>
            <Grid item>Annual&nbsp;
            </Grid>
          </Grid>
        </Typography>
      </Grid>
      {
        planOptions.map((plan, key) => (
          <Grid key={key} item xs={12} sm={userIsBasic ? 4 : 6}>
            <PaymentPlan {...{...plan, onPlanSelected: handlePlanSelected, interval, planNumber: key}}/>
          </Grid>
        ))
      }
    </Grid>
  );
};

export default CabinetPromo;