import { ActionType } from '../actionTypes';
import { StaticData } from './types';
import { RootAction } from '..';

export const initialState: StaticData = {
  airlines: [],
  countries: [],
  industries: [],
  hotels: [], 
  promoMeetings: {},
  fetchingAirlines: false,
  fetchingCountries: false,
  fetchingIndustries: false, 
  fetchingHotels: false,
};

export default function staticData(state = initialState, action: RootAction): typeof initialState  {
  switch (action.type) {
    case ActionType.FETCHED_AIRLINES:
      return {...state, airlines: action.airlines, fetchingAirlines: false};

    case ActionType.FETCHED_COUNTRIES:
      return {...state, countries: action.countries, fetchingCountries: false};

    case ActionType.FETCHED_INDUSTRIES:
      return {...state, industries: action.industries, fetchingIndustries: false};

    case ActionType.FETCHED_HOTELS:
      return {...state, hotels: action.hotels, fetchingHotels: false};

    case ActionType.FETCHED_PROMO_MEETING_POLLS:
      return {...state, promoMeetings: action.meetings};
    
    case ActionType.FETCHING_AIRLINES:
      return {...state, fetchingAirlines: true};

    case ActionType.FETCHING_COUNTRIES:
      return {...state, fetchingCountries: true};

    case ActionType.FETCHING_INDUSTRIES:
      return {...state, fetchingIndustries: true};

    case ActionType.FETCHING_HOTELS:
      return {...state, fetchingHotels: true};

    default:
      return state;
  }
}