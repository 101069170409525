import { ActionType } from '../actionTypes';
import { GlobalModalAction, GlobalModalComponentName } from './types';

export const openModal = (componentName: GlobalModalComponentName): GlobalModalAction => {
  return {type: ActionType.OPEN_GLOBAL_MODAL, componentName};
};

export const closeModal = (): GlobalModalAction => {
  return {type: ActionType.CLOSE_GLOBAL_MODAL};
};



