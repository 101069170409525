import { RootAction } from '..';
import { ActionType } from '../actionTypes';
import { EventAnalyticsState } from './types';


export const initialState: EventAnalyticsState = {
  events: {},
  events_order:[],
  executive_contacts_reconciliation: {},
  executive_contacts_analysis: {},
  executive_contact_categories: {},
  event_categories: {},
  event_types: {},
  attendeeTableData: [],
  consolidated_locations: {},
  recurrence_types: {},
  contact_types: {},
  eventAnalysisResponse: undefined,
  meta: {
    min_start_date: null,
    max_end_date: null,
    most_recent_log: null,
    most_recent_started_log_date: null,
    most_recent_completed_log_date: null,
    most_recent_queued_log_date: null
  },
  analysis_inclusions: {},
  analytics_colors: []
};

export default function eventAnalytics(state = initialState, action: RootAction): typeof initialState  {
  switch (action.type) {
    case ActionType.SET_ANALYTICS_EVENTS:
      return {
        ...state,
        events: action.payload.events,
        events_order: action.payload.order || [],
        meta: {
          min_start_date: action.payload.meta.min_start_date,
          max_end_date: action.payload.meta.max_end_date,
          most_recent_log: action.payload.meta.most_recent_log,
          most_recent_started_log_date: action.payload.meta.most_recent_started_log_date,
          most_recent_completed_log_date: action.payload.meta.most_recent_completed_log_date,
          most_recent_queued_log_date: action.payload.meta.most_recent_queued_log_date
        }
      };
    case ActionType.SET_EXECUTIVE_CONTACTS_RECONCILIATION:
      return {...state, executive_contacts_reconciliation: action.contacts};
    case ActionType.UPDATE_ANALYTICS_EVENTS:
      return {
        ...state,
        events: {
          ...state.events, ...action.payload.events
        },
        events_order: [...state.events_order, ...(action.payload.order || [])],
        meta: {
          min_start_date: action.payload.meta?.min_start_date || state.meta.min_start_date,
          max_end_date: action.payload.meta?.max_end_date || state.meta.max_end_date,
          most_recent_log: action.payload.meta?.most_recent_log || state.meta.most_recent_log,
          most_recent_started_log_date: action.payload.meta?.most_recent_started_log_date || 
            state.meta.most_recent_started_log_date,
          most_recent_completed_log_date: action.payload.meta?.most_recent_completed_log_date || 
            state.meta.most_recent_completed_log_date,
          most_recent_queued_log_date: action.payload.meta?.most_recent_queued_log_date || 
            state.meta.most_recent_queued_log_date,
        }
      };
    case ActionType.UPDATE_EXECUTIVE_CONTACTS_RECONCILIATION:
      return {
        ...state,
        executive_contacts_reconciliation: {...state.executive_contacts_reconciliation, ...action.contacts}
      };
    case ActionType.UPDATE_EVENT_CATEGORIES:
      return {...state, event_categories: {...state.event_categories, ...action.categories}};
    case ActionType.SET_ANALYSIS_INCLUSIONS:
      return {...state, analysis_inclusions: action.inclusions};
    case ActionType.UPDATE_EVENT_TYPES:
      return {...state, event_types: {...state.event_types, ...action.types}};
    case ActionType.UPDATE_CONSOLIDATED_LOCATION:
      return {...state, consolidated_locations: {...state.consolidated_locations, ...action.locations}};
    case ActionType.UPDATE_RECURRENCE_TYPE:
      return {...state, recurrence_types: {...state.recurrence_types, ...action.types}};
    case ActionType.UPDATE_EXECUTIVE_CONTACT_CATEGORIES:
      return {...state, executive_contact_categories: {...state.executive_contact_categories, ...action.categories}};
    case ActionType.SET_EVENT_ANALYSIS_RESPONSE:
      return {
        ...state,
        eventAnalysisResponse: action.analysis,
        meta: {
          min_start_date: action.analysis.aggregates.min_start_date,
          max_end_date: action.analysis.aggregates.max_end_date,
          most_recent_log: action.analysis.aggregates.most_recent_log,
          most_recent_started_log_date: action.analysis.aggregates.most_recent_started_log_date,
          most_recent_completed_log_date: action.analysis.aggregates.most_recent_completed_log_date,
          most_recent_queued_log_date: action.analysis.aggregates.most_recent_queued_log_date,
        }
      };
    case ActionType.UPDATE_EXECUTIVE_CONTACTS_ANALYSIS:
      return {...state, executive_contacts_analysis: {...state.executive_contacts_analysis, ...action.contacts}};
    case ActionType.DELETED_EVENT_CATEGORY: {
      const newCategories = { ...state.event_categories };
      delete newCategories[action.categoryId.toString()];
      const updateEvents = {...state.events};
      Object.values(updateEvents).forEach((event) => {
        if (event.event_category === action.categoryId) {
          updateEvents[event.id].event_category = null;
        }
      });
      const keys = {...state.eventAnalysisResponse?.keys};
      if (keys) {
        delete keys[action.categoryId];
      }
      return { 
        ...state,
        event_categories: newCategories,
        events: updateEvents,
        eventAnalysisResponse: state.eventAnalysisResponse ? {
          ...state.eventAnalysisResponse,
          keys: keys
        } : undefined
      };
    }
    case ActionType.DELETED_EVENT_TYPE: {
      const newTypes = { ...state.event_types };
      delete newTypes[action.typeId.toString()];
      const updateEvents = {...state.events};
      Object.values(updateEvents).forEach((event) => {
        if (event.event_category === action.typeId) {
          updateEvents[event.id].event_category = null;
        }
      });
      const keys = {...state.eventAnalysisResponse?.keys};
      if (keys) {
        delete keys[action.typeId];
      }

      return { 
        ...state,
        event_types: newTypes,
        events: updateEvents,
        eventAnalysisResponse: state.eventAnalysisResponse ? {
          ...state.eventAnalysisResponse,
          keys: keys
        } : undefined
      };
    }
    case ActionType.DELETED_EXECUTIVE_CONTACT_CATEGORIES: {
      const newCategories = { ...state.executive_contact_categories };
      delete newCategories[action.categoryId.toString()];
      const updateEvents = {...state.events};
      Object.values(updateEvents).forEach((event) => {
        if (event.event_category === action.categoryId) {
          updateEvents[event.id].event_category = null;
        }
      });
      const keys = {...state.eventAnalysisResponse?.keys};
      if (keys) {
        delete keys[action.categoryId];
      }

      return { 
        ...state,
        executive_contact_categories: newCategories,
        events: updateEvents,
        eventAnalysisResponse: state.eventAnalysisResponse ? {
          ...state.eventAnalysisResponse,
          keys: keys
        } : undefined
      };
    }
    case ActionType.SET_EXECUTIVE_CONTACT_TYPES:
      return { 
        ...state,
        contact_types: action.types,
      };
    case ActionType.UPDATE_ANALYTICS_META:
      return { 
        ...state,
        meta: {...state.meta, ...action.meta}
      };
    case ActionType.SET_ANALYTICS_COLORS:
      return { 
        ...state,
        analytics_colors: action.colors
      };
    default:
      return state;
  }
}