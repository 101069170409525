import { API_URLS }  from '../../apiUrls';
import { ActionType } from '../actionTypes';
import { ThunkResult, ThunkDispatchType, FetchReturn } from '../types';

import { makeHeaders, fetchData } from '../../utils/apiUtils';
import { RootState } from '..';

export const fetchAirlines = (): ThunkResult<Promise<Record<string, unknown>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<Record<string, unknown>> => {

    dispatch({ type: ActionType.FETCHING_AIRLINES });

    const headers = await makeHeaders();

    return fetchData(API_URLS.AIRLINES, { headers, }).then((res: FetchReturn): Record<string, unknown>  => {
      if (res.status === 200) {
        dispatch({ type: ActionType.FETCHED_AIRLINES, airlines: res.data });
        return res.data;
      } else {
        dispatch({ type: ActionType.FETCHED_AIRLINES, airlines: [] });
        return {};
      }
    });
  };

export const fetchCountries = (): ThunkResult<Promise<Record<string, unknown>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<Record<string, unknown>> => {

    dispatch({ type: ActionType.FETCHING_COUNTRIES });

    const headers = await makeHeaders();

    return fetchData(API_URLS.COUNTRIES, {headers, }).then((res: FetchReturn): Record<string, unknown>  => {
      if (res.status === 200) {
        dispatch({ type: ActionType.FETCHED_COUNTRIES, countries: res.data });
        return res.data;
      } else {
        dispatch({ type: ActionType.FETCHED_COUNTRIES, countries: [] });
        return {};
      }
    });
  };

export const fetchIndustries = (): ThunkResult<Promise<Record<string, unknown>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<Record<string, unknown>> => {

    dispatch({ type: ActionType.FETCHING_INDUSTRIES });

    const headers = await makeHeaders();

    return fetchData(API_URLS.INDUSTRIES, { headers, }).then((res: FetchReturn): Record<string, unknown>  => {
      if (res.status === 200) {
        dispatch({ type: ActionType.FETCHED_INDUSTRIES, industries: res.data });
        return res.data;
      } else {
        dispatch({ type: ActionType.FETCHED_INDUSTRIES, industries: [] });
        return {};
      }
    });
  };

export const fetchHotels = (): ThunkResult<Promise<Record<string, unknown>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<Record<string, unknown>> => {

    dispatch({ type: ActionType.FETCHING_HOTELS });

    const headers = await makeHeaders();

    return fetchData(API_URLS.HOTELS, { headers, }).then((res: FetchReturn): Record<string, unknown>  => {
      if (res.status === 200) {
        dispatch({ type: ActionType.FETCHED_HOTELS, hotels: res.data });
        return res.data;
      } else {
        dispatch({ type: ActionType.FETCHED_HOTELS, hotels: [] });
        return {};
      }
    });
  };

export const fetchPromoMeetings = (): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const { meetings } = await import('./data/promoPolls');
    dispatch({ type: ActionType.FETCHED_PROMO_MEETING_POLLS, meetings});
  };

type TermsOfService = {  version: string; tos_content: string };

export const fetchCabinetTermsOfService = (): ThunkResult<Promise<TermsOfService | undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders();

    const res = await fetchData<TermsOfService>(API_URLS.TERMS_OF_SERVICE, { headers, });

    if (res.status === 200) {
      return res.data;
    } else {
      console.error(res.status);
    }
  };
