import { FormControlLabel, FormControlLabelProps, styled } from "@mui/material";
import { ReactElement } from "react";
import { colors } from "../../../colors";
import { CabComponentProps } from "../cabStyled";


export interface CabControlLabelProps extends CabComponentProps<FormControlLabelProps>{
  label: FormControlLabelProps['label'];
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  disabled?: FormControlLabelProps['disabled'];
  control: FormControlLabelProps['control'];
  id?: FormControlLabelProps['id'];
  labelGap?: number;
}

export const CabControlLabel = ({
  disabled, label, labelPlacement, labelGap, control, id, overrides, sx
}: CabControlLabelProps): ReactElement => {
  const props: FormControlLabelProps = {
    label,
    labelPlacement,
    disabled,
    id,
    control,
    sx,
  };

  return (
    <ControlLabelStyled labelGap={labelGap} {...props} {...overrides} />
  );
};

const ControlLabelStyled = styled(
  FormControlLabel, { label: 'ControlLabelStyled', shouldForwardProp: (prop) => prop !== 'labelGap' }
)<CabComponentProps<FormControlLabelProps> & { labelGap?: number }>
(({ labelPlacement, theme, labelGap }) => ({
  '& .MuiFormControlLabel-label': theme.palette.mode === 'dark' 
    ? {
      marginRight: labelPlacement === 'start' 
        ? labelGap || 8
        : 0,
      marginLeft: labelPlacement === 'end' 
        ? labelGap || 8
        : 0,
      color: colors.white900,
      '&.Mui-disabled': {
        color: colors.white500
      }
    }
    : {
      color: colors.black900,
      marginRight: labelPlacement === 'start' 
        ? labelGap || 8
        : 0,
      marginLeft: labelPlacement === 'end' 
        ? labelGap || 8
        : 0,
    },
  margin: 0
}));
