import { ActionType } from '../actionTypes';
import { ProfileNotesState } from './types';
import { RootAction } from '..';
import clone from 'lodash/clone';

export const initialState: ProfileNotesState = {
  categories: {},
  notes: {},
  categoriesLoaded: false,
  notesLoaded: false,
};

export default function profileNotes(state = initialState, action: RootAction): typeof initialState  {

  switch (action.type) {
    case ActionType.FETCHED_PROFILE_CATEGORIES:
      return {...state, categories: action.profileCategories, categoriesLoaded: true};

    case ActionType.CREATED_PROFILE_CATEGORY:
      return {...state, categories: {[action.profileCategory.id]: action.profileCategory, ...state.categories}};

    case ActionType.UPDATED_PROFILE_CATEGORY:
      return {...state, categories: {...state.categories, [action.profileCategory.id]: action.profileCategory}};

    case ActionType.FETCHED_ORG_LEADER_CATEGORIES: {
      const categories = action.categories;
      return {...state, categories: Object.fromEntries(Object.values(categories).reduce(
        (current, next) => current.concat(next), []
      ).map(cat => [cat.id, cat]))};
    }
    case ActionType.DELETED_PROFILE_CATEGORY: {
      const notDeleteCategories = clone(state.categories);
      delete notDeleteCategories[action.profileCategory.id];
      return {...state, categories: notDeleteCategories};
    }
    case ActionType.FETCHED_PROFILE_NOTES:
      return {...state, notes: action.profileNotes, notesLoaded: true};

    case ActionType.CREATED_PROFILE_NOTE:
      return {
        ...state, 
        categories: {
          ...state.categories, 
          [action.profileNote.profile_category]: {
            ...state.categories[action.profileNote.profile_category],
            notes: [
              action.profileNote.id, 
              ...state.categories[action.profileNote.profile_category].notes, 
            ]
          }
        },
        notes: {
          ...state.notes, 
          [action.profileNote.id]: action.profileNote
        }
      };

    case ActionType.UPDATED_PROFILE_NOTE:
      return {...state, notes: {...state.notes, [action.profileNote.id]: action.profileNote}};

    case ActionType.DELETED_PROFILE_NOTE: {
      const notDeletedNotes = clone(state.notes);
      const deletedNoteCategoryId = notDeletedNotes[action.profileNoteId].profile_category;
      const notDeletedNoteIds = state.categories[deletedNoteCategoryId].notes.slice();

      delete notDeletedNotes[action.profileNoteId];
      notDeletedNoteIds.splice(notDeletedNoteIds.indexOf(action.profileNoteId), 1);

      return {
        ...state, 
        ...state, 
        categories: {
          ...state.categories, 
          [deletedNoteCategoryId]: {
            ...state.categories[deletedNoteCategoryId],
            notes: notDeletedNoteIds
          }
        },
        notes: notDeletedNotes};
    }
    case ActionType.FETCHING_PROFILE_CATEGORIES:
    case ActionType.LOGOUT_SUCCESSFUL:
      return initialState;
    
    default:
      return state;
  }
}