import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { FormControlProps } from '@mui/material';
import { CabComponentProps } from "../cabStyled";
import { CabAutocomplete } from "@CabComponents/CabAutocomplete";
import { timePickerOptions } from "../../../utils/scheduleUtils";
import { trimStart } from "lodash-es";
import { addLeadingZero, convert24hrTo12hr, timeRegex } from "../../../utils/datesUtils";

export interface CabTimePickerProps extends CabComponentProps<FormControlProps>  {
  pickerTime: string;
  minTime?: string;
  maxTime?: string;
  onChange?: (newTime: string) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  onUpdate?: () => void;
}

interface PickerValue {
  value: string;
  label: string;
}

const baseOptions = timePickerOptions(5);

export const CabTimePicker = ({
  pickerTime, minTime, maxTime, onChange, disabled, fullWidth, onUpdate
}: CabTimePickerProps): ReactElement => {
  const [time, setTime] = useState<string>('00:00');
  const [pickerTimeOptions, setPickerTimeOptions] = useState(baseOptions);

  const cleanMinTime = useMemo(() => (
    minTime ? minTime.split(":").map(addLeadingZero).join(":") : "00:00"
  ), [minTime]);

  const cleanMaxTime = useMemo(() => (
    maxTime ? maxTime.split(":").map(addLeadingZero).join(":") : "23:59"
  ), [maxTime]);

  const addNewOptions = useCallback((filterText: string) => {
    // No options - create some based on what the user has so far provided
    const newOptions: PickerValue[] = [];
    const match = timeRegex.exec(filterText);

    if (match && match.groups) {
      const hours = match.groups.h && Number(match.groups.h) <= 12 && Number(match.groups.h) >= 1 
        ? match.groups.h
        : null;
      let mins = match.groups.m && Number(match.groups.m) <= 59 ? match.groups.m : null;
      // If the hour is 12, it can only be am
      const ampm = match.groups.a 
        ? match.groups.a.toLowerCase() === 'p' 
          ? 'pm' 
          : 'am' 
        : null;

      if (!hours) {
        // If this regex does not match at least hours, we don't know where to start
        return [];
      }
      if (!mins) {
        mins = "00";
      }

      const optTimeLabel = `${hours}:${mins}`;
      if (ampm) {
        // AM / PM specified
        const offsetHours = ampm === "pm" || hours === "12" ? `${(Number(hours) + 12) % 24}` : hours;
        const optTimeValue = `${addLeadingZero(offsetHours)}:${mins}`;
        newOptions.push(
          {value: optTimeValue, label: `${optTimeLabel} ${ampm}`}
        );
      } else {
        // AM / PM not specified, so show both
        newOptions.push(
          {value: `${addLeadingZero(hours)}:${mins}`, label: `${optTimeLabel} ${hours === "12" ? "pm" : "am"}`}
        );
        const offsetHours = addLeadingZero(((Number(hours) + 12) % 24).toString());
        newOptions.push(
          {value: `${offsetHours}:${mins}`, label: `${optTimeLabel} ${hours === "12" ? "am" : "pm"}`}
        );
      }

      // Remove any options that already exist to prevent duplicates
      const addedOptions = newOptions.filter(
        n => !baseOptions.find(o => o.value === n.value)
      );

      const newPickerOptions = [...addedOptions, ...baseOptions].sort((o1, o2) => o1.value.localeCompare(o2.value));

      setPickerTimeOptions(newPickerOptions);
    }
  }, []);

  useEffect(() => {
    if (pickerTime) {
      // Pad with 0 in case the input isn't properly padded
      const paddedTime = pickerTime.split(":").map(addLeadingZero).join(":");
      const time12hr = convert24hrTo12hr(paddedTime);
      addNewOptions(time12hr);
      setTime(paddedTime);
    }
  }, [addNewOptions, pickerTime]);

  const handleChangeTime = (value: string) => {   
    setTime(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <CabAutocomplete<string, never>
      value={time || null}
      onInputChange={v => addNewOptions(v)}
      onChange={onUpdate ? (v) => {
        handleChangeTime(v ? (Array.isArray(v) ? v[0] : v) : ''); 
        onUpdate();
      } : (v) => handleChangeTime(v ? (Array.isArray(v) ? v[0] : v) : '')}
      options={pickerTimeOptions}
      noOptionsText="--"
      openOnFocus
      disabled={disabled}
      autoSelect
      autoHighlight
      sx={{width: fullWidth ? '100%' : '120px', paddingRight: 0}}
      // Note shape of options are {value: 24hr-time, label: 12hr-time}
      filterOptions={(opts, state) => {
        const regex = /[^\w]/g;
        const match = timeRegex.exec(state.inputValue);
        let hours = "";
        let mins = "";
        let ampm = "";
        if (match && match.groups) {
          hours = match.groups.h ?? "";
          mins = match.groups.m ?? "";
          ampm = match.groups.a ? match.groups.a === "a" ? "am" : "pm" : "";
        }

        const filterText = trimStart(state.inputValue.toLowerCase().replaceAll(regex, ''), '0');
        const newOpts: typeof opts = opts.filter(opt => {
          // Make sure the value selected is always in the list before filtering
          if (!state.inputValue && opt.value === time) {
            return true;
          }

          // Respect min/max (inclusive)
          if (opt.value > cleanMaxTime || opt.value < cleanMinTime) {
            return false;
          }

          // Standardize label format and do basic search
          const cleanLabel = `${opt.label.toLowerCase()}`.replaceAll(regex, '');
          if (cleanLabel.includes(filterText)) {
            return true;
          }

          // If we have hours and ampm but not mins, find a match e.g. 7am
          if (hours && !mins && ampm) {
            return cleanLabel.includes(`${hours}00${ampm}`);
          }
          return false;
        });

        // If there are more than 15 options, show only 15 minute intervals, otherwise show 5 minute intervals
        // NOTE: Need to avoid filtering out selected value
        if (newOpts.length > 15) {
          return newOpts.filter(opt => opt.value === time || (Number(opt.value.slice(-2)) % 15) === 0);
        } else if (newOpts.length > 0) {
          return newOpts;
        }

        return [];
      }}
    />
  );
};