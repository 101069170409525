import { Box, Typography } from "@mui/material";


const TrialExpired = () => {
  return <Box 
    width="100%" 
    minHeight={200} 
    padding={2} 
    display={"flex"} 
    alignItems={"center"} 
    justifyContent={"center"} 
    flexDirection={"column"}
  >
    <Typography sx={{width: "100%", textAlign: "center"}}>
      Your subscription or trial period has has expired.
    </Typography>
    <Typography sx={{width: "100%", textAlign: "center", marginTop: 1}}>
      Please update your subscription details to continue.
    </Typography>
  </Box>;
};

export default TrialExpired;