import { DateTime } from 'luxon';
import { PROVIDER } from '../constants';
import { AllOAuthGrantDetails, Auth, Grant } from '../store';
import { validateEmail } from './inputUtils';

// Amplify doesn't export this type properly
export type AmplifyAuthProvider = 'Amazon' | 'Apple' | 'Facebook' | 'Google';

// const GOOGLE_READ_ONLY_SCOPES = [
//   'https://www.googleapis.com/auth/calendar.readonly',
//   'https://www.googleapis.com/auth/calendar.events.readonly',
//   //'profile',
//   //'email'
// ];

// These are the permissions required if the user is able to access booking
// !THESE MUST BE IN SYNC WITH THE BACKEND SCOPES IN common.py
export const GOOGLE_BOOKING_SCOPES = [
  "https://www.googleapis.com/auth/calendar.readonly",
  "https://www.googleapis.com/auth/calendar.events",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/userinfo.email",
  "openid"
];


export const getGrants = (auth: Auth): Array<Grant> => {
  if (auth.user?.oauth_grant_details) {
    const { oauth_grant_details } = auth.user;
    return Object.keys(oauth_grant_details)
      .filter(provider => Object.keys(oauth_grant_details[provider]).length > 0)
      .map(provider => {
        const allDetails = oauth_grant_details[provider];
        // We don't currently support multiple oauth per provider, so we just look at the first one here
        const username = Object.keys(allDetails)[0];
        return {
          provider,
          username,
          account_user_id: allDetails[username].account_user_id,
          user_id: allDetails[username].user_id,
          scope: allDetails[username].scope,
          token_id: allDetails[username].token_id,
          signedIn: true,
        };
      });
  } else {
    return [];
  }
}; 

export const getGoogleBookingScope = (): string => {
  // This gives new scopes to anyone with the booking feature flag on
  const scopes = GOOGLE_BOOKING_SCOPES;
  return scopes.join(' ');
};


// TODO: We can probably make this a bit more efficient by making these selectors
export const checkForProviderGrant = (oauth_grant_details: AllOAuthGrantDetails, provider: string): boolean => (
  Object.keys(oauth_grant_details).some(p => 
    p === provider && Object.keys(oauth_grant_details[p]).length > 0
  )
);

export const checkForGoogleGrant = (oauth_grant_details: AllOAuthGrantDetails): boolean => (
  checkForProviderGrant(oauth_grant_details, PROVIDER.GOOGLE.name)
);

export const checkForMicrosoftGrant = (oauth_grant_details: AllOAuthGrantDetails): boolean => (
  checkForProviderGrant(oauth_grant_details, PROVIDER.MICROSOFT.name)
);


// Check if there is any google or microsoft grant in the available set
export const checkForCalendarGrants = (oauth_grant_details: AllOAuthGrantDetails): boolean => (
  checkForGoogleGrant(oauth_grant_details) || checkForMicrosoftGrant(oauth_grant_details)
);

export const getEmailDomain = (email: string) => {
  if (!validateEmail(email)) {
    throw new Error('invalid email');
  }
  
  return email.split("@")[1].toLowerCase();
};

export const useNewTier = () => {
  return import.meta.env.VITE_DEPLOY_ENV !== "dev";
};


// Using Session Storage for auth errors is here because Amplify ALWAYS refreshes / redirects on logout. 
// In addition to actual user-logout, amplify's logout happens automatically under some conditions, or when 
//    we need to clear auth state. Much of this has to do with the use of Cognito + Cabinet auth flows together.
// Using Session Storage allows us to briefly persist errors beyond a refresh.

export interface SessionStorageAuthError {
  timestampMs: number;
  errors: string[]
}

const SESSION_STORAGE_AUTH_ERROR_KEY = "authError";
const SESSION_STORAGE_AUTH_ERROR_EXPIRE_MILLIS = 5000;

export const setAuthErrorToSessionStorage = (errors: string[]) => {
  const authErrorJson: SessionStorageAuthError = {
    timestampMs: DateTime.now().toMillis(),
    errors
  };
  sessionStorage.setItem(SESSION_STORAGE_AUTH_ERROR_KEY, JSON.stringify(authErrorJson));
};

export const getAuthErrorFromSessionStorage = (): string[] | null => {
  const loginErrorStr = sessionStorage.getItem(SESSION_STORAGE_AUTH_ERROR_KEY);
  if (!loginErrorStr) return null;

  // Intentionally resolving type ambiguity in real-time to be forward-compatible with changes to the shape.
  //    The only firm constraint is that the root value is stringified json.
  const loginErrorJson: Record<string, unknown> = JSON.parse(loginErrorStr);

  if (
    "timestampMs" in loginErrorJson 
    && typeof loginErrorJson.timestampMs === "number"
    && loginErrorJson.timestampMs + SESSION_STORAGE_AUTH_ERROR_EXPIRE_MILLIS > DateTime.now().toMillis()
    && "errors" in loginErrorJson
    && Array.isArray(loginErrorJson.errors)
    && loginErrorJson.errors.every(error => typeof error === "string")
  ) {
    return loginErrorJson.errors;
  }
  return null;
};

export const clearAuthErrorSessionStorage = () => {
  sessionStorage.removeItem(SESSION_STORAGE_AUTH_ERROR_KEY);
};