import { ActionType } from '../actionTypes';
import { AirlineNoteList, AirlineNote } from './types';
import { RootAction } from '..';

export const initialState: AirlineNoteList = {
  loaded: false,
  notes: [],
};

export default function airlines(state = initialState, action: RootAction): typeof initialState  {
  //let newAirlineNotes = state.airlineNotes.slice();

  switch (action.type) {
    case ActionType.FETCHED_AIRLINE_NOTES:
      return {loaded: true, notes: action.airlineNotes};

    case ActionType.LOGOUT_SUCCESSFUL:
      return initialState;

    case ActionType.CREATED_AIRLINE_NOTE:
      return {...state, notes: [action.airlineNote, ...state.notes]};

    case ActionType.UPDATED_AIRLINE_NOTE: {
      const notesAfterUpdate = state.notes.map(
        (note): AirlineNote => note.id === action.airlineNote.id ? action.airlineNote : note);
      return {...state, notes: notesAfterUpdate};
    }
    case ActionType.DELETED_AIRLINE_NOTE: {
      const notDeletedNotes = state.notes.filter((note): boolean => note.id !== action.airlineNoteId);
      return {...state, notes: notDeletedNotes};
    }
    default:
      return state;
  }
}