import {styled, TextField, TextFieldProps } from "@mui/material";
import chroma from "chroma-js";
import colors from "../../../colors";
import { CabComponentProps } from "../cabStyled";

export interface ICabInput extends CabComponentProps<TextFieldProps>{
  label?: string;
  xsmall?: boolean;
  text?: boolean;
}

export const CabInput = styled(TextField, {
  shouldForwardProp: (prop: string) => !['xsmall', 'text'].includes(prop), label: 'CabInput',
})<{xsmall?: boolean, text?: boolean}>(({ theme, xsmall, text }) => ({
  ...(xsmall && {
    '& input': {
      paddingTop: '5px',
      paddingBottom: '4px'
    },
  }),
  ...(text && {
    '& input': {
      paddingTop: '2px',
      paddingBottom: '1px'
    },
  }),
  ...(theme.palette.mode !== 'dark' ? {
    color: colors.black900,
    backgroundColor: colors.white900,
    '& .MuiOutlinedInput-root': { //Light UI
      '&:hover': {
        backgroundColor: colors.black50,
      },
      '&:hover fieldset': {
        borderColor: colors.black200,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.black800,
        borderWidth: 1
      },
      '&.Mui-focused .MuiInputAdornment-root': {
        color: colors.black900,
      },
      '& fieldset': {
        borderColor: colors.black200,
      },
      '& .MuiInputAdornment-root': {
        color: colors.black500
      },
      '& .MuiInputBase-input.Mui-disabled': {
        backgroundColor: colors.black50,
      },
      '&.Mui-disabled fieldset': {
        borderColor: colors.black200,
      },
    }
  } : {
    color: colors.white900,
    backgroundColor: chroma(colors.white900).alpha(0.04).hex(),
    '& .MuiInputLabel-root': {
      color: colors.white800,
    },
    '& .MuiOutlinedInput-root': { //Dark UI
      '&:hover': {
        backgroundColor: chroma(colors.white900).alpha(0.08).hex(),
      },
      '&:hover fieldset': {
        borderColor: colors.white300,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.white800,
        borderWidth: 1
      },
      '&.Mui-focused .MuiInputAdornment-root': {
        color: colors.white900,
      },
      '& fieldset': {
        borderColor: colors.white200,
      },
      '& .MuiInputAdornment-root': {
        color: colors.white500
      },
      '& .MuiInputBase-input__input': {
        color: colors.white900
      },
      '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: colors.white500,
        backgroundColor: colors.white50,
      },
      '&.Mui-disabled fieldset': {
        borderColor: colors.white100
      },
    }
  }),
}));