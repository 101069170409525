import {captureMessage, captureException} from "@sentry/react";

export const cabCaptureException = (err: unknown, extra?: Record<string, unknown>) => {
  if (import.meta.env.VITE_DEPLOY_ENV !== "dev") {
    captureException(err, extra);
  } else {
    console.error(err, extra);
  }
};

export const cabCaptureMessage = (message: string) => {
  if (import.meta.env.VITE_DEPLOY_ENV !== "dev") {
    captureMessage(message);
  } else {
    console.log(message);
  }
};