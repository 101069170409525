import { USER_ROLE } from "../constants";
import { ValueOf } from "./types";


const userRoleStringLocalization = {
  'onboard-basic-features': ['Scheduling links', 'Automatic holds', 'Meeting status tracking and more'],
  'onboard-essentials-features': ['Everything in Basic', 'Group scheduling', 'Zoom integration'],
  'onboard-pro-features': {
    [USER_ROLE.INDIVIDUAL]: ['Everything in Essentials', 'Team Collaboration', 'Advanced Profiles'],
    [USER_ROLE.ASSISTANT]: ['Everything in Essentials', 'Task management', 'Executive profiles and more'],
  },
  'onboard-individual-features': ['Scheduling automation', 'Calendar insights', 'Executive profiles'],
  'onboard-starter-features': ['Team collaboration', 'SSO Implementation', 'Onboarding support'],
  'onboard-growth-features': ['Team collaboration', 'SSO Implementation', 'Security & Legal Reviews', 
    'Onboarding & Success Team Support'],
  'onboard-premier-features': ['Everything in Growth', 'Custom Build Hours', 'Advanced Success Team Support'],
  'promo-essentials-features': [
    'Everything in the Basic tier plus...', 'Integration with Zoom',
    'Meeting Polls for large meetings', '14 day free trial',
  ],
  'promo-starter-features': [
    'Everything in the Individual tier plus...', 'Team collaboration',
    'SSO Implementation', 'Onboarding support',
  ],
  'promo-larger-team-features': [
    'Everything in the Starter tier plus...', 'Security & Legal Reviews',
    'Custom Build Hours', 'Advanced Success Team Support',
  ],
  'promo-pro-features': {
    [USER_ROLE.INDIVIDUAL]: ['Everything in the Essentials tier plus...', 'Collaborate with your colleagues',
      'Customize your profile information', 'Analyze time spent from your calendars', '14 day free trial',
    ],
    [USER_ROLE.ASSISTANT]: ['Everything in the Essentials tier plus...', 
      'Analyze time spent from your executive\'s calendars',
      'Track your executives\' information', 'Collaborate with your colleagues', '14 day free trial',
    ],
  },
  'add-team-member-title': {
    [USER_ROLE.INDIVIDUAL]: 'Add a Teammate',
    [USER_ROLE.ASSISTANT]: 'Add an Executive',
  },
  'add-team-member-image': {
    [USER_ROLE.INDIVIDUAL]: 'Profile Photo (optional)',
    [USER_ROLE.ASSISTANT]: 'Photo of your Executive (optional)',
  },
  'add-team-member-button': {
    [USER_ROLE.INDIVIDUAL]: 'Add Teammate',
    [USER_ROLE.ASSISTANT]: 'Add Executive',
  },
  'integrations-zoom-title': {
    [USER_ROLE.INDIVIDUAL]: 'My Account',
    [USER_ROLE.ASSISTANT]: 'Executive Zoom Settings',
  },
  'integrations-zoom-help': {
    [USER_ROLE.INDIVIDUAL]: `You can authenticate with Zoom to create meetings for each event.`,
    [USER_ROLE.ASSISTANT]: `You can authenticate with Zoom to schedule on behalf of your executives.`,
  },
  'calendar-association': {
    [USER_ROLE.INDIVIDUAL]: `Next to each of your colleagues below, choose any number of calendars you'd like to have
      visible when you find times for their meetings.`,
    [USER_ROLE.ASSISTANT]: `Next to each of your executives below, choose any number of calendars you'd like to have
      visible when you find times for their meetings.`,
  },
  'meeting-filter-team-member-select': {
    [USER_ROLE.INDIVIDUAL]: 'Select Team Member',
    [USER_ROLE.ASSISTANT]: 'Select Executive',
  },
  'meeting-filter-team-member-select-placeholder': {
    [USER_ROLE.INDIVIDUAL]: 'Team Member',
    [USER_ROLE.ASSISTANT]: 'Select an Executive',
  },
  'meeting-drag-timeslots': {
    [USER_ROLE.INDIVIDUAL]: 'Drag timeslots on the calendar to select the range of times you are available.',
    [USER_ROLE.ASSISTANT]: 'Drag timeslots on the calendar to select the range of times your executives are available.',
  },
  'meeting-log-header': {
    [USER_ROLE.INDIVIDUAL]: 'Teammates',
    [USER_ROLE.ASSISTANT]: 'Executives',
  },
  'new-meeting-button': {
    [USER_ROLE.INDIVIDUAL]: 'Invite someone to pick a time for a meeting.',
    [USER_ROLE.ASSISTANT]: 'Invite someone to pick a time to meet with your executives.',
  },
  'no-calendars-warning': {
    [USER_ROLE.INDIVIDUAL]: `You don't have any attached calendars. Attach a calendar so we can help you
      schedule meetings faster!`,
    [USER_ROLE.ASSISTANT]: `This executive doesn't have any attached calendars. Attach a calendar so we can help you
      schedule meetings faster!`,
  },
  'missing-email-title': {
    [USER_ROLE.INDIVIDUAL]: 'Teammate Emails',
    [USER_ROLE.ASSISTANT]: 'Executive Emails',
  },
  'missing-email-body': {
    [USER_ROLE.INDIVIDUAL]: `Cabinet needs your teammate's email address to invite them to meetings. We will never give
      this email out to any third parties, or send any spam their way.`,
    [USER_ROLE.ASSISTANT]: `Cabinet needs your executive's email address to invite them to meetings. We will never give
      this email out to any third parties, or send any spam their way.`,
  },
  'missing-email-placeholder': {
    [USER_ROLE.INDIVIDUAL]: "Teammate's Email",
    [USER_ROLE.ASSISTANT]: "Executive's Email",
  },
  'hold-times': {
    [USER_ROLE.INDIVIDUAL]: `This will add events to your selected calendars in order to hold the times selected
      for this meeting.`,
    [USER_ROLE.ASSISTANT]: `This will add events to the selected executive calendars in order to hold the times selected
      for this meeting.`,
  },
  'onboard-connect-calendar': {
    [USER_ROLE.INDIVIDUAL]: "Connect to your calendar provider(s) so you can perform seamless scheduling.",
    [USER_ROLE.ASSISTANT]: "Connect to your calendar provider(s) so you can perform seamless scheduling.",
  },
  'schedule-teammember-select-placeholder': {
    [USER_ROLE.INDIVIDUAL]: "Select Teammate to View",
    [USER_ROLE.ASSISTANT]: "Select Executives to View",
  },
  'schedule-no-teammember-selected': {
    [USER_ROLE.INDIVIDUAL]: "Please select a teammate from the drop down to get started.",
    [USER_ROLE.ASSISTANT]: "Please select an executive from the drop down to get started.",
  },
  'shared-teammember-calendar-warning': {
    [USER_ROLE.INDIVIDUAL]: "You cannot change calendars for this teammate",
    [USER_ROLE.ASSISTANT]: "You cannot change calendars for shared executives",
  },
};

export type UserRoleStringLocalization = typeof userRoleStringLocalization;
export type Category = keyof UserRoleStringLocalization;

// NOTE: type inference did not work well here so we're using an explicit conditional type
type LocalizedStringReturnType<T extends Category> = UserRoleStringLocalization[T] extends string[] ? string[]
  : ValueOf<UserRoleStringLocalization[T]> extends string[] ? string[] : string;

export const getUserRoleLocalizedString = <T extends Category>(
  category: T, role: USER_ROLE,
): LocalizedStringReturnType<T> => {
  const obj = userRoleStringLocalization[category];

  return ((typeof obj === 'string' || Array.isArray(obj)) ? obj : (obj[role] || '')) as LocalizedStringReturnType<T>;
};
