import { Box, SxProps, Tooltip, TooltipProps, styled } from "@mui/material";
import { CabComponentProps } from "../cabStyled";
import { ReactNode } from "react";


export interface ICabTooltip extends CabComponentProps<Record<string, never>> {
  title: TooltipProps['title'];
  children: ReactNode;
  placement?: TooltipProps['placement'];
  arrow?: TooltipProps['arrow'];
  wrapWithSpan?: boolean;
  open?: TooltipProps["open"]
  componentsProps?: TooltipProps["componentsProps"]
  enterDelay?: TooltipProps["enterDelay"]
  enterNextDelay?: TooltipProps["enterNextDelay"]
  spanSx?: SxProps;
}

export const CabTooltip = ({ 
  sx, title, children, placement, arrow, wrapWithSpan, open, componentsProps, enterDelay, enterNextDelay, spanSx 
}: ICabTooltip) => {
  const props = {
    sx,
    title,
    placement,
    arrow,
    open,
    componentsProps,
    enterDelay,
    enterNextDelay
  };

  if (!title) {
    return <>{children}</>;
  }

  return (
    <StyledTooltip {...props}
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: '14px',
            whiteSpace: 'pre-wrap'
          }
        }
      }}
    >
      {!wrapWithSpan && !Array.isArray(children)
        ? <>{children}</>
        : <Box sx={spanSx}>{children}</Box>}
    </StyledTooltip>
  );
};


const StyledTooltip = styled(Tooltip, { label: 'StyledTooltip' })(() => ({
}));
