import { AuthError as AmplifyAuthError } from 'aws-amplify/auth';
import { cabCaptureMessage } from './logging';




export type AmplifyError = AmplifyAuthError &  {
  code: COGNITO_ERROR_CODE | AMPLIFY_ERROR_CODE, 
};

// These codes come from the Amplify package
export enum AMPLIFY_ERROR_CODE {
  NO_CONFIG = 'NoConfig',
  MISSING_AUTH_CONFIG = 'MissingAuthConfig',
  EMPTY_USERNAME = 'EmptyUsername',
  INVALID_USERNAME = 'InvalidUsername',
  EMPTY_PASSWORD = 'EmptyPassword',
  EMPTY_CODE = 'EmptyCode',
  SIGN_UP_ERROR = 'SignUpError',
  NO_MFA = 'NoMFA',
  INVALID_MFA = 'InvalidMFA',
  EMPTY_CHALLENGE_RESPONSE = 'EmptyChallengeResponse',
  NO_USER_SESSION = 'NoUserSession',
  DEFAULT = 'Default',
  DEVICE_CONFIG = 'DeviceConfig',
  NETWORK_ERROR = 'NetworkError',
  AUTOSIGNIN_ERROR = 'AutoSignInError',
  ALIAS_EXISTS_EXCEPTION = 'AliasExistsException',
}

// These codes come from the Cognito service
export enum COGNITO_ERROR_CODE {
  CUSTOM_PROVIDER_IDENTITY_EXISTS = "CustomProviderIdentityExists",
  FEDERATED_IDENTITY_EXISTS = "FederateIdentityExists",
  CODE_MISMATCH_EXCEPTION = 'CodeMismatchException',
  EXPIRED_CODE_EXCEPTION = 'ExpiredCodeException',
  INVALID_PASSWORD_EXCEPTION = 'InvalidPasswordException',
  NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException',
  USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException',
  USER_NOT_CONFIRMED_EXCEPTION = 'UserNotConfirmedException',
  USERNAME_EXISTS_EXCEPTION = 'UsernameExistsException',
  PASSWORD_RESET_REQUIRED_EXCEPTION = 'PasswordResetRequiredException',
  LIMIT_EXCEEDED_EXCEPTION = 'LimitExceededException',
  INVALID_PARAMETER_EXCEPTION = 'InvalidParameterException',
  UNKOWN_EXCEPTION = 'Unknown',
  OTHER_EMAIL_NOT_CONFIRMED = 'EmailNotConfirmed',
  USER_ALREADY_AUTHENTICATED_EXCEPTION = 'UserAlreadyAuthenticatedException'  
}

export enum CABINET_AUTH_ERROR_CODE {
  SSO_REQUIRED = "sso_required",
  FEDERATED_ERROR = "federated_identity_exists",
  LOGIN_IN_WITH_GOOGLE = 'login_in_with_google'
}

export const RESET_PASSWORD_MESSAGE = 'You need to set a new password. ';
export const INDETERMINATE_AUTH_ERROR_MESSAGE = `Your email and password do not 
match. Please try again, or sign in with Google/SSO.`;

export const getException = 
  (error: AmplifyAuthError): {errorType: COGNITO_ERROR_CODE, message: string}  => {
    switch (error.name) {
      case COGNITO_ERROR_CODE.CUSTOM_PROVIDER_IDENTITY_EXISTS:
        return {errorType: error.name, message: 'Please login using SSO'};
      case COGNITO_ERROR_CODE.CODE_MISMATCH_EXCEPTION: 
        return {errorType: error.name, message: 'That verification code is not correct'};
      case COGNITO_ERROR_CODE.EXPIRED_CODE_EXCEPTION: 
        return {errorType: error.name, message: 'That verification code has expired.' + 
      ' Please refresh and click \'Forgot Password\' '
      + 'again to get a new one'};
      case COGNITO_ERROR_CODE.INVALID_PASSWORD_EXCEPTION:
        return {errorType: error.name, message: 'Your password isn\'t secure enough - please try the following:\n' +
        ' \n• Avoid sequential numbers/letters (e.g. 1234)' +
         '\n• Avoid repeated characters (e.g. aaaa)' +
         '\n• Avoid common words (e.g. password123)' +
         '\n• Include capitals, symbols, and numbers\n'};
      case COGNITO_ERROR_CODE.NOT_AUTHORIZED_EXCEPTION:
        return {errorType: error.name, message: INDETERMINATE_AUTH_ERROR_MESSAGE};

      case COGNITO_ERROR_CODE.USER_NOT_FOUND_EXCEPTION:
        return {errorType: error.name, message: INDETERMINATE_AUTH_ERROR_MESSAGE};

      case COGNITO_ERROR_CODE.USER_NOT_CONFIRMED_EXCEPTION:
        return {errorType: error.name, 
          message: 'Please confirm your e-mail address by clicking the link in the email from ' +
        'Cabinet'};
      case COGNITO_ERROR_CODE.USERNAME_EXISTS_EXCEPTION: 
        return {errorType: error.name, message: "You cannot create an account with that email address."};

      case COGNITO_ERROR_CODE.PASSWORD_RESET_REQUIRED_EXCEPTION:
        return {errorType: error.name, message: RESET_PASSWORD_MESSAGE};

      case COGNITO_ERROR_CODE.LIMIT_EXCEEDED_EXCEPTION: 
        return {errorType: error.name, message:  'Please wait a few minutes and try again'};

      case COGNITO_ERROR_CODE.INVALID_PARAMETER_EXCEPTION:   
        cabCaptureMessage(error.message);
        return {errorType: error.name, message: error.message};
      default:    
        return {
          errorType: COGNITO_ERROR_CODE.UNKOWN_EXCEPTION, 
          message: 'Something went wrong. Please email help@joincabinet.com for assistance.'
        };
    }
  };


export const isAmplifyError = (candidate: unknown): candidate is AmplifyError => {
  if (candidate && typeof candidate === 'object' && 'code' in candidate && 'message' in candidate) {
    return true;
  }
  return false;
};
