import { GridFilterModel } from '@mui/x-data-grid';
import { cabCaptureException } from './logging';

const BOOKING_CONTEXT_KEY = "bookingContext";
const MEETING_LOG_PENDING_FILTERS = "meetingLogInProgressFilters";
const MEETING_LOG_COMPLETED_FILTERS = "meetingLogCompletedFilters";
const REUSABLE_LOG_PENDING_FILTERS = "reusableLogInProgressFilters";
const REUSABLE_LOG_COMPLETED_FILTERS = "reusableLogCompletedFilters";
const POLL_LOG_PENDING_FILTERS = "pollLogInProgressFilters";
const POLL_LOG_COMPLETED_FILTERS = "pollLogCompletedFilters";
const RECONCILIATION_TABLE_FILTERS = "reconciliationFilters";

export const setBookingInboundLeadData = (
  organizationId: number, organizationName: string, userId: number, meetingId: number
) => {
  try {
    const bookingContextString = localStorage.getItem(BOOKING_CONTEXT_KEY);
    if (bookingContextString !== null) {
      const bookingContext = JSON.parse(bookingContextString);
      bookingContext.push({organizationId, organizationName, userId, meetingId});
      localStorage.setItem(BOOKING_CONTEXT_KEY, JSON.stringify(bookingContext));
    } else {
      localStorage.setItem(BOOKING_CONTEXT_KEY, JSON.stringify([{
        organizationId, organizationName, userId, meetingId
      }]));
    }
  } catch (err) {
    cabCaptureException(err);
  }
};

export const getBookingInboundLeadData = () => {
  try {
    const data = localStorage.getItem(BOOKING_CONTEXT_KEY);
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  } catch (err) {
    cabCaptureException(err);
  }
};

export const setMeetingLogInProgressFilters = (filters: GridFilterModel) => {
  try {
    localStorage.setItem(MEETING_LOG_PENDING_FILTERS, JSON.stringify(filters));
  } catch (err) {
    cabCaptureException(err);
  }
};

export const getMeetingLogInProgressFilters = () => {
  try {
    const data = localStorage.getItem(MEETING_LOG_PENDING_FILTERS);
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  } catch (err) {
    cabCaptureException(err);
  }
};

export const setMeetingLogCompletedFilters = (filters: GridFilterModel) => {
  try {
    localStorage.setItem(MEETING_LOG_COMPLETED_FILTERS, JSON.stringify(filters));
  } catch (err) {
    cabCaptureException(err);
  }
};

export const getMeetingLogCompletedFilters = () => {
  try {
    const data = localStorage.getItem(MEETING_LOG_COMPLETED_FILTERS);
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  } catch (err) {
    cabCaptureException(err);
  }
};

export const setReusableLogInProgressFilters = (filters: GridFilterModel) => {
  try {
    localStorage.setItem(REUSABLE_LOG_PENDING_FILTERS, JSON.stringify(filters));
  } catch (err) {
    cabCaptureException(err);
  }
};

export const getReusableLogInProgressFilters = () => {
  try {
    const data = localStorage.getItem(REUSABLE_LOG_PENDING_FILTERS);
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  } catch (err) {
    cabCaptureException(err);
  }
};

export const setReusableLogCompletedFilters = (filters: GridFilterModel) => {
  try {
    localStorage.setItem(REUSABLE_LOG_COMPLETED_FILTERS, JSON.stringify(filters));
  } catch (err) {
    cabCaptureException(err);
  }
};

export const getReusableLogCompletedFilters = () => {
  try {
    const data = localStorage.getItem(REUSABLE_LOG_COMPLETED_FILTERS);
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  } catch (err) {
    cabCaptureException(err);
  }
};

export const setPollLogInProgressFilters = (filters: GridFilterModel) => {
  try {
    localStorage.setItem(POLL_LOG_PENDING_FILTERS, JSON.stringify(filters));
  } catch (err) {
    cabCaptureException(err);
  }
};

export const getPollLogInProgressFilters = () => {
  try {
    const data = localStorage.getItem(POLL_LOG_PENDING_FILTERS);
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  } catch (err) {
    cabCaptureException(err);
  }
};

export const setPollLogCompletedFilters = (filters: GridFilterModel) => {
  try {
    localStorage.setItem(POLL_LOG_COMPLETED_FILTERS, JSON.stringify(filters));
  } catch (err) {
    cabCaptureException(err);
  }
};

export const getPollLogCompletedFilters = () => {
  try {
    const data = localStorage.getItem(POLL_LOG_COMPLETED_FILTERS);
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  } catch (err) {
    cabCaptureException(err);
  }
};

export const setReconciliationFilters = (filters: GridFilterModel) => {
  try {
    localStorage.setItem(RECONCILIATION_TABLE_FILTERS, JSON.stringify(filters));
  } catch (err) {
    cabCaptureException(err);
  }
};

export const getReconciliationFilters = () => {
  try {
    const data = localStorage.getItem(RECONCILIATION_TABLE_FILTERS);
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  } catch (err) {
    cabCaptureException(err);
  }
};
