import { reactRouterV6BrowserTracingIntegration, init as sentryInit } from "@sentry/react";
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router';

const initSentry = (): void => {
  let sentrySampleRate = 0.0;
  try {
    sentrySampleRate = Number(import.meta.env.VITE_SENTRY_SAMPLE_RATE);
  } catch (e) {
    // Do nothing
  }

  sentryInit({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
    ],
    tracePropagationTargets: [import.meta.env.VITE_API_HOST as string],
    tracesSampleRate: sentrySampleRate,
    normalizeDepth: 10,
    release: import.meta.env.VITE_APP_FRONTEND_VERSION,
    environment: import.meta.env.VITE_DEPLOY_ENV,
    replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAY_SAMPLE_RATE, // Sample rate for all sessions
    replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAY_ERROR_SAMPLE_RATE, // Sample rate for sessions w errors
  });
};

if (process.env.NODE_ENV === 'production') {
  initSentry();
}