import { Checkbox, CheckboxProps, createSvgIcon, styled } from "@mui/material";
import { ReactElement } from "react";
import colors from "../../../colors";
import { CabComponentProps } from "../cabStyled";


export interface CabCheckboxProps extends CabComponentProps<CheckboxProps>{
  checked?: CheckboxProps['checked'];
  disabled?: CheckboxProps['disabled'];
  name?: CheckboxProps['name'];
  slot?: CheckboxProps['slot'];
  onChange?: CheckboxProps['onChange'];
}

export const CabCheckbox = ({
  checked, disabled, slot, onChange, name, sx, overrides
}: CabCheckboxProps): ReactElement => {
  const checkboxProps: CheckboxProps = {
    checked,
    disabled,
    slot,
    onChange,
    name,
    sx,
    disableRipple: true,
    icon: <CustomUnchecked />
  };

  return (
    <CheckboxStyled {...checkboxProps} {...overrides} />
  );
};

const CustomUnchecked = createSvgIcon(
  <>
    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
    <path fill="none" className="innerBox" d="M19 5v14H5V5h14" />
  </>,
  'CustomUnchecked'
);

const CheckboxStyled = styled(Checkbox, { label: 'CheckboxStyled' })<CabComponentProps<CheckboxProps>>(({ theme }) => ({
  ...(theme.palette.mode === 'dark' ? {
    '& .MuiSvgIcon-root .innerBox': {
      fill: colors.white100,
    },
    '&:hover': {
      '& .MuiSvgIcon-root': {
        color: colors.white200
      },
      '& .MuiSvgIcon-root .innerBox': {
        fill: colors.white200,
      },
    },
    '&.Mui-disabled': {
      '& .MuiSvgIcon-root': {
        color: colors.white50
      },
      '& .MuiSvgIcon-root .innerBox': {
        fill: colors.white50,
      },
    },
    '& .MuiSvgIcon-root': {
      color: colors.white100,
    },
    '&.Mui-checked': {
      '& .MuiSvgIcon-root': {
        color: colors.white900,
      },
      '&:hover': {
        '& .MuiSvgIcon-root': {
          color: colors.white800,
        },
      },
      '&.Mui-disabled': {
        '& .MuiSvgIcon-root': {
          color: colors.white500,
        },
      },
    }
  } : {
    '& .MuiSvgIcon-root .innerBox': {
      fill: colors.black100,
    },
    '&:hover': {
      '& .MuiSvgIcon-root': {
        color: colors.black200
      },
      '& .MuiSvgIcon-root .innerBox': {
        fill: colors.black200,
      },
    },
    '&.Mui-disabled': {
      '& .MuiSvgIcon-root': {
        color: colors.black50
      },
      '& .MuiSvgIcon-root .innerBox': {
        fill: colors.black50,
      },
    },
    '& .MuiSvgIcon-root': {
      color: colors.black100,
    },
    '&.Mui-checked': {
      '& .MuiSvgIcon-root': {
        color: colors.black900,
      },
      '&:hover': {
        '& .MuiSvgIcon-root': {
          color: colors.black900,
        },
      },
      '&.Mui-disabled': {
        '& .MuiSvgIcon-root': {
          color: colors.black500,
        },
      },
    }
  }),
  padding: 0,
}));
