import { ActionType } from '../actionTypes';
import { Auth } from './types';
import { RootAction } from '..';
import { setUserForMetrics } from '../../utils/appAnalyticsUtils';
import { FLOW_STATE } from '../../constants';

export const initialState: Auth = {
  flowState: FLOW_STATE.SIGNED_OUT,
  challenge: {name: '', params: {}, session: ''},
  isAuthenticated: false,
  isLoading: true,
  isRegistrationSaving: false,
  isOnboardingInitialized: false,
  user: null,
  email: null,
  showCongrats: false,
  showOnboarding: false,
  moderator: false,
  redirectUrl: null
};


export default function auth(state = initialState, action: RootAction): typeof initialState  {
  switch (action.type) {
    case ActionType.NO_TOKEN:
      return {...state, isLoading: false};

    case ActionType.USER_LOADING:
      return {...state, isLoading: true};

    case ActionType.CREATED_LEADER:
      if (state.user) {
        return {...state, user: {...state.user, profile: {...state.user.profile }}};
      }
      return state;

    case ActionType.UPDATE_ONBOARDING_COMPLETED:
      if (state.user) {
        return {
          ...state,
          user: {...state.user, profile: {...state.user.profile, app_onboarding_completed: action.completed } },
        };
      }
      return state;

    case ActionType.UPDATE_REFERENCE_TYPE:
      if (state.user) {
        return {
          ...state,
          user: {...state.user, profile: {...state.user.profile, reference_type: action.referenceType } },
        };
      }
      return state;

    case ActionType.USER_LOADED:
      setUserForMetrics(action.user);
      return {...state, flowState: FLOW_STATE.SIGNED_IN, isAuthenticated: true, isLoading: false, 
        user: action.user, email: action.email, showOnboarding: action.showOnboarding};

    case ActionType.UPDATED_USER_PROFILE: {
      if (!state.user) return state;

      return {
        ...state,
        user: {
          ...state.user,
          ...action.userData,
          profile: {
            ...state.user.profile,
            ...action.profile,
          }
        },
      };
    }

    case ActionType.UPDATED_USER_PREFS: {
      if (state.user) {
        return {
          ...state,
          user: {
            ...state.user, 
            prefs: {
              ...state.user.prefs,
              ...action.userPrefs
            }
          }
        };
      }
      return state;
    }

    case ActionType.UPDATED_USER_ACTIVE_LICENSE: {
      if (!state.user?.active_license || !action.license.id) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user, 
          active_license: {
            ...state.user.active_license,
            ...action.license,
          },
        }
      };
    }

    case ActionType.ACCEPTED_TOS: {
      if (state.user) {
        return {
          ...state,
          user: {
            ...state.user, 
            tos_acceptance_required: false,
          }
        };
      }
      return state;
    }

    case ActionType.ONBOARDING_INITIALIZED: {
      return {
        ...state,
        isOnboardingInitialized: action.isOnboardingInitialized,
      };
    }



    case ActionType.LOGIN_SUCCESSFUL:
      setUserForMetrics(action.user);
      if (action.stayLoggedIn) {
        // do something with cognito to use this
      }
      
      return {...state, flowState: FLOW_STATE.SIGNED_IN, user: action.user, isAuthenticated: true, isLoading: false,  
        showOnboarding: action.showOnboarding
      };

    case ActionType.PASSWORD_CHANGE_REQUIRED:
      return {...state, flowState: FLOW_STATE.CONFIRM_FORGOT_PASSWORD};

    case ActionType.PASSWORD_CHANGE_SUCCESFUL:
      return {...initialState, isLoading: false, flowState: FLOW_STATE.FORGOT_PASSWORD_SUCCESFUL};
    
    case ActionType.REGISTRATION_INCOMPLETE:
      return {...state, flowState: FLOW_STATE.REGISTRATION_INCOMPLETE};

    case ActionType.SAVED_OAUTH_GRANT:
      if (state.user) {
        return {
          ...state, user: {
            ...state.user, 
            oauth_grant_details: {
              ...state.user?.oauth_grant_details, 
              [action.provider]: {
                ...state.user?.oauth_grant_details[action.provider],
                [action.username]: action.grant_details
              }
            }
          }
        };
      } else {
        return state;
      }

    case ActionType.AUTHENTICATION_ERROR:
    case ActionType.LOGIN_FAILED:
    case ActionType.PASSWORD_CHANGE_CANCELLED:
      return {...initialState, isLoading: false};

    case ActionType.LOGOUT_SUCCESSFUL:
      return {...initialState, isLoading: false};

    case ActionType.SET_AUTH_REDIRECT:
      return {...state, redirectUrl: action.url};

    case ActionType.LOGGED_OUT_OAUTH: 
      if (state.user?.oauth_grant_details) {
        const newOauthGrantDetails = state.user?.oauth_grant_details;
        delete newOauthGrantDetails[action.provider][action.username];
        return {
          ...state, 
          user: {
            ...state.user,
            oauth_grant_details: newOauthGrantDetails
          }};
      } else {
        return state;
      }

    default:
      return state;
  }
}