import { useEffect, useRef, useState, useMemo, useLayoutEffect } from 'react';
import { Breakpoint } from '@mui/material/styles';
import { invert } from 'lodash-es';
import { NavigateOptions, To, useLocation, useNavigate } from 'react-router';
import { PAGE_URL } from '../constants';


// This hook allows us to only execute something once on mount of the component
// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = (fun: () => void): void => (useEffect(fun, []));

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

// NOTE: width can sometimes jitter so we need to check against tolerance
export const useElementWidth = (
  elRef: React.RefObject<Element>,
  defaultWidth: number,
  tolerance = 0,
) => {
  const [elWidth, setElWidth] = useState(elRef.current?.getBoundingClientRect().width || defaultWidth);

  useLayoutEffect(() => {
    const resizeObserverCallback: ResizeObserverCallback = (event) => {
      const lastEvent = event[event.length - 1];
      // This ResizeObserver API can be a bit finicky, so we need to make sure these objects exist
      // e.g. https://cabinet.sentry.io/issues/3905603220/
      const newWidth = lastEvent?.borderBoxSize?.[0]?.inlineSize;

      if (newWidth && Math.abs(newWidth - elWidth) > tolerance) {
        setElWidth(newWidth);
      }
    };

    // setTimeout preventing an error here: https://github.com/juggle/resize-observer/issues/103#issuecomment-1711148285
    const resizeObserverCallbackWithTimeout: ResizeObserverCallback = ((event, ob) => {
      setTimeout(() => {
        resizeObserverCallback(event, ob);
      }, 0);
    });

    // NOTE: we don't want to use setTimeout wrapper when testing because resize observer is mocked and tests will break
    const resizeObserver = new ResizeObserver(
      process.env.NODE_ENV !== 'test' ? resizeObserverCallbackWithTimeout : resizeObserverCallback
    );

    if (elRef.current !== null) {
      resizeObserver.observe(elRef?.current);
    }
    return () => {
      resizeObserver.disconnect();
    };

  }, [elRef, elWidth, tolerance]);

  return elWidth;
};

export const useElementBreakpoint = (
  elRef: React.RefObject<Element>,
  breakpoints: {[px: number]: Breakpoint},
  defaultBreakpoint: Breakpoint,
  tolerance = 0
) => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(defaultBreakpoint);
  const sortedPx = useMemo(() => (
    Object.keys(breakpoints).map(Number).sort((a, b) => a - b)
  
  // NOTE: need breakpoints to have a consistent hash value
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [JSON.stringify(breakpoints)]);

  const defaultWidthStr = invert(breakpoints)[defaultBreakpoint];
  const defaultWidth = defaultWidthStr ? Number(defaultWidthStr) : 0;

  const elWidth = useElementWidth(elRef, defaultWidth, tolerance);

  useLayoutEffect(() => {
    let newBreakpoint: Breakpoint = defaultBreakpoint;
    let lastPx = 0;

    sortedPx.forEach(px => {
      if (elWidth > lastPx) {
        newBreakpoint = breakpoints[px];
      }
      lastPx = px;
    });

    setBreakpoint(newBreakpoint);
  // NOTE: need breakpoints to have a consistent hash value
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(breakpoints), elWidth, sortedPx]);

  return breakpoint;
};


const pathDisplayName: Record<string, string> = {
  [PAGE_URL.CRM_PEOPLE]: 'People',
  [PAGE_URL.CRM_RELATIONSHIPS]: 'Relationships',
  [PAGE_URL.CRM_COMPANIES]: 'Companies',
};

export const useCabNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (to: To, options?: NavigateOptions) => {
    navigate(to, { ...options, state: { ...options?.state, from: {
      ...options?.state?.from,
      path: location.pathname,
      displayName: pathDisplayName[location.pathname],
    } } });
  };
};
