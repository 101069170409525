export const baseUrl = (import.meta.env.PUBLIC_URL || '') + '/';

export const PAGE_URL = {
  DASHBOARD: baseUrl,
  TODO: baseUrl + 'todo',
  MEETINGS: baseUrl + 'meetings',
  REUSABLE_MEETINGS: baseUrl + 'reusable-meetings',
  POLL_RESULTS: baseUrl + 'polls',
  SCHEDULE: baseUrl + 'schedule',
  LOGIN: baseUrl + 'login',
  RESET_PASS: baseUrl + 'reset-password',
  SIGNUP: baseUrl + 'signup',
  UPDATE: baseUrl + 'update',
  EXECUTIVE: baseUrl + 'executive',
  SETTINGS: baseUrl + 'settings',
  PREFERENCES: baseUrl + 'settings/preferences',
  MY_TEAM: baseUrl + 'settings/team',
  ACCOUNT: baseUrl + 'settings/account',
  ANALYTICS_TAB: baseUrl + 'settings/analytics',
  CRM_SETTINGS: baseUrl + 'settings/crm',
  SUPPORT_MAP: baseUrl + 'settings/support-map',
  INTEGRATION_SETTINGS: baseUrl + 'integrations',
  BOOK_MEETING: baseUrl + 'book',
  GROUP_SCHEDULING_PARTICIPANT: baseUrl + 'poll',
  TEST: baseUrl + 'test',
  ONBOARDING: baseUrl + "onboarding",
  MAINTENANCE: baseUrl + "maintenance",
  SSO_SEARCH: baseUrl + "sso",
  RECONCILIATION: baseUrl + "reconciliation",
  INSIGHTS: baseUrl + "insights",
  MANAGE_CALENDARS: baseUrl + "manage-calendars",
  TERMS_OF_SERVICE: baseUrl + "terms-of-service",
  CHANGE_EMAIL: baseUrl + "change-email",
  CRM_RELATIONSHIPS: baseUrl + "relationships",
  CRM_PEOPLE: baseUrl + "people",
  CRM_COMPANIES: baseUrl + "companies",
  CRM_SEARCH: baseUrl + "search",
};

export const EXEC_SUBPAGE = {
  AIRLINES: 'airlines',
  PERSONAL: 'personal',
  HOTELS: 'hotels',
  PROFILE_CATEGORIES: 'category',
};

const INTEGRATIONS_OAUTH_ROOT = `${PAGE_URL.INTEGRATION_SETTINGS}/oauth`;
export const MICROSOFT_OAUTH_REDIRECT_URI = `${INTEGRATIONS_OAUTH_ROOT}/microsoft`;

export const MICROSOFT_INCREMENTAL_SCOPES_LS_KEY = "msIncrementalScopes";
export const MICROSOFT_CATEGORIES_INCREMENTAL_SCOPES = ["mscategories"];

export enum OLD_TIER {
  BASIC = 'BASIC',
  ESSENTIALS = 'ESSENTIALS',
  PRO = 'PRO'
}

export const OLD_TIER_DETAILS: {[key in keyof typeof OLD_TIER as string]: ITier} = {
  BASIC: {
    title: 'Basic',
    description: `The Basic tier includes access to Cabinet's scheduling tool designed to save you hours every week.`,
    annualPrice: 0,
    monthlyPrice: 0,
  },
  ESSENTIALS: {
    title: 'Essentials',
    description: `Get upgraded scheduling tools, integration with Zoom, and more with Cabinet Essentials.`,
    annualPrice: 144,
    monthlyPrice: 15,
  },
  PRO: {
    title: 'Pro',
    description: 'Get Cabinet Pro to unlock collaboration with your team so you can get more done together.',
    annualPrice: 374,
    monthlyPrice: 39,
  }
};

export enum NEW_TIER {
  BASIC = 'BASIC',
  INDIVIDUAL = 'INDIVIDUAL',
  STARTER = 'STARTER',
  GROWTH = 'GROWTH',
  PREMIER = 'PREMIER'
}

export const NEW_TIER_IDS: {
  [key in keyof typeof NEW_TIER as string]: number
} = {
  INDIVIDUAL: 100,
  STARTER: 101,
  GROWTH: 102,
  PREMIER: 103,
  BASIC: -1,
};

export type NewITier = ITier & {
  selfService: boolean
  visible: boolean
};

export const NEW_TIER_DETAILS: {[key in keyof typeof NEW_TIER as string]: NewITier} = {
  BASIC: {
    title: 'Basic',
    description: `The Basic tier includes access to Cabinet's scheduling tool designed to save you hours every week.`,
    annualPrice: 0,
    monthlyPrice: 0,
    visible: false,
    selfService: false
  },
  INDIVIDUAL: {
    title: 'Individual',
    description: `For managing one or multiple calendars`,
    annualPrice: 372,
    monthlyPrice: 39,
    visible: true,
    selfService: true
  },
  STARTER: {
    title: 'Starter',
    description: 'For managing one or multiple calendars while collaborating with a small team of EAs and Assistants',
    annualPrice: 516,
    monthlyPrice: 49,
    visible: true,
    selfService: true
  },
  GROWTH: {
    title: 'Growth',
    description: `For managing one or multiple calendars while collaborating with a small team of EAs and Assistants 
    with all of the enterprise security features you expect`,
    annualPrice: 0,
    monthlyPrice: 0,
    visible: true,
    selfService: false
  },
  PREMIER: {
    title: 'Premier',
    description: 'For teams looking for extensive services to meet all enterprise needs',
    annualPrice: 0,
    monthlyPrice: 0,
    visible: true,
    selfService: false
  }
};

export type TIER = OLD_TIER | NEW_TIER;

export enum USER_ROLE {
  ASSISTANT = 'assistant',
  INDIVIDUAL = 'individual',
}

export enum FLOW_STATE {
  SIGNED_IN = "SIGNED_IN",
  SIGNED_OUT = "SIGNED_OUT",
  CONFIRM_FORGOT_PASSWORD = "CONFIRM_FORGOT_PASSWORD",
  PASSWORD_CHANGE_REQUIRED = "PASSWORD_CHANGE_REQUIRED",
  FORGOT_PASSWORD_SUCCESFUL = "FORGOT_PASSWORD_SUCCESFUL",
  REGISTRATION_INCOMPLETE = "REGISTRATION_INCOMPLETE"
}

export enum BILLING_INTERVAL {
  MONTH = 'month',
  YEAR = 'year',
}

export const ANALYTICS_INTERVAL = {
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
};

export const MEETING_TYPE = {
  ONE_OFF: 'One-off Meeting',
  REUSABLE: 'Reusable Meeting',
  POLL: 'Meeting Poll',
};

export const QUESTION_TYPE: {[key: number]: string} = {
  1: 'Text', 
  2: 'Single-Select',
  3: 'Multi-Select'
};

export enum ANALYTICS_REPORT_TYPE {
  TAG,
  LEADER
}

export enum ANALYTICS_METRIC_TYPE {
  TASKS,
  TIME
}

export enum NoteType {
  AIRLINE,
  PERSONAL,
  LEADER,
  HOTEL,
  PROFILE
}

export enum ERROR_TYPE {
  REQUIRED = 'REQUIRED',
  TOO_LONG = 'TOO_LONG',
  EMAIL = 'EMAIL',
}

export const ERROR_MESSAGE = {
  [ERROR_TYPE.REQUIRED]: 'required',
  [ERROR_TYPE.TOO_LONG]: 'too Long',
  [ERROR_TYPE.EMAIL]: 'not a valid email format'
};

export const NOTE_FIELD_LABELS = {
  first_name: 'First Name',
  last_name: 'Last Name',
  role: 'Job Title',
  company: 'Company',
  email: 'Email Address',
  postal_code: 'Postal Code',
  industry: 'Industry',
  country: 'Country',
  notes: 'General Notes',
  username: 'Username',
  loyalty_number: 'Loyalty #',
  known_traveler: 'Traveler Number',
  phone_number: 'Phone Number',
  name: 'Name',
  birthday: 'Birthday',
  health_insurance: 'Health Insurance',
  address: 'Address',
  airline_seat_preference: 'Airline Seat Preference',
  relation: 'Relation',
  title: 'Title',
  airline_name: 'Airline Name',
  airline_phone_number: 'Airline Phone Number',
  airline_website_url: 'Airline Website',
  hotel_name: 'Hotel Name',
  hotel_phone_number: 'Website Phone Number',
  hotel_website_url: 'Hotel Website'
};

export const TASK_FIELD_LABELS = {
  title: 'Title',
  description: 'Description',
  due_date: 'Due Date',
  completed_date: 'Completed Date',
  priority: 'Priority',
  labels: 'Labels',
  leaders: 'Associated Executives',
  checklist: 'Checklist',
  interval: 'Recurrence Schedule',
  first_occurence: 'First Occurence Date',
  minutes_spent: 'Track Time',
  time_track_remind: 'Remind me to track time for this task'
};

export enum GADGET {
  COMMUNITY = 'COMMUNITY',
  DASHBOARD = 'DASHBOARD',
  MEETINGS = 'MEETINGS',
  POLLS = 'POLLS',
  SCHEDULE = 'SCHEDULE',
  PROFILES = 'PROFILES',
  ANALYTICS = 'ANALYTICS',
  USER = 'USER',
}

export const TASK_STATUS = {
  REQUESTED: 1,
  NOT_STARTED: 2,
  IN_PROGRESS: 3,
  COMPLETE: 4,
};

export const TASK_PRIORITY = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
  URGENT: 3,
};

export const DEFAULT_LEADER_LABEL_COLORS = [
  '#B81111', '#895656', '#DD2C00', '#D2620E', '#5F2B01', '#C3A328', '#827717', '#6A771B', '#08790C', '#2E7D32', 
  '#17601B', '#2D8F48', '#00838F', '#1889A1', '#445964', '#093147', '#0277BD', '#01579B', '#1C6BB4', '#376FFF', 
  '#23379E', '#5C6BC0', '#001ED6', '#7C4DFF', '#651FFF', '#4A17A5', '#4A148C', '#AA00FF', '#9320AB', '#890D38' 
];

export const FEEDBACK_MAX_LENGTH = 4000;
export const TASK_TITLE_MAX_LENGTH = 500;
export const PROFILE_CATEGORY_MAX_LENGTH = 30;

// eslint-disable-next-line max-len, no-useless-escape
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const companyRegex = /^([a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;


export const TIME_UNITS = {
  minutes: 1,
  hours: 60,
};

export enum EXEC_CARD_TYPES {
  AIRLINES = 'AIRLINES',
  FAMILY = 'FAMILY',
  HOTELS = 'HOTELS',
  CUSTOM = 'CUSTOM', 
}

export enum ANALYTICS_OPTIONS {
  INTERVAL = 'INTERVAL', 
  START_DATE = 'START_DATE', 
  END_DATE = 'END_DATE',
}

export enum EVENT_TYPE {
  START_TASK_CREATION = 'StartTaskCreation', 
  CREATE_TASK = 'CreateTask', 
  UPDATE_TASK = 'UpdateTask', 
  DELETE_TASK = 'DeleteTask', 
  COMPLETE_TASK = 'CompleteTask', 
  UNCOMPLETE_TASK = 'UncompleteTask', 
  FILTER_TASKS = 'FilterTasks',  // has extra {'Labels': TF, 'Executives': TF, 'Urgent': TF}
  CHECK_RECURRING_TASKS = 'CheckRecurringTasks', 
  CHECK_LABELS = 'CheckLabels', 
  CHECKLIST_ITEM_COMPLETE = 'ChecklistItemComplete', 
  CHECKLIST_ITEM_UNCOMPLETE = 'ChecklistItemUncomplete', 
  CANCEL_CUSTOM_RECURRING_SCHEDULE = 'CancelCustomRecurringSchedule', 
  CHANGE_ANALYTICS_TIME = 'ChangeAnalyticsTime',  // has extra {'option': 'start date' | 'end date' | 'interval'}
  CHANGE_ANALYTICS_REPORT_TYPE = 'ChangeAnalyticsReportType',  // has extra {'type': 'TAG' | 'LEADER'}
  CHANGE_ANALYTICS_METRIC_TYPE = 'ChangeAnalyticsMetricType',  // has extra {'type': 'TIME' | 'TASKS'}
  TAB_COMPLETE = 'TabComplete', 
  TAB_IN_PROGRESS = 'TabInProgress', 
  START_EXEC_CREATION = 'StartExecCreation', 
  CREATE_EXEC = 'CreateExec', 
  UPDATE_EXEC = 'UpdateExec', 
  DELETE_EXEC = 'DeleteExec', 
  CREATE_EXEC_CATEGORY = 'CreateExecCategory', 
  UPDATE_EXEC_CATEGORY = 'UpdateExecCategory', 
  DELETE_EXEC_CATEGORY = 'DeleteExecCategory', 
  // CREATE_EXEC_CATEGORY_CARD has extra {'category': 'airlines' | 'hotels' | 'family' | 'custom'}
  CREATE_EXEC_CATEGORY_CARD = 'CreateExecCategoryCard',
  // UPDATE_EXEC_CATEGORY_CARDhas extra {'category': 'airlines' | 'hotels' | 'family' | 'custom'}
  UPDATE_EXEC_CATEGORY_CARD = 'UpdateExecCategoryCard', 
  // DELETE_EXEC_CATEGORY_CARD has extra {'category': 'airlines' | 'hotels' | 'family' | 'custom'}
  DELETE_EXEC_CATEGORY_CARD = 'DeleteExecCategoryCard', 
  COPY_CARD_FIELD = 'CopyCardField', 
  SHARE_EXEC_PROFILE = 'ShareExecProfile', 
  REVOKE_EXEC_PROFILE = 'RevokeExecProfile', 
  ONBOARD_WELCOME = 'Onboard: Welcome', 
  ONBOARD_ANALYTICS = 'Onboard: Anlytics', 
  ONBOARD_PROFILE = 'Onboard: Profile', 
  ONBOARD_EXEC = 'Onboard: Exec', 
  ONBOARD_CONNECT = 'Onboard: Connect Accounts', 
  ONBOARD_CALENDARS = 'Onboard: Calendars', 
  ONBOARD_CATEGORIES = 'Onboard: Categories',
  ONBOARD_COMPLETE = 'Onboard: Complete', 
  ONBOARD_SIGNUP = 'Onboard: Sign Up',
  ONBOARD_NAME_PASS = 'Onboard: Name and Password',
  ONBOARD_VERIFY_EMAIL = 'Onboard: Verify Email',
  ONBOARD_USER_TYPE = 'Onboard: User Type',
  ONBOARD_BOOK_ENTERPRISE_MEETING= 'Onboard: Book Enterpris Meeting',
  ONBOARD_CHOOSE_PLAN = 'Onboard: Choose Plan',
  ONBOARD_TIER_SELECTION = 'Onboard: New Tier Selected', // has extra
  ONBOARD_CONTINUE_WITH_PLAN = 'Onboard: Plan Tier Continued', // has extra
  ONBOARD_CONNECT_CALENDAR = 'Onboard: Connect Calendar',
  ONBOARD_EXEC_CALENDAR = 'Onboard: Exec Calendars',
  ONBOARD_ADD_CALENDARS = 'Onboard: Add Calendars',
  ONBOARD_CONFERENCE_CONNECT = 'Onboard: Conference Connect',
  ONBOARD_ONE_LAST_THING = 'Onboard: Company Info',
  ONBOARD_FEATURE_VIDEO = 'Onboard: Feature Video',
  ONBOARD_INTRO_CALL = 'Onboard: Intro Call',
  ONBOARD_SKIP_CALENDARS = 'Onboard: Skip Calendar Connect',
  ONBOARD_SKIP_CALENDARS_EXEC = 'Onboard: No Calendars Connected to Execs',
  ONBOARD_MS_DELEGATE = 'Onboard: Delegate email used',
  SCHEDULING_OAUTH_GOOGLE = 'Scheduling: OAuth Google',
  SCHEDULING_OAUTH_MICROSOFT = 'Scheduling: OAuth Microsoft',
  SCHEDULING_OAUTH_ZOOM = 'Scheduling: OAuth Zoom',
  SCHEDULING_ASSOCIATE = 'Scheduling: Associate Calendars',
  SCHEDULING_CREATE_MEETING = 'Scheduling: Create Meeting',
  SCHEDULING_COPY_SLOTS = 'Scheduling: Copy Slots',
  SCHEDULING_COPY_LINK = 'Scheduling: Copy Link',
  SCHEDULING_SCHEDULE = 'Scheduling: Schedule Meeting',
  SCHEDULING_CHANGE_STATUS = 'Scheduling: Change Meeting Status',
  SCHEDULING_CHANGE_PRIORITY = 'Scheduling: Change Meeting Priority',
  SCHEDULING_CHANGE_DATE_SCHEDULED = 'Scheduling: Change Meeting Date Scheduled',
  SCHEDULING_POLL_CHANGE_STATUS = 'Scheduling: Change Meeting Poll Status',
  MEETING_BOOKED = 'Booking: Meeting booked through external link',
  MEETING_BOOKED_SIGNUP = 'Booking: Sign Up CTA Clicked',
  MEETING_POLL_BOOKED = 'Polls: Meeting Poll booked through external link',
  MEETING_POLL_SIGNUP = 'Polls: Sign up CTA Clicked',
  PROMO_TIER_CLICKED = 'Promo: Tier Clicked',
  SUBSCRIPTION_LEARN_MORE_CLICKED = 'Subscription: Learn More Clicked',
  SUBSCRIPTION_TIER_CLICKED = 'Subscription: Tier Clicked',
  SUBSCRIPTION_TIER_CONFIRMED = 'Subscription: Pro Confirmed',
  SUBSCRIPTION_CHANGED = 'Subscription: Changed',
  PURCHASE = 'purchase',
  SALES_OAUTH_SALESFORCE = 'Sales: OAuth Salesforce'
}

type ProviderKey = 'GOOGLE'|'MICROSOFT'|'ZOOM'|'SALESFORCE'|'NO_PROVIDER';

export const GRANT_KEY_INT_TO_STRING: {[key: number]: string} = {
  1: "google",
  2: "microsoft",
  3: "zoom",
  4: "hupspot",
  5: "salesforce"
};

export const PROVIDER: {[key in ProviderKey]: {id: number, name: string, label: string}} = {
  GOOGLE: {
    id: 1, 
    name: 'google',
    label: 'Google',
  },
  MICROSOFT: {
    id: 2,
    name: 'microsoft',
    label: 'Microsoft',
  },
  ZOOM: {
    id: 3,
    name: 'zoom',
    label: 'Zoom',
  },
  SALESFORCE: {
    id: 5,
    name: 'salesforce',
    label: 'Salesforce'
  },
  NO_PROVIDER: {
    id: 999,
    name: "no-provider",
    label: "None"
  }
};

const  id_to_provider: {[id: number]: {id: number, name: string, label: string}} = {};
Object.values(PROVIDER).forEach(provider => {
  id_to_provider[provider.id] = provider;
});
export const PROVIDER_BY_ID = id_to_provider; 

const name_to_provider: {[name: string]: {id: number, name: string, label: string}} = {};
Object.values(PROVIDER).forEach(provider => {
  name_to_provider[provider.name] = provider;
});
export const PROVIDER_BY_NAME = name_to_provider; 

export const PROVIDER_CONFERENCE_TYPES: { 
  [providerId: string]: { id: number, key: string, label: string }[] 
} = {
  // NOTE: Google can also have "addOn" as a potential conference solution that represents 3rd party conference
  //       providers like Zoom, but I'm not sure if it's ever indicated on a calendar. It might just be on events if you
  //       book an event with a 3rd party. We have not seen it live, but we will get a Sentry alert if it shows up
  [PROVIDER.GOOGLE.id]: [
    { id: 1, key: "hangoutsMeet", label: 'Google Meet' },
    { id: 1, key: "eventHangout", label: 'Google Meet' },
    { id: 1, key: "eventNamedHangout", label: 'Google Meet' },
  ],
  [PROVIDER.MICROSOFT.id]: [
    { id: 2, key: "teamsForBusiness", label: 'Microsoft Teams' },
    { id: 2, key: "skypeForBusiness", label: 'Skype for Business' },
    { id: 2, key: "skypeForConsumer", label: 'Skype' },
  ],
  [PROVIDER.ZOOM.id]: [
    { id: 3, key: "zoom", label: 'Zoom' },
  ],
  [PROVIDER.NO_PROVIDER.id]: [
    { id: 999, key: "no-provider", label: 'None' },
  ],
};

export const MICROSOFT_CATEGORY_SCOPE = ['MailboxSettings.Read'];

export enum ZOOM_CONNECT_STRATEGY {
  OBO = 'OBO',
  PMI = 'PMI',
}

export const GOOGLE_COLORS = {
  'kind': 'calendar#colors',
  'updated': '2012-02-14T00:00:00.000Z',
  'calendar': {
    '1': {
      'background': '#795548',
      'foreground': '#1d1d1d'
    },
    '2': {
      'background': '#e67c73',
      'foreground': '#1d1d1d'
    },
    '3': {
      'background': '#d50000',
      'foreground': '#1d1d1d'
    },
    '4': {
      'background': '#f4511e',
      'foreground': '#1d1d1d'
    },
    '5': {
      'background': '#ef6c00',
      'foreground': '#1d1d1d'
    },
    '6': {
      'background': '#f09300',
      'foreground': '#1d1d1d'
    },
    '7': {
      'background': '#009688',
      'foreground': '#1d1d1d'
    },
    '8': {
      'background': '#0b8043',
      'foreground': '#1d1d1d'
    },
    '9': {
      'background': '#7cb342',
      'foreground': '#1d1d1d'
    },
    '10': {
      'background': '#c0ca33',
      'foreground': '#1d1d1d'
    },
    '11': {
      'background': '#e4c441',
      'foreground': '#1d1d1d'
    },
    '12': {
      'background': '#f6bf26',
      'foreground': '#1d1d1d'
    },
    '13': {
      'background': '#33b679',
      'foreground': '#1d1d1d'
    },
    '14': {
      'background': '#039be5',
      'foreground': '#1d1d1d'
    },
    '15': {
      'background': '#4285f4',
      'foreground': '#1d1d1d'
    },
    '16': {
      'background': '#3f51b5',
      'foreground': '#1d1d1d'
    },
    '17': {
      'background': '#7986cb',
      'foreground': '#1d1d1d'
    },
    '18': {
      'background': '#b39ddb',
      'foreground': '#1d1d1d'
    },
    '19': {
      'background': '#616161',
      'foreground': '#1d1d1d'
    },
    '20': {
      'background': '#a79b8e',
      'foreground': '#1d1d1d'
    },
    '21': {
      'background': '#ad1457',
      'foreground': '#1d1d1d'
    },
    '22': {
      'background': '#d81b60',
      'foreground': '#1d1d1d'
    },
    '23': {
      'background': '#8e24aa',
      'foreground': '#1d1d1d'
    },
    '24': {
      'background': '#9e69af',
      'foreground': '#1d1d1d'
    }
  },
  'event': {
    '1': {
      'background': '#7986cb',
      'foreground': '#1d1d1d'
    },
    '2': {
      'background': '#33b679',
      'foreground': '#1d1d1d'
    },
    '3': {
      'background': '#8e24aa',
      'foreground': '#1d1d1d'
    },
    '4': {
      'background': '#e67c73',
      'foreground': '#1d1d1d'
    },
    '5': {
      'background': '#f6bf26',
      'foreground': '#1d1d1d'
    },
    '6': {
      'background': '#f4511e',
      'foreground': '#1d1d1d'
    },
    '7': {
      'background': '#039be5',
      'foreground': '#1d1d1d'
    },
    '8': {
      'background': '#616161',
      'foreground': '#1d1d1d'
    },
    '9': {
      'background': '#3f51b5',
      'foreground': '#1d1d1d'
    },
    '10': {
      'background': '#0b8043',
      'foreground': '#1d1d1d'
    },
    '11': {
      'background': '#d50000',
      'foreground': '#1d1d1d'
    }
  }
};

export const MICROSOFT_COLORS = {
  "preset0": "#E74856",  // Red
  "preset1": "#FF8C00",  // Orange
  "preset2": "#FFAB45",  // Brown
  "preset3": "#FFF100",  // Yellow
  "preset4": "#47D041",  // Green
  "preset5": "#30C6CC",  // Teal
  "preset6": "#73AA24",  // Olive
  "preset7": "#00BCF2",  // Blue
  "preset8": "#8764B8",  // Purple
  "preset9": "#F495BF",  // Cranberry
  "preset10": "#A0AEB2", //  Steel
  "preset11": "#004B60", //  DarkSteel
  "preset12": "#B1ADAB", //  Gray
  "preset13": "#5D5A58", //  DarkGray
  "preset14": "#000000", //  Black
  "preset15": "#750B1C", //  DarkRed
  "preset16": "#C8421A", //  DarkOrange
  "preset17": "#AB620D", //  DarkBrown
  "preset18": "#C19C00", //  DarkYellow
  "preset19": "#004B1C", //  DarkGreen
  "preset20": "#004B50", //  DarkTeal
  "preset21": "#0B6A0B", //  DarkOlive
  "preset22": "#002050", //  DarkBlue
  "preset23": "#32145A", //  DarkPurple
  "preset24": "#5C005C", //  DarkCranberry
};


export const CABINET_COLORS = {
  frenchBlue: '#b5c9f7',
  primary: '#252d49',
  secondary: '#3a4e64',
  primaryTint: '#e7ebf7',
  tertiary: '#79a6d5',
  coral: '#eba083',
  platinum: '#9e9e9e',
  black: '#000000',
  white: '#ffffff',
  cream: '#F0ECE5'
};

export const SCHEDULER_SLOT_LENGTH = 15;

export enum SCHEDULING_FORM_ERRORS {
  MEETING_NAME_NOT_ENTERED = 'Please enter a meeting name or recipient',
  NO_SELECTED_LEADER = 'Please select an executive',
}

export const MEETING_STATUS_LABELS: {[key: number]: string} = {
  1: 'Pending', 
  2: 'Scheduled', 
  3: 'No Response',
  4: 'Postponed', 
  5: 'Cancelled',
};

export const SLOT_INTERVAL_LABELS: {[key: number]: string} = {
  0: 'Automatic',
  15: 'Every 15 mins (i.e. 1:00, 1:15)', 
  20: 'Every 20 mins (i.e. 1:00, 1:20)', 
  30: 'Every 30 mins (i.e. 1:00, 1:30)', 
  60: 'Every 60 mins (i.e. 1:00, 2:00)', 
};

export const SELECTED_INTERVAL_LABELS: {[key: number]: string} = {
  0: 'Automatic',
  15: 'Every 15 mins', 
  20: 'Every 20 mins', 
  30: 'Every 30 mins', 
  60: 'Every 60 mins', 
};

export const MEETING_AUDIT_STATUS_LABELS: {[key: number]: string} = {
  1: 'Pending', 
  2: 'Rescheduled', 
  3: 'No Response', 
  4: 'Postponed', 
  5: 'Cancelled'
};

export const REUSABLE_MEETING_STATUS_LABELS: {[key: number]: string} = {
  1: 'Active',
  5: 'Inactive'
};

export const MEETING_PRIORITY_LABELS: {[key: number]: string} = {
  3: 'High', 
  6: 'Medium', 
  9: 'Low'
};

export const MEETING_DURATION_HOURS = Array(13).fill(0).map((_, i) => ({value: i, label: i.toString()}));
export const MEETING_DURATION_MINUTES = Array(12).fill(0).map((_, i) => {
  const value = i * 5;
  if (value.toString().length < 2) {
    return {value, label: `0${value}`};
  } else {
    return {value, label: `${value}`};
  }
});

export const MEETING_DURATION_UNITS = {
  MINUTES: 'Minutes',
  HOURS: 'Hours',
  DAYS: 'Days'
};

export const COMMUNITY_QUESTION_SORT_METHODS = {
  RECENTLY_ASKED: 'recentlyAsked',
  RECENTLY_ANSWERED: 'recentlyAnswered',
  MOST_POPULAR: 'mostPopular',
  NEEDS_LOVE: 'needsLove',
};

export const PRIVACY_POLICY = 'https://www.joincabinet.com/privacy-policy';
export const TERMS_OF_SERVICE = 'https://www.joincabinet.com/terms-of-use';
export const LARGE_TEAMS = 'https://www.joincabinet.com/large-teams';
export const APP_STORE_URL = 'https://apps.apple.com/us/app/cabinet-assistants-and-admins/id1469135840';
export const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?' + 
'id=com.joincabinet.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
export const LEARN_MORE_ZOOM = 'https://support.joincabinet.com/en/articles/' +
'6178956-unique-zoom-meetings-vs-personal-meeting-rooms';

export const HELP_CENTER_URL = 'https://support.joincabinet.com';
export const MS_CALENDAR_HELP_ARTICLE_URL = 
'https://support.joincabinet.com/en/articles/6167768-what-if-i-can-t-view-my-calendars-in-cabinet';

export const CABINET_LANDING_PAGE_URL = 'https://www.joincabinet.com/';
export const CABINET_FEATURES_PAGE_URL = CABINET_LANDING_PAGE_URL + 'features/';

export enum ONBOARD_STEP {
  LOADING,
  SIGNUP_EMAIL,
  SIGNUP_NAME_PASS,
  VERIFY_EMAIL,
  USER_TYPE,
  CHOOSE_PLAN,
  BOOK_ENTERPRISE_MEETING,
  WELCOME_CALENDAR_CONNECT,
  EXEC_CALENDARS,
  ADD_CALENDARS,
  CONFERENCE_CONNECT,
  ONE_LAST_THING,
}

export enum CognitoProvider {
  COGNITO = "Cognito",
  GOOGLE = "Google",
  CUSTOM = "Custom"
}

interface ITier {
  title: string;
  description: string;
  annualPrice: number;
  monthlyPrice: number;
}

export const DEFAULT_PAGINATION_COUNT = 20;

export const ALLOWED_IMG_EXTS = ['.png', '.jpg', '.jpeg', '.tif', '.webp'];
export const ALLOWED_DOC_EXTS = [
  '.txt', '.rtf', '.doc', '.docx', '.ppt', '.pptx', '.pdf', '.xls', '.xlsx',
  '.key', '.csv', '.tsv', '.gdoc', '.gsheet', '.gslides', '.json', '.eml', '.emltpl',
  '.ics', '.html', '.yml', '.xml', '.msg'
];

export const ALLOWED_COMPRESSED_EXTS = [".zip"];

export const ACCEPTED_MIME_EXTS = [
  ...ALLOWED_IMG_EXTS,
  ...ALLOWED_DOC_EXTS,
  ...ALLOWED_COMPRESSED_EXTS
];

export const HEADER_HEIGHT = 65;

export const FORCE_LOGOUT_REASON = { 
  SESSION_TIMEOUT: "org_session_timeout",
  SESSION_TIMEOUT_NEW_DAY: "org_session_timeout_new_day",
  SESSION_LOGOUT: "session_logout",
};