import { BoxProps, Box, CircularProgress, styled, CircularProgressProps } from '@mui/material';
import {ReactElement} from 'react';
import { CabComponentProps } from '../cabStyled';

export interface ICabSpinner extends CabComponentProps<BoxProps> {
  sx?: BoxProps['sx'];
  scale?: number;
  color?: CircularProgressProps['color'];
  className?: BoxProps['className'];
}

export const CabSpinner = ({ className, sx, scale = 3, color }: ICabSpinner): ReactElement => (
  <StyledBox sx={sx} className={className}>
    <CircularProgress 
      color={color || "primary"}
      size={scale * 20}
    />
  </StyledBox>
);

const StyledBox = styled(Box, {label: "StyledBox"})(() => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
}));

export default CabSpinner;
