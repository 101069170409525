import { ReactElement } from 'react';
import { ScopedCssBaseline } from '@mui/material';
import { RouterProvider} from 'react-router-dom';

import AppInit from './utils/appInit';

import './theme.css';
import ErrorBoundary from './components/Errors/ErrorBoundary';
import { AppState } from './appState';
import store from './store';
import { IntercomProvider } from "react-use-intercom";
import { theme } from './theme';
import { router } from './router';


export const App = (): ReactElement => {
  return <AppState store={store} theme={theme}>
    <ScopedCssBaseline sx={{
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      position: "absolute",
    }}>
      <IntercomProvider
        appId={import.meta.env.VITE_INTERCOM_ID as string}
      >
        <ErrorBoundary>
          <>
            <RouterProvider router={router} />
            <AppInit />
          </>
        </ErrorBoundary>
      </IntercomProvider>
    </ScopedCssBaseline>
  </AppState>;
};
