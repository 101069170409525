import { ActionType } from '../actionTypes';
import { PersonalNoteList, PersonalNote } from './types';
import { RootAction } from '..';

export const initialState: PersonalNoteList = {
  loaded: false,
  notes: [],
};

export default function personalNotes(state = initialState, action: RootAction): typeof initialState  {

  switch (action.type) {
    case ActionType.FETCHED_PERSONAL_NOTES:
      return {loaded: true, notes: action.personalNotes};

    case ActionType.LOGOUT_SUCCESSFUL:
      return initialState;

    case ActionType.CREATED_PERSONAL_NOTE:
      return {...state, notes: [action.personalNote, ...state.notes]};

    case ActionType.UPDATED_PERSONAL_NOTE: {
      const notesAfterUpdate = state.notes.map(
        (note): PersonalNote => note.id === action.personalNote.id ? action.personalNote : note);
      return {...state, notes: notesAfterUpdate};
    }
    case ActionType.DELETED_PERSONAL_NOTE: {
      const notDeletedNotes = state.notes.filter((note): boolean => note.id !== action.personalNoteId);
      return {...state, notes: notDeletedNotes};
    }
    default:
      return state;
  }
}