import { ReactElement, ReactNode, useEffect, useState } from "react";
import { Box, Drawer, DrawerProps, Typography } from '@mui/material';
import { CabComponentProps } from "../cabStyled";
import { colors } from "../../../colors";
import { styled } from "@mui/material";
import { CabIcon } from "../CabIcon";
import { IoClose } from "react-icons/io5";


export interface CabPanelProps extends CabComponentProps<DrawerProps> {
  children?: DrawerProps['children'];
  onClose?: () => void;
  open?: DrawerProps['open'];
  anchor?: DrawerProps['anchor'];
  title?: string | ReactNode; 
  closeIcon: boolean;
  width?: number;
}

export const CAB_PANEL_WIDTH = 325;

export const CabPanel = ({
  title, children, open: panelOpen, onClose, anchor, closeIcon, overrides, width, sx
}: CabPanelProps): ReactElement => {
  const [open, setOpen] = useState(panelOpen);

  useEffect(() => {
    setOpen(panelOpen);
  }, [panelOpen]); 

  const props: DrawerProps & { width?: number } = {
    open,
    anchor,
    onClose,
    sx,
    width: width || CAB_PANEL_WIDTH,
  };

  return (
    <DrawerStyled {...props} {...overrides} hideBackdrop variant="persistent">
      <BoxStyled>
        <TitleBox>
          <Typography variant='h2'>
            {title}
          </Typography>
          {closeIcon && onClose ? (
            <CabIcon Icon={IoClose} onClick={() => onClose()} 
              alt='Close' size="small" sx={{marginRight: '3px'}} />
          ) : null}
        </TitleBox>
        <ChildrenBox>
          {children}
        </ChildrenBox>
      </BoxStyled>
    </DrawerStyled>
  );
};

const DrawerStyled = styled(
  Drawer, {label: "DrawerStyled", shouldForwardProp: p => p !== 'width'}
)<CabComponentProps<DrawerProps> & { width?: number }>(({ theme, width }) => ({
  '& .MuiDrawer-paper': {
    ...(theme.palette.mode === 'dark' ? {
      backgroundColor: colors.navyDark,
      backgroundImage: 'none'
    } : {
    }),
    width: width,
  },
  flexShrink: 0,
  width: width,
}));

const BoxStyled = styled(Box, {label: "BoxStyled"})(() => ({
  height: '100%'
}));

const ChildrenBox = styled(Box, {label: "ChildrenBox"})(() => ({
  height: 'calc(100% - 48px)',
  paddingBottom: 0
}));

const TitleBox = styled(Box, {label: "TitleBox "})(({ theme }) => ({
  position: 'sticky',
  height: '48px',
  top: 0,
  zIndex: 100,
  padding: 10,
  paddingBottom: 6,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.mode === 'dark'
    ? colors.navyDark
    : 'none',
}));
