import { API_URLS }  from '../../apiUrls';
import { ActionType } from '../actionTypes';
import { ThunkResult, ThunkDispatchType, FetchReturn } from '../types';
import { makeHeaders, makeParams, fetchData } from '../../utils/apiUtils';
import { RootState } from '..';
import { AirlineNote } from './types';

export const fetchAirlineNotes = (leader: number): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    const params = makeParams({leader});

    return fetchData(API_URLS.AIRLINE_NOTES + params, { headers, }).then((res: FetchReturn): void  => {
      if (res.status === 200) {
        dispatch({ type: ActionType.FETCHED_AIRLINE_NOTES, airlineNotes: res.data });
      }
    });
  };

export const createAirlineNote = (note: AirlineNote): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    // Copy all AirlineNote data except remove id and only take the ID of the airline
    const airlineId = note.airline_data.id;
    const { id, airline_data, ...newNote } = note;
  
    // If an airline was selected, add the ID
    // If an airline was selected, add the airline data
    let body = '';
    if (airlineId > 0) {
      body = JSON.stringify({...newNote, airline: airlineId});
    } else {
      body = JSON.stringify({...newNote, airline_data});
    }
    
    return fetchData(API_URLS.AIRLINE_NOTES, { headers, method: 'POST', body}).then((res: FetchReturn): void  => {
      if (res.status === 201) {
        dispatch({ type: ActionType.CREATED_AIRLINE_NOTE, airlineNote: res.data });
      }
    });
  };

export const updateAirlineNote = (note: AirlineNote): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify(note);
    return fetchData(API_URLS.AIRLINE_NOTES + note.id + '/',
      { headers, method: 'PATCH', body }).then((res: FetchReturn): void => {
      if (res.status === 200) {
        dispatch({ type: ActionType.UPDATED_AIRLINE_NOTE, airlineNote: res.data});
      }
    });
  };

export const deleteAirlineNote = (noteId: number): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);

    return fetchData(API_URLS.AIRLINE_NOTES + noteId + '/', { headers, method: 'DELETE'})
      .then((res: FetchReturn): void => {
        dispatch({ type: ActionType.DELETED_AIRLINE_NOTE, airlineNoteId: noteId});
      });
  };