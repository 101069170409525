import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';
import { API_HOST } from '../../apiUrls';
import { makeHeaders } from '../../utils/apiUtils';

// initialize an empty api service that we'll inject endpoints into later as needed
export const cabinetApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/`,
    prepareHeaders: async (headers) => {
      const newHeaders = await makeHeaders(true);
      if (newHeaders) {
        // we don't want to explicitly set the Content-Type. It will default to JSON, but in cases
        // where form data is given it will automatically change to multipart/form-data
        delete newHeaders['Content-Type'];
        Object.entries(newHeaders).forEach(([name, val]) => {
          headers.append(name, val);
        });
      }

      return headers;
    },
    paramsSerializer: (params: Record<string, unknown>) => (
      queryString.stringify(params, { arrayFormat: 'none' })
    ),
  }),
  endpoints: () => ({}),
});
