import { CABINET_ICONS_URL } from "./resourceUrls";

export const ICON_COLORS = {
  medium: '#808489',
  primary: '#252d49',
  danger: '#f04141',
  light: '#808489',
  coral: '#eba083',
  green: '#4ca525',
  beige: '#ede1cc',
  black: '#231f20',
  lightBlue: '#4dace3',
  menuLightBlue: '#79a6d5',
  white: '#ffffff',
  orange: '#e7724f',
  frenchBlue: '#b5c9f7',
};

export const EMOJIS = {
  AIRLINES: 'airplane',
  HOTELS: 'bed',
  PERSONAL: 'family',
  DEFAULT_CATEGORY: 'page_facing_up',
  UNICORN: 'unicorn_face',
  PLUS: 'heavy_plus_sign'
};


export interface CabinetIcon {
  name: string;
  icon_url: string;
  is_default: boolean;
  showInPicker: boolean;
}

export type CabinetIconData = CabinetIcon & { id: keyof typeof CABINET_ICONS };

export const CABINET_ICONS = {
  BOARDS: {
    icon_url: `${CABINET_ICONS_URL}/boards.svg`,
    is_default: true,
    name: 'Boards',
    showInPicker: true,
  },
  BOOKMARK: {
    icon_url: `${CABINET_ICONS_URL}/bookmark.svg`,
    is_default: true,
    name: 'Bookmark',
    showInPicker: true,
  },
  BUSINESS_CONTACT: {
    icon_url: `${CABINET_ICONS_URL}/business-contact.svg`,
    is_default: true,
    name: 'Business Contacts',
    showInPicker: true,
  },
  CAKE: {
    icon_url: `${CABINET_ICONS_URL}/cake.svg`,
    is_default: true,
    name: 'Cake',
    showInPicker: true,
  },
  CALENDAR: {
    icon_url: `${CABINET_ICONS_URL}/calendar.svg`,
    is_default: true,
    name: 'Calendar',
    showInPicker: true,
  },
  CAR: {
    icon_url: `${CABINET_ICONS_URL}/car.svg`,
    is_default: true,
    name: 'Car',
    showInPicker: true,
  },
  CLOCK: {
    icon_url: `${CABINET_ICONS_URL}/clock.svg`,
    is_default: true,
    name: 'Clock',
    showInPicker: true,
  },
  COFFEE: {
    icon_url: `${CABINET_ICONS_URL}/coffee.svg`,
    is_default: true,
    name: 'Coffee',
    showInPicker: true,
  },
  COMMUNICATION: {
    icon_url: `${CABINET_ICONS_URL}/communication.svg`,
    is_default: true,
    name: 'Communication',
    showInPicker: true,
  },
  CONTACT_BOOK: {
    icon_url: `${CABINET_ICONS_URL}/contact-book.svg`,
    is_default: true,
    name: 'Contact Book',
    showInPicker: true,
  },
  CONTACT: {
    icon_url: `${CABINET_ICONS_URL}/contact.svg`,
    is_default: true,
    name: 'Contacts',
    showInPicker: true,
  },
  COPY: {
    icon_url: `${CABINET_ICONS_URL}/copy.svg`,
    is_default: true,
    name: 'Copy',
    showInPicker: true,
  },
  CREDIT_CARD: {
    icon_url: `${CABINET_ICONS_URL}/credit-card.svg`,
    is_default: true,
    name: 'Credit Card',
    showInPicker: true,
  },
  DO_NOT_ENTER: {
    icon_url: `${CABINET_ICONS_URL}/do-not-enter.svg`,
    is_default: true,
    name: 'Do Not Enter',
    showInPicker: true,
  },
  DRINKS: {
    icon_url: `${CABINET_ICONS_URL}/drinks.svg`,
    is_default: true,
    name: 'Drinks',
    showInPicker: true,
  },
  EDUCATION: {
    icon_url: `${CABINET_ICONS_URL}/education.svg`,
    is_default: true,
    name: 'Education',
    showInPicker: true,
  },
  FAMILY: {
    icon_url: `${CABINET_ICONS_URL}/family.svg`,
    is_default: true,
    name: 'Family',
    showInPicker: true,
  },
  FILTER: {
    icon_url: `${CABINET_ICONS_URL}/filter.svg`,
    is_default: true,
    name: 'Filter',
    showInPicker: true,
  },
  FOLDER: {
    icon_url: `${CABINET_ICONS_URL}/folder.svg`,
    is_default: true,
    name: 'Folder',
    showInPicker: true,
  },
  FOOD: {
    icon_url: `${CABINET_ICONS_URL}/food.svg`,
    is_default: true,
    name: 'Food',
    showInPicker: true,
  },
  GIFT: {
    icon_url: `${CABINET_ICONS_URL}/gift.svg`,
    is_default: true,
    name: 'Gift',
    showInPicker: true,
  },
  HEALTH: {
    icon_url: `${CABINET_ICONS_URL}/health.svg`,
    is_default: true,
    name: 'Health',
    showInPicker: true,
  },
  HEART: {
    icon_url: `${CABINET_ICONS_URL}/heart.svg`,
    is_default: true,
    name: 'Heart',
    showInPicker: true,
  },
  HOTEL: {
    icon_url: `${CABINET_ICONS_URL}/hotel.svg`,
    is_default: true,
    name: 'Hotel',
    showInPicker: true,
  },
  HOUSE: {
    icon_url: `${CABINET_ICONS_URL}/house.svg`,
    is_default: true,
    name: 'House',
    showInPicker: true,
  },
  KEYHOLE: {
    icon_url: `${CABINET_ICONS_URL}/keyhole.svg`,
    is_default: true,
    name: 'Keyhole',
    showInPicker: true,
  },
  LOCATION_PIN: {
    icon_url: `${CABINET_ICONS_URL}/location-pin.svg`,
    is_default: true,
    name: 'Location',
    showInPicker: true,
  },
  NOTIFICATION: {
    icon_url: `${CABINET_ICONS_URL}/notification.svg`,
    is_default: true,
    name: 'Notifications',
    showInPicker: true,
  },
  PAPER_PLANE: {
    icon_url: `${CABINET_ICONS_URL}/paper-plane.svg`,
    is_default: true,
    name: 'Paper Plane',
    showInPicker: true,
  },
  PASSPORT: {
    icon_url: `${CABINET_ICONS_URL}/passport.svg`,
    is_default: true,
    name: 'Passport',
    showInPicker: true,
  },
  PLANE: {
    icon_url: `${CABINET_ICONS_URL}/plane.svg`,
    is_default: true,
    name: 'Plane',
    showInPicker: true,
  },
  RAMEN: {
    icon_url: `${CABINET_ICONS_URL}/ramen.svg`,
    is_default: true,
    name: 'Ramen',
    showInPicker: true,
  },
  RECURRING: {
    icon_url: `${CABINET_ICONS_URL}/recurring.svg`,
    is_default: true,
    name: 'Recurring',
    showInPicker: true,
  },
  SOCIAL_MEDIA: {
    icon_url: `${CABINET_ICONS_URL}/social-media.svg`,
    is_default: true,
    name: 'Social Media',
    showInPicker: true,
  },
  SUN: {
    icon_url: `${CABINET_ICONS_URL}/sun.svg`,
    is_default: true,
    name: 'Sun',
    showInPicker: true,
  },
  TECH: {
    icon_url: `${CABINET_ICONS_URL}/tech.svg`,
    is_default: true,
    name: 'Tech',
    showInPicker: true,
  }
};

export const CABINET_ICONS_LIST = Object.keys(CABINET_ICONS)
  .map((icon_id) => ({ id: icon_id, ...CABINET_ICONS[icon_id as keyof typeof CABINET_ICONS] }));