import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import storeObject from "./store";
import { ReactElement, ReactNode, useState } from "react";
import { Provider } from "react-redux";
import themeObject from "./theme";
import CabLuxonAdapter from "./utils/cabLuxonAdapter";
import { CabinetContextProvider } from "./CabinetContext";
import { USER_ROLE } from "./constants";
import { useMountEffect } from "./utils/hooks";



export const AppState = (
  { children, store, theme, forceUserRole }: { 
    children: ReactElement[] | ReactElement | ReactNode;
    store: typeof storeObject;
    theme: typeof themeObject;
    forceUserRole?: USER_ROLE;
  }
): ReactElement => {
  const [userRole, setUserRole] = useState<USER_ROLE|undefined>();
  useMountEffect(() => {
    store.subscribe(() => {
      const stateUserRole = store.getState().auth.user?.active_license.user_role;
      if (stateUserRole !== userRole) {
        setUserRole(stateUserRole);
      }
    });
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={CabLuxonAdapter}>
          <Provider store={store}>
            <CabinetContextProvider userRole={forceUserRole || userRole}>
              {children}
            </CabinetContextProvider>
          </Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
