import { ReactElement, useState } from 'react';

import LeaderInfo, { NewLeader } from './LeaderInfo';
import { Calendar, Leader, OrgUserAutocompleteOption } from '../../../store';


interface ComponentProps {
  open: boolean;
  onClose: () => void;
  onCreateLeader: (
    leader: Leader, leaderPic: File | null, idx?: number, calendars?: number[]
  ) => Promise<void>;
  calendars?: Calendar[];
  shareWithUserOptions?: OrgUserAutocompleteOption[];
}

type Props = ComponentProps;

export const AddLeader = ({open, onClose, onCreateLeader, calendars, shareWithUserOptions}: Props): ReactElement => {

  const [buttonDisabled, setButtonDisabled] = useState(false);
  

  const handleSaveLeader = (data: NewLeader): void => {
    setButtonDisabled(true);
    // Only provide the image arguments if there is an image
    const img = data.img || null;
    const leaderCalendars = data.calendars;
    delete data.img;
    delete data.imgName;
    onCreateLeader(data, img, undefined, leaderCalendars)
      .then((): void => {
        setButtonDisabled(false);
        onClose();
      });
  };

  return (
    <LeaderInfo
      isOpen={open}
      handleSaveLeader={handleSaveLeader} 
      cancelAddLeader={onClose}
      firstLeader={false}
      nextDisabled={buttonDisabled}
      calendars={calendars}
      shareWithUserOptions={shareWithUserOptions}/>
  );
};

export default AddLeader;