import { ActionType } from '../actionTypes';
import { CabUIState } from './types';
import { MeetingType, RootAction } from '..';
import { USER_ROLE } from '../../constants';


export const initialState: CabUIState = {
  navbarExpanded: true,
  fetchError: {
    status_code: 0,
    message: "",
  },
  meetingLogPage: {
    dataFilter: {
      items: [],
    },
    columnSort: [],
    columnHide: {},
  },
  pollResultsPage: {
    dataFilter: {
      items: [],
    },
    columnSort: [],
    columnHide: {},
  },
  reusablePage: {
    dataFilter: {
      items: [],
    },
    columnSort: [],
    columnHide: {},
  },
};

export default function staticData(state = initialState, action: RootAction): typeof initialState  {
  switch (action.type) {
    case ActionType.SET_CAB_NAVBAR_EXPANDED:
      return {...state, navbarExpanded: action.navbarExpanded};

    case ActionType.SET_FETCH_ERROR:
      return {...state, fetchError: action.error};

    case ActionType.SET_MEETING_LOG_FILTER: {
      const logPage = action.meetingType === MeetingType.REUSABLE ? 'reusablePage'
        : action.meetingType === MeetingType.POLL ? 'pollResultsPage'
          : 'meetingLogPage';
      return {
        ...state,
        [logPage]: {
          ...state[logPage],
          dataFilter: action.filter,
        },
      };
    }

    case ActionType.SET_MEETING_LOG_COLUMN_SORT: {
      const logPage = action.meetingType === MeetingType.REUSABLE ? 'reusablePage'
        : action.meetingType === MeetingType.POLL ? 'pollResultsPage'
          : 'meetingLogPage';
      return {
        ...state,
        [logPage]: {
          ...state[logPage],
          columnSort: action.sort,
        },
      };
    }

    case ActionType.SET_MEETING_LOG_COLUMN_HIDE: {
      const logPage = action.meetingType === MeetingType.REUSABLE ? 'reusablePage'
        : action.meetingType === MeetingType.POLL ? 'pollResultsPage'
          : 'meetingLogPage';

      return {
        ...state,
        [logPage]: {
          ...state[logPage],
          columnHide: action.hide,
        },
      };
    }

    case ActionType.USER_LOADED: {
      const individualUser = action.user?.active_license.user_role === USER_ROLE.INDIVIDUAL 
        ? action.user.profile.user_leader 
        : undefined;

      const initFilterItems = individualUser ? [{field: 'leaders', operator: 'eq', value: [individualUser]}] : [];

      return {
        ...state,
        meetingLogPage: {
          ...state.meetingLogPage,
          dataFilter: {
            ...state.meetingLogPage.dataFilter,
            items: initFilterItems
          },
          columnSort: [{ field: "date_scheduled", sort: "desc" }],
          columnHide: {
            date_scheduled: true, template_parent: true, template_children: true, participants: true, locations: true
          }
        },
        reusablePage: {
          ...state.reusablePage,
          dataFilter: {
            ...state.reusablePage.dataFilter,
            items: initFilterItems
          },
          columnSort: [{ field: "date_scheduled", sort: "desc" }],
          columnHide: {
            date_scheduled: true, template_parent: true, template_children: true, participants: true, locations: true
          }
        },
        pollResultsPage: {
          ...state.pollResultsPage,
          dataFilter: {
            ...state.pollResultsPage.dataFilter,
            items: initFilterItems
          },
          columnSort: [{ field: "date_scheduled", sort: "desc" }],
          columnHide: {
            date_scheduled: true, template_parent: true, template_children: true, participants: true, locations: true
          }
        }
      };
    }

    default:
      return state;
  }
}
