import { ActionType } from '../actionTypes';
import { Event as RBCEventBase} from 'react-big-calendar';
import { DateTime } from 'luxon';
import { OAuthToken } from '../auth/types';
import { ZOOM_CONNECT_STRATEGY } from '../../constants';
import { Leader } from '../leaders/types';


export type RecurringTimeRRule = {
  "freq": "WEEKLY",
  "byweekday": number,
  "time": string
};


export type RecurringTimeRule = {
  rrule: RecurringTimeRRule
  duration: number
};


export type RecurringTimesDay = {
  rules: RecurringTimeRule[]
  enabled: boolean
};


export type  RecurringTimesDays = {
  mon: RecurringTimesDay
  tue: RecurringTimesDay
  wed: RecurringTimesDay
  thu: RecurringTimesDay
  fri: RecurringTimesDay
  sat: RecurringTimesDay
  sun: RecurringTimesDay
};


export enum RecurringTimeOptionLimit {
  NUM_DAYS = "num_days",
  DATE_RANGE = "date_range"
}


export type RecurringTimesLimit = {
  selected: RecurringTimeOptionLimit | null
  num_days: number
  date_range: {
    start: string
    end: string
  }
};


export type RecurringTimesOptions = {
  limit: RecurringTimesLimit
};


export type RecurringTimes = {
  days: RecurringTimesDays
  options: RecurringTimesOptions
};

export type AvailabilitySlotTimes = {
  startTime: string, endTime: string
};

export type AvailabilitySlot = {
  times: AvailabilitySlotTimes[],
  enabled: boolean
};

export type ExcludedSlot = {
  dates: [DateTime | null, DateTime | null];
  times: { startTime: string, endTime: string},
};

export type RecurringTimeFormData = {
  mon: AvailabilitySlot;
  tue: AvailabilitySlot;
  wed: AvailabilitySlot;
  thu: AvailabilitySlot;
  fri: AvailabilitySlot;
  sat: AvailabilitySlot;
  sun: AvailabilitySlot;
};

export type CopyToDays = {
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
};

export type ExcludedTimesFormData = {
  excludedSlots: ExcludedSlot[];
};

export type ExcludedMeetingSlots = {
  excludedSlots: MeetingSlot[];
};

export type ExcludedSingleDateFormData = {
  slotId: number;
  date: string;
  startTime: string;
  endTime: string;
};

export type AbbreviatedDays = keyof RecurringTimeFormData;


export interface EventDate {
  dateTime: string; 
  timeZone?: string;
}

export interface MeetingHoldEventError {
  action: string | null;
  calendarId: string;
  meetingSlotCalendarEventId: number;
  leaderName: string;
  error_code: string;
  meetingName: string;
  resolved: boolean;
  error_timestamp: string;
  meetingSlotId: number | null;
  meetingSlotStart: string | null;
  meetingSlotEnd: string | null;
}

export interface MeetingHoldEventErrorResponseObject {
  id: number,
  meeting_title: string,
  event_errors: {
    error: string,
    action: string | null,
    error_code: string,
    error_timestamp: string,
    func: string
  },
  meeting_slot: null | {
    id: number,
    meeting: number,
    start_date: string,
    end_date: string
  },
  calendar_association: {
    id: number,
    calendar_id: string
    leaders: number[]
    provider: number
  }
}


export interface MeetingHoldEventErrorResponse {
  errors: {
    [key: string]: MeetingHoldEventErrorResponseObject[]
  }
}

export type AttendeeResponseStatus = "needsAction" | "declined" | "tentative" | "accepted";

export interface EventAttendee {
  displayName?: string,
  email: string,
  responseStatus: AttendeeResponseStatus,
  self?: boolean
}

export interface APICalendarEvent {
  unique_id: string, // this is unique to the specific event (does not consider calendar)
  id: string,
  provider: number,
  title: string;
  start: EventDate;
  end: EventDate; 
  attendees?: EventAttendee[];
  colorId: string;
  calendarId: string;
  backgroundColor: string;
  foregroundColor?: string;
  busy: boolean;
  allDay: boolean;
  iCalUId: string;
  status: "confirmed" | "tentative" | "cancelled";
  selfResponse: AttendeeResponseStatus;
  buffer_start_minutes?: number;
  buffer_end_minutes?: number;
  recurring_root_event_idr?: string;
}

export interface UICalendarEvent {
  unique_cal_event_id: string, // this is unique to this specific event AND calendar
  unique_id: string, // this is unique to the specific event (does not consider calendar)
  id: string,
  title?: string,
  start: string,
  end: string,
  allDay: boolean,
  editable?: boolean,
  attendees?: EventAttendee[],
  busy?: boolean,
  textColor?: string,
  calendarId: string,
  backgroundColor?: string,
  borderColor?: string,
  className?: string,
  iCalUId: string;
  selfResponse?: AttendeeResponseStatus;
  isBackground: boolean;
  resourceId?: string;
  bufferStartMinutes?: number;
  bufferEndMinutes?: number;
  recurringRootEventIdr?: string;
}

export type LeaderBorders = Array<{color: string, status?: AttendeeResponseStatus}>;

export interface UICalendarEventConsolidated extends Omit<UICalendarEvent, 'unique_cal_event_id'> {
  leaderBackgroundColors: string[];
  leaderBorders: LeaderBorders;
  leaderInfo?: Leader[];
  calendarInfo?: Calendar[];
  additionalCalendarIds?: string[];
}

export interface BaseMeetingSlot {
  id: number;
  start_date: string;
  end_date: string;
  backgroundColor?: string;
  borderColor?: string;
  title?: string;
  textColor?: string;
  className?: string;
}

export type MeetingSlot = BaseMeetingSlot & {
  meeting: number;
  is_exclude: boolean;
  editable?: boolean;
  allDay?: boolean;
  selfResponse?: AttendeeResponseStatus;
  isRecurring?: boolean;
  containedSlots?: MeetingSlot[];
};

export type CombinedMeetingSlot = BaseMeetingSlot & {
  // This field is used if we are showing meeting slots in compressed form
  containedSlots: MeetingSlot[];
};

export interface MeetingQuestion {
  id: number;
  meeting: number;
  title: string;
  per_timeslot: boolean;
  required: boolean;
  question_type: QuestionType;
  order: number;
  answers?: MeetingQuestionAnswer[];
  options?: MeetingQuestionOption[];
}

export enum QuestionType {
  TEXT = 1,
  SINGLE_SELECT = 2,
  MULTI_SELECT = 3,
}

export interface MeetingQuestionOption {
  id: number;
  question: number;
  name: string;
}

export interface MeetingQuestionAnswer {
  id: number;
  question: number;
  poll_selection?: PublicPollSelection;
  options?: number[];
  text?: string;
  participant: number;
}

export enum MeetingPollPriority {
  AVAILABLE = 1,
  POTENTIAL = 2,
}

export enum MeetingPriority {
  LOW = 9, 'Low',
  MEDIUM = 6, 'Medium',
  HIGH = 3, 'High'
}

export type MeetingQuestionAnswerSubmission = Omit<MeetingQuestionAnswer, "participant"> & {
  participant?: number
};

export interface PublicPollSelection {
  id: number;
  meeting: number;
  participant: string;
  start_date: string;
  end_date: string;
  create_date: string;
  priority: MeetingPollPriority;
}

export interface NoSelectionCreate {
  participant: number;
}

export type PollSelectionCreate = NoSelectionCreate & {
  start_date: string;
  end_date: string;
  priority: MeetingPollPriority;
};

export type PollSelectionUpdate = NoSelectionCreate & {
  id: number;
  start_date: string;
  end_date: string;
  priority: MeetingPollPriority;
};

export interface PollSelectionDelete {
  id: number;
}

interface RemovePublicParticipant extends PublicExternalParticipant {
  invalidVotes?: PublicPollSelection[]
}

interface RemovePrivateParticipant extends PrivateExternalParticipant {
  invalidVotes?: PublicPollSelection[]
}

export type InvalidParticipantVotes = { [id: string]: RemovePublicParticipant | RemovePrivateParticipant };

interface DelegatorUser {
  leader: number | null;
  email: string | null;
  fetch_failures: number;
}
export interface CalendarAssociation {
  id: number;
  calendar_id: string;
  calendar_access_id: number | null;
  calendar_name?: string;
  provider: number;
  leaders: number[];
  free_busy: boolean;
  readable: boolean;
  can_edit: boolean;
  additional_calendar_email?: { email: string; name: string; };
  delegator_user: DelegatorUser | null;
  conference_solutions: string[]
  owner_email: string | null;
  is_owner_primary?: boolean;
  auto_background_color: string
  override_background_color: string | null
  auto_foreground_color: string
  override_foreground_color: string | null
  summary?: string;
  
}

export interface Calendar {
  id: number;
  calendar_id: string
  calendar_access_id: number | null;
  autoBackgroundColor: string;
  autoForegroundColor: string;
  overrideBackgroundColor: string | null;
  overrideForegroundColor: string | null;
  foregroundColor?: string; 
  backgroundColor?: string; 
  summary: string; 
  summaryOverride?: string;
  provider: number;
  canEdit: boolean;
  conferenceSolutions: string[];
  readable: boolean;
  owner_email: string | null;
  leaders: number[];
  freeBusy: boolean;
  delegatorUser: DelegatorUser | null;
  additionalCalendarEmail?: { email: string; name: string; };
  delegated?: boolean;
  is_owner_primary?: boolean;
}

// Fix in V1_14
// These types do not align properly due to api issues that make remoteCalendars and calendars identical

export type RemoteCalendar = Pick<Calendar, 'id'|'calendar_id'|'summary'|'summaryOverride'|'foregroundColor'
|'backgroundColor'|'canEdit'|'conferenceSolutions'|'readable'|'provider'|'owner_email'> & {
  calendar_access_id: number;
  canViewPrivateItems: boolean;
  is_owner_primary: boolean;
  freeBusy?: boolean; 
};

export enum EventHoldTitle {
  INTERNAL_LABEL = "INTERNAL_LABEL",
  EVENT_TITLE = "EVENT_TITLE",
}

export enum EventHoldDescription {
  INTERNAL_NOTES = "INTERNAL_NOTES",
  EVENT_DESCRIPTION = "EVENT_DESCRIPTION",
}

export interface SchedulingPreferences {
  id: number;
  user: number | null;
  leader: number | null;
  booking_check_conflicts: boolean;
  default_duration_minutes: number;
  default_conference_provider: number | null;
  silence_executive_hold_notice: boolean;
  disable_poll_response_confirmation: boolean;
  meeting_holds_busy: boolean;
  multi_timezone: boolean;
  default_calendar_timezone: string | null;
  default_secondary_timezone: (string | null)[] | null;
  hide_promo_text: boolean;
  allow_reschedule_cancel: boolean;
  conferenceSolutions: string[];
  default_start_delay_minutes: number;
  default_slot_time_interval: number | null;
  default_condense_slots: boolean;
  default_allow_add_participants: boolean;
  default_location_presets: number[];
  event_title_for_holds: EventHoldTitle;
  default_buffer_start_minutes: number;
  default_buffer_end_minutes: number;
  meeting_organizer_override: string | null;
  send_participant_reminders: boolean;
  default_participant_reminder_minutes: number;
  recently_used_timezones: {
    [tzName: string]: {
      frequency: number;
      last_used: string;
    }
  }
  event_description_for_holds: EventHoldDescription;
}

export interface SchedulingPreferencesResponse {
  user_prefs?: SchedulingPreferences;
  leader_prefs: {
    [id: string]: SchedulingPreferences;
  }
}

export type SchedulingPreferenceUpdate = Partial<SchedulingPreferences> & { id: SchedulingPreferences['id'] };

export interface FetchMeetingsResponse {
  meta?: { 
    count: number;
  };
  data: {
    all_filtered_ids: number[];
    removed: {
      [meetingId: number]: Meeting
    };
    meetings: {
      [meetingId: number]: Meeting
    };
  };
}

export interface ConferenceSettings {
  id: number;
  conference_provider: number;
  oauth_token?: {
    id: number;
    username: string;
    provider: string;
  };
  leader: number | null;
}

export interface ConferenceContact {
  account_user_id: string;
  username: string;
  first_name?: string;
  last_name?: string;
}

export interface ZoomSettings extends ConferenceSettings {
  alternate_hosts: ConferenceContact[];
  schedule_obo?: ConferenceContact | null;
  personal_meeting_link?: string;
  method: ZOOM_CONNECT_STRATEGY;
}

export type ZoomSettingsUpdate = Partial<Omit<ZoomSettings, 'oauth_token'>>
& { id: ZoomSettings['id']; oauth_token_id?: number };

export type ZoomSettingsCreate = Omit<ZoomSettings, 'id'>;

export type MeetingLeader = Partial<Pick<Leader, 'first_name' | 'last_name' | 'pic_url' | 'color'>> & {
  id: number;
  meeting: Meeting['id'];
  leader: Leader['id'];
  required?: boolean;
  prevent_conflicts?: boolean;
  view_calendar?: boolean;
  should_invite?: boolean;
  is_fetchable?: boolean;
};

export type MeetingLeaderUpdate = Partial<Omit<MeetingLeader, 'first_name' | 'last_name' | 'pic_url' | 'color'>>;

export type MeetingLeaderInfo = Leader & Omit<MeetingLeader, 'id' | 'meeting' | 'leader'>
& { meeting_leader_id: number };

export type EventCalendarInfo = Pick<Calendar, 'id' | 'backgroundColor' | 'summary'>;

export type MeetingCalendarInfo
= Pick<CalendarAssociation, 'id' | 'calendar_id' | 'calendar_name' | 'leaders' | 'provider' | 'summary'>;

export interface CreateUser {
  id: number;
  email: string;
  first_name: string; 
  last_name: string;
  active_license?: {
    organization: {
      name: string | null;
      logo: string | null;
    }
  }
}

interface NormalizedPollSelections {[selectionId: string]: PublicPollSelection}

export type MeetingFile = {
  id: number;
  file: string;
  filename: string;
  created: string;
  updated: string;
};

export interface Meeting {
  id: number;
  is_poll?: boolean;
  leaders: number[];
  leader_info?: MeetingLeaderInfo[];
  calendar_info?: MeetingCalendarInfo;
  hold_calendar_info?: MeetingCalendarInfo[];
  title: string;
  title_booked: string | null;
  internal_label?: string | null;
  description?: string;
  description_booked?: string | null;
  date_scheduled: string | null;
  create_date: string;
  created_at: string;
  updated_at: string | null;
  event_start_time: string | null;
  selected_scheduled_time: string | null;
  event_id: string | null;
  calendar_tz: string | null;
  secondary_tz: (string | null)[] | null;
  copy_tz: string | null;
  secondary_copy_tz: string[] | null;
  status: MeetingStatus;
  status_data?: {
    id: number,
    name: string,
  }
  recurring_times: RecurringTimes | null;
  //Booking info
  booking_calendar?: number;
  duration_minutes?: number;
  num_expected_participants?: number;
  conference_provider?: number | null;
  conference_leader?: number | null;
  locations?: string[] | null;
  locations_booked?: string[] | null;
  use_link?: boolean;
  external_id?: string;
  prevent_conflict: boolean;
  poll_selections?: NormalizedPollSelections;
  participants?: PrivateNormalizedExternalParticipants;
  executive_hold: boolean;
  hold_calendars: number[];
  errors?: MeetingHoldEventError[];
  error_count: number;
  show_voter_names: boolean;
  allow_add_participants: boolean;
  create_user?: CreateUser;
  questions: {[id: number]: MeetingQuestion};
  rooms: number[];
  location_presets: number[];
  auto_merge_slots: boolean;
  is_reusable: boolean;
  enable_public_attendee_list: boolean;
  allow_reschedule_cancel: boolean;
  priority: MeetingPriority;
  notes?: string | null;
  template_parent?: number | null;
  use_template_parent?: boolean;
  template_children?: number[];
  start_delay_minutes: number;
  files: MeetingFile[];
  slot_time_interval?: number | null;
  buffer_start_minutes: number;
  buffer_end_minutes: number;
  earliest_slot_date?: string;
  send_participant_reminders: boolean;
  participant_reminder_minutes: number;
}

export type NewMeeting = Partial<Meeting> & { id: -1 };

export interface Meetings {
  [meetingId: string]: Meeting;
}

export type MeetingUpdate = Partial<Omit<Meeting, 'id'>> & {
  id: Meeting['id'];
};
export type MeetingCreate = Omit<Meeting, 'id'|'send_participant_reminders'|'participant_reminder_minutes'
|'created_at'|'updated_at'> & {
  send_participant_reminders?: Meeting['send_participant_reminders'];
  participant_reminder_minutes?: Meeting['participant_reminder_minutes'];
};

export type NewMeetingUpdate = Omit<MeetingUpdate, 'id'> & { leader_info?: Meeting['leader_info'] };

//Simple slot type used in meeting booking page for time selection. 

export type BookingSlotReason = "PAST" | "NOTICE" | "EVENT" | "BUFFER" | "TAKEN" | "EXCLUSION";
export interface BookingSlot {
  id: number;
  meeting: number;
  start: string;
  end: string;
  detail?: {
    reason?: BookingSlotReason;
    message?: string;
    extra?: {
      calendar_pk?: string;
      event_id?: string;
      event_icaluid?: string;
      event_title?: string;
    }
  }
}

//Leader object used in Meeting booking. 
export interface PublicLeader {
  id: number;
  first_name: string;
  last_name: string;
  pic_url: string;
  color: string;
}

export interface ExternalMeetingInfo {
  id: number;
  external_id: string;
  title: string;
  title_booked: string | null;
  description?: string;
  description_booked?: string | null;
  use_link: boolean;
  duration_minutes: number;
  num_expected_participants?: number;
  date_scheduled: string | null;
  event_start_time: string | null;
  event_id: string | null;
  allow_add_participants: boolean;
  leaders: PublicLeader[];
  status: {
    id: MeetingStatus;
    name: string;
  }
  is_poll: boolean;
  allow_reschedule_cancel: boolean;
  booking_slots: BookingSlot[];
  conflict_slots: BookingSlot[];
  poll_selections: NormalizedPollSelections;
  participants: NormalizedExternalParticipants;
  create_user: CreateUser
  questions?: MeetingQuestion[];
  is_reusable: boolean;
  enable_public_attendee_list: boolean;
  earliest_slot_date?: string;
  meeting_organizer_override?: string | null;
}

export interface CalendarError {
  name: string, provider: number
}
export interface ScheduleState {
  calendars: Calendar[];
  calendarErrors: CalendarError[];
  meetings: Meetings;
  newMeeting: NewMeeting | null;
  meetingSlots: MeetingSlot[];
  pollSelections: NormalizedPollSelections;
  externalParticipants: NormalizedExternalParticipants;
  events: Record<UICalendarEvent['id'], UICalendarEvent>;
  participantAutoCompletOptions: ParticipantAutocompleteOption[];
  schedulingPrefs: SchedulingPreferencesResponse;
  zoomSettings: {
    [settingsId: string]: ZoomSettings;
  };
  associationsLoaded: boolean;
  meetingsLoaded: boolean;
  calendarsLoaded: boolean;
  slotsLoaded: boolean;
  scheduledMeetingsLoaded: boolean;
  scheduledSlotsLoaded: boolean;
  publicMeetings: {
    [externalId: string]: ExternalMeetingInfo;
  };
  scheduleErrors: {[key: string]: MeetingHoldEventError[]}
  questionAnswers: {[id: string]: MeetingQuestionAnswer}
  meetingRooms: {[id: string]: MeetingRoom}
  meetingAuditLogsLoaded: boolean;
  meetingAuditLogs: {[id: string]: MeetingAuditLog}
  recentlyScheduledLoaded: boolean;
  recentlyScheduled: Meetings;
  presetLocations: PresetLocations;
}

export interface CalendarsDict {
  [calendarId: string]: { 
    backgroundColor?: string; 
    events: UICalendarEvent[] 
  }
}

export interface BackgroundsDict {
  [calendarId: string]: string;
}

export interface UpdateMeetingResponse {
  meeting: Meeting,
  savedTimeSlots?: MeetingSlot[],
  savedQuestions?: MeetingQuestion[]
}
export interface EventsByDateDict { 
  [date: string]: Array<{startDate: string, endDate: string, meeting: number | null, id: number}>
}

export interface CurrentTimezone {
  timezone: string;
  timezoneLong: string;
}

export interface DisplayCalendarsDict {
  [calendarId: string]: {
    id: number;
    calendarId: string;
    color?: string;
    display: boolean;
    name: string;
    provider: number;
    canEdit: boolean;
    leaders: number[];
    conferenceSolutions: string[];
    readable: boolean,
    additionalCalendarEmail?: { email: string; name: string; };
    freeBusy: boolean,
    calendarAccessId?: number;
  }
}

export interface DateRange {
  start: string, 
  end: string
}

export interface IEventCache {
  [calendarId: string]: {
    [startDate: string]: {
      [endDate: string]: {
        events: APICalendarEvent[];
        cacheTimestamp: DateTime;
      }
    }
  }
}

export interface EventRequestInfo { 
  [providerId: number]: Array<string>
}

export interface PublicExternalParticipant {
  id: number;
  meeting: number;
  name: string;
  email: null | string
  email_hash: string;
  first_response_date: string | null;
  preregistered?: boolean;
  required: boolean;
  no_times_comment: string | null
  calendar_access: number | null
}

export type PrivateExternalParticipant = PublicExternalParticipant & {
  calendar_access?: number | null
  email: string | null;
  first_response_date: string;
  required: boolean;
  prevent_conflicts: boolean;
  view_calendar: boolean;
  should_invite: boolean;
  is_fetchable: boolean;
};

export type PrivateExternalParticipantCreate =
  Omit<PrivateExternalParticipant, "id" | "email_hash" | "email" | "first_response_date"
  | "prevent_conflicts" | "view_calendar" | "is_fetchable" | "calendar" | "no_times_comment"
  | "name"
  > & {
    email: string | null;
    name?: PrivateExternalParticipant['name'];
  };

export type PrivateExternalParticipantUpdate =
  Partial<Omit<PrivateExternalParticipant, "email_hash" | "first_response_date" | "meeting"
  | "no_times_comment" | "is_fetchable" | "calendar" | "preregistered" | "name"
  >> & {
    id: PrivateExternalParticipant['id'];
    name?: PrivateExternalParticipant['name'];
    email?: PrivateExternalParticipant['email'];
    no_times_comment?: PrivateExternalParticipant['no_times_comment'];
  };

export type NormalizedExternalParticipant = PublicExternalParticipant | PrivateExternalParticipant;

export interface NormalizedExternalParticipants {
  [participantId: string]: NormalizedExternalParticipant
}

export interface PrivateNormalizedExternalParticipants {
  [participantId: string]: PrivateExternalParticipant
}

export interface FormErrors {
  meetingName: string;
  selectedLeader: string;
}

export interface CalDateRange {
  start: Date;
  startStr: string;
  end: Date;
  endStr: string;
}

export type RBCEvent = RBCEventBase & {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  attendees?: EventAttendee[];
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  editable: boolean;
  leaderBackgroundColors: string[];
  leaderBorders: LeaderBorders;
  selfResponse: AttendeeResponseStatus;
  leaderInfo?: Leader[];
  calendarInfo?: Calendar[];
  busy?: boolean;
  bufferStartMinutes?: number;
  bufferEndMinutes?: number;
  resourceId?: string;
  recurringRootEventIdr?: string;
};

//Simple slot range for selection in the meeting booking page. 
export interface BookingSlotRange {start: Date, end: Date} 
//Dict to assist with grouping available booking slots by day in meeting booking page. 
export interface BookingSlotsByDay {[date: string]: Array<BookingSlot>}

export interface MeetingHoldEventErrorResolution {meetingId:number, meetingSlotCalendarEventId: number}

export interface ZoomSettingsResponse {
  id: number;
  conference_provider: number;
  leader: number;
  oauth_token: OAuthToken;
  alternate_hosts: ConferenceContact[];
  method: string;
  schedule_obo: ConferenceContact[] | null;
  personal_meeting_link: string | null;
}

export type MeetingRoom = {
  id: number;
  room_id: string;
  display_name: string;
  provider: number;
  capacity: number;
  email: string;
};

export type AuditMeeting = Pick<Meeting, 'id'|'title'|'title_booked'|'leaders'|'is_reusable'|'create_date'|'status'
|'is_poll'|'create_user' | 'questions' >;

export type MeetingAuditLog = {
  id: number;
  create_date: string;
  from_status: MeetingStatus;
  to_status: MeetingStatus;
  user: number | null;
  meeting: AuditMeeting;
  questions: {[id: number]: MeetingQuestion};
};

export type CreatePollSelectionsResponse = {
  poll_selections:PublicPollSelection[],
  participants: PublicExternalParticipant[]
};

export interface ParticipantAutocompleteOption {
  name: string;
  email: string;
  label: string;
}

export interface PresetLocation {
  id: number;
  name: string;
  location: string;
}

export interface PresetLocations {
  [id: number]: PresetLocation;
}

export enum MeetingType {
  ONE_OFF = "ONE_OFF",
  POLL = "POLL",
  REUSABLE = "REUSABLE"
}

type MeetingSortParams = (
  "title" | "create_date" | "status" | "leaders" | "is_reuseable" | 
  "create_user_full_name" | "participants" | "priority" |
  "-title" | "-create_date" | "-status" | "-leaders" | "-is_reuseable" | 
  "-create_user_full_name" | "-participants" | "-priority"
);

export enum MeetingStatus {
  PENDING = 1,
  SCHEDULED = 2,
  NO_RESPONSE = 3,
  POSTPONED = 4,
  CANCELLED = 5
}

export type MeetingFilter = {
  title__contains: string
  create_date__gte: string
  create_date__lte: string
  leaders: number[]
  is_reusable: boolean
  create_user__contains: number[]
  participants: number[]
  participants__gt: number
  participants__lt: number
  priority: number,
  priority__gt: number
  priority__lt: number
  title: string
  create_date: string
  status: MeetingStatus
  create_user: number
  topology: "or" | "and"
  is_poll: boolean
  template_parent_id: number
  order_by: MeetingSortParams
  internal_label: string,
  status__in: MeetingStatus[]
};

export type SelectedSlot = { id: number, start: DateTime, end: DateTime, priority?: MeetingPollPriority };
export type SelectedSlots = SelectedSlot[];

export type ParticipantWithSelections = { 
  id?: number;
  name: string;
  emailHash: string,
  email: string | null;
  selectedSlots: SelectedSlots;
  first_response_date: string | null;
  required?: boolean;
};

export type ParticipantsWithSelections = ParticipantWithSelections[];

export type ScheduleAction =  
  { type: ActionType.SAVED_MEETING_SLOTS; meetingSlots: MeetingSlot[]; } | 
  { type: ActionType.SAVING_MEETING_SLOTS; } | 
  { type: ActionType.EDITING_MEETING_SLOTS; } |
  {
    type: ActionType.SAVED_MEETING_QUESTIONS;
    meetingId: number;
    meetingQuestions: MeetingQuestion[];
    isSavedMeeting: boolean;
  } |
  { type: ActionType.FETCHED_CALENDAR_ASSOCIATIONS; calendars: Calendar[]; } |
  { type: ActionType.CREATED_CALENDAR; calendar: Calendar; } |
  { type: ActionType.UPDATED_CALENDAR; calendar: Calendar; } |
  { type: ActionType.FETCHED_AVAILABLE_CALENDARS; remoteCalendars: RemoteCalendar[]; errors: CalendarError[] } |
  { type: ActionType.FETCHING_AVAILABLE_CALENDARS; } |
  { type: ActionType.CREATED_AVAILABLE_CALENDAR; availableCalendar: Calendar; } |
  { type: ActionType.FETCHED_MEETINGS; 
    meetings: Meetings, scheduledMeetingsLoaded: boolean, fetchStartTime: string 
  } |
  { type: ActionType.FETCHED_MEETING; meeting: Meeting } |
  { type: ActionType.UPDATED_NEW_MEETING; meeting: Partial<Meeting> | null } |
  { type: ActionType.DELETED_NEW_MEETING } |
  { type: ActionType.UPDATED_MEETING; meeting: MeetingUpdate } |
  { type: ActionType.DELETED_MEETING; meeting: Meeting } |
  { type: ActionType.DELETED_MEETINGS; meetingIds: Meeting['id'][] } |
  { type: ActionType.FETCHED_MEETING_SLOTS; meetingSlots: MeetingSlot[], unscheduled: boolean } |
  { type: ActionType.FETCHED_SLOTS_FOR_MEETING; meetingSlots: MeetingSlot[] } | 
  { type: ActionType.FETCHED_MEETING_EXTERNAL; meeting: ExternalMeetingInfo } | 
  { type: ActionType.FETCHED_POLL_SELECTIONS; pollSelections: NormalizedPollSelections; 
    externalParticipants: NormalizedExternalParticipants } | 
  { type: ActionType.SET_POLL_SELECTIONS;
    externalId: ExternalMeetingInfo['external_id']; pollSelections: NormalizedPollSelections; 
    externalParticipants: NormalizedExternalParticipants } | 
  { type: ActionType.DELETED_POLL_SELECTIONS;
    externalId: ExternalMeetingInfo['external_id']; deletedSelectionIds: PublicPollSelection['id'][] } | 
  { type: ActionType.FETCHED_POLL_RESULTS; meetings: Meetings } |
  { type: ActionType.FETCHED_REMOTE_CALENDAR_EVENTS;
    events: Record<UICalendarEvent['unique_cal_event_id'], UICalendarEvent> } | 
  { type: ActionType.SAVED_MEETING; meeting: Meeting } | 
  { type: ActionType.UPDATED_MEETING_SLOT; meetingSlot: MeetingSlot } | 
  { type: ActionType.DELETED_MEETING_SLOT; slotId: number } |
  { type: ActionType.DELETED_MEETING_SLOTS; slotIds: number[] } |
  {
    type: ActionType.UPDATED_MEETING_QUESTION;
    meetingQuestion: MeetingQuestion;
    isSavedMeeting: boolean;
  } | 
  {
    type: ActionType.DELETED_MEETING_QUESTIONS;
    meetingId: number;
    questionIds: number[];
    isSavedMeeting: boolean;
  } |
  { type: ActionType.FETCHED_ZOOM_SETTINGS; conferenceSettings: { [id: string]: ZoomSettings } } |
  { type: ActionType.UPDATED_ZOOM_SETTINGS; conferenceSettings: ZoomSettings } |
  { type: ActionType.FETCHED_SCHEDULING_PREFS; preferences: SchedulingPreferencesResponse } |
  { type: ActionType.UPDATED_SCHEDULING_PREFS; preferences: SchedulingPreferences } |
  { type: ActionType.SET_SCHEDULE_ERRORS; errors: {[key:string]: MeetingHoldEventError[]}} |
  { type: ActionType.SET_MEETING_SCHEDULE_ERRORS; errors: MeetingHoldEventError[], meetingId: number } |
  { type: ActionType.SET_MEETING_QUESTION_ANSWERS; questionAnswers: {[id: string]: MeetingQuestionAnswer }} |
  { type: ActionType.SET_EXTERNAL_PARTICIPANT; externalParticipant: PublicExternalParticipant} |
  {
    type: ActionType.SET_MEETING_PARTICIPANT;
    externalParticipant: PrivateExternalParticipant;
    isSavedMeeting: boolean;
  } |
  { type: ActionType.FETCHED_PARTICIPANT_AUTOCOMPLETE_OPTIONS; options: ParticipantAutocompleteOption[] } |
  { type: ActionType.UPDATED_MEETING_PARTICIPANT;
    participantId: PublicExternalParticipant['id'];
    meetingId: Meeting['id'];
    externalParticipant: Partial<Omit<PublicExternalParticipant, 'id'>>;
    isSavedMeeting: boolean;
  } |
  { type: ActionType.DELETED_MEETING_PARTICIPANT;
    participantId: PublicExternalParticipant['id'];
    meetingId: Meeting['id'];
    isSavedMeeting: boolean;
  } |
  { type: ActionType.UPDATED_MEETING_LEADER;
    meetingId: Meeting['id'];
    leaderId: Leader['id'];
    meetingLeader: Partial<Omit<MeetingLeader, 'meeting' | 'leader'>>;
    isSavedMeeting: boolean;
  } |
  { type: ActionType.SET_MEETING_ROOMS; rooms: {[id: string]: MeetingRoom}} | 
  { type: ActionType.SET_MEETING_AUDIT_LOGS; auditLogs: {[id: string]: MeetingAuditLog}} |
  { type: ActionType.SET_RECENTLY_SCHEDULED; meetings: Meetings} |
  { type: ActionType.FETCHED_PRESET_LOCATIONS; locations: PresetLocations } |
  { type: ActionType.UPDATED_PRESET_LOCATION; id: number, location: PresetLocation } |
  { type: ActionType.DELETED_PRESET_LOCATION; id: number };
