// Polyfills
import 'core-js';
import 'cross-fetch/polyfill';
import './utils/initResizeObserverPolyfill';

// Initialize sentry first in separate file
import './utils/initSentry';
// Initialize logrocket early in separate file (per guidance from logrocket docs)
import './utils/initLogrocket';

import '@capacitor/core';
import './index.css';

import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { Device } from '@capacitor/device';
import { initAnalytics, configureDeviceMeta, checkIsMobileWeb, browserCompatibilityCheck, 
  googleTagManagerStart } from './initUtils';
//import { removeBadCognitoCookies, removeBadLocalStorageTokens } from './utils/storageUtils';
import { CapacitorException, ExceptionCode } from '@capacitor/core';
import '@stripe/stripe-js';
import { LicenseInfo } from '@mui/x-license';
import { MaintenanceMode } from './pages/Maintenance';
import { createRoot } from 'react-dom/client';
import { cabCaptureException } from './utils/logging';
import { isPlatform } from './utils/platformUtils';
import { Amplify } from 'aws-amplify';
import { getAmplifyConfig, getCookieStorage } from './amplifyUtils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';
import { PAGE_URL } from './constants';

// START index.html inline script replacement
// Toggle Demo Mode
if (import.meta.env.VITE_DEPLOY_ENV === 'demo') {
  window.DEMO_MODE = '1';
} else {
  window.DEMO_MODE = '0';
}

// Check for browser compatibility
browserCompatibilityCheck();

// --- Start Google Tag Manager ---
//    Note, there is still a <script> tag that loades gtag from a src in the index.html
googleTagManagerStart(window, document, 'script', 'dataLayer', import.meta.env.VITE_TAG_MANAGER_ID || '');

window.dataLayer = window.dataLayer || [];
const gtag: Gtag.Gtag = function gtag() {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
};

gtag('js', new Date());

gtag('config', import.meta.env.VITE_TAG_MANAGER_ID || '', {
  'linker': {
    'domains': ['checkout.stripe.com']
  }
});
// --- End Google Tag Manager ---
// END index.html inline script replacement

interface UserVars { displayName: string; email: string; [key: string]: string|number|boolean }

declare global {

  interface GTAGPurchaseEvent {
    'event': 'purchase';
    'transactionId': string;
    'transactionAffiliation': string;
    'transactionTotal': number;
    'transactionCurrency': string;
    'transactionTax': number;
    'transactionShipping': number;
    'transactionProducts': {
      'sku': string;
      'name': string;
      'category': string;
      'price': number;
      'quantity': number;
    }[]
  }

  interface GTAGSignupCompleteEvent {
    'event': 'signup-complete';
  }
  interface Window { 
    DEMO_MODE: '0' | '1';
    FS: { identify: (userId: string, { displayName, email }: UserVars) => void };
    MIXPANEL_ENABLED: boolean;
    IS_MOBILE: boolean;
    IS_NATIVE_IOS: boolean;
    IS_NATIVE_ANDROID: boolean;
    IS_MOBILEWEB: boolean;
    IS_DESKTOP: boolean;
    opera: string;

    dataLayer: Array<GTAGPurchaseEvent | GTAGSignupCompleteEvent | IArguments>;

    // perfStart is here for performance timing (e.g. perfStart = performance.now())
    //    Honestly we can probably just use console.time() and console.timeLog()
    perfTime: number;
    perfStart: () => void;
    perfNext: (msg: string) => void;
  }
  
  // This is required to fix a MUI incompatibility with React 17.
  // We should be able to remove this when we upgrade to React 18.
  // https://github.com/mui/material-ui/issues/35287
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace React {
    interface DOMAttributes<T> {
      onResize?: ReactEventHandler<T> | undefined;
      onResizeCapture?: ReactEventHandler<T> | undefined;
      nonce?: string | undefined;
    }
  }
}

// Start (or 'tare') the performance timer
window.perfStart = () => {
  window.perfTime = performance.now();
};

// Show the time in ms since the last time a measurement was made
window.perfNext = (msg: string) => {
  console.log(msg, performance.now() - window.perfTime);
  window.perfStart();
};

// If we ever change the ClientID for Cognito, some people will have old cookies/localstorage and we risk 
//    the header getting too long or wrong client being used. This has already happened once around 09/13/2020.
// The functions below can be used to clear out any tokens/cookies with the wrong ClientID.
// removeBadCognitoCookies();
// removeBadLocalStorageTokens();

if (process.env.NODE_ENV === 'production') {
  // Sentry and LogRocket already initialized (if production build) at top of file via imports
  initAnalytics();
  configureDeviceMeta();
}

if (isPlatform('mobile') || isPlatform('android') || isPlatform('ios')) {
  Keyboard.setResizeMode({mode: KeyboardResize.Native}).catch((e: CapacitorException) => {
    if (e.code !== ExceptionCode.Unimplemented) {
      cabCaptureException(e);
    }
  });
}


window.IS_MOBILE = false;
// Default IS_NATIVE_IOS/ANDROID to true just to be sure we don't violate any policies in the App Store even before 
// Device.getInfo() completes to verify whether or not it really is native iOS/Android. Better safe than sorry.
window.IS_NATIVE_IOS = true;
window.IS_NATIVE_ANDROID = true;

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_PRO_LICENSE_KEY || "");

Device.getInfo().then(deviceInfo => {
  window.IS_MOBILE = deviceInfo.operatingSystem === 'android' || deviceInfo.operatingSystem === 'ios';
  window.IS_NATIVE_IOS = deviceInfo.platform === 'ios';
  window.IS_NATIVE_ANDROID = deviceInfo.platform === 'android';
  window.IS_MOBILEWEB = checkIsMobileWeb();
}).catch(e => {
  if (e instanceof CapacitorException && e?.code !== ExceptionCode.Unimplemented) {
    cabCaptureException(e);
  }
  // According to Sentry, this is happening only for Android.
  window.IS_MOBILE = true;
  window.IS_NATIVE_IOS = false;
  window.IS_NATIVE_ANDROID = true;
  window.IS_MOBILEWEB = false;
}).finally(() => {
  // Amplify needs window.IS_NATIVE_IOS and window.IS_NATIVE_ANDROID set before initialization

  // aws-amplify hijacks the `code` URL param, so we need to change the name of that param for
  //   any page where we are using it to do MS code grant flows for calendar integration
  // https://github.com/aws-amplify/amplify-js/issues/3494
  const pathName = window.location.pathname;
  if (pathName.includes(PAGE_URL.SIGNUP) || pathName.includes(PAGE_URL.INTEGRATION_SETTINGS)) {
    const newSearch = window.location.search.replace('?code', '?oauth_code').replace('?error', '?oauth_error');
    const newUrl = `${pathName}${newSearch}`;
    window.history.replaceState({path: newUrl}, '', newUrl);
  }

  Amplify.configure(getAmplifyConfig());
  const cookieStorage = getCookieStorage();
  if (cookieStorage) {
    cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage(cookieStorage));
  }

  const ele = document.getElementById('root');

  if (ele) {
    const root = createRoot(ele);
    if (import.meta.env.VITE_MAINTENANCE_BACKEND === "1") {
      
      root.render(<MaintenanceMode />);
    } else {
      root.render(<App />);
    }
  }

});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();