import { API_URLS }  from '../../apiUrls';
import { ActionType } from '../actionTypes';
import { ThunkResult, ThunkDispatchType, FetchReturn } from '../types';
import { makeHeaders, makeParams, fetchData } from '../../utils/apiUtils';
import { RootState, PersonalNote } from '..';

export const fetchPersonalNotes = (leader: number): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    const params = makeParams({leader});

    return fetchData(API_URLS.PERSONAL_NOTES + params, { headers, }).then((res: FetchReturn): void  => {
      if (res.status === 200) {
        dispatch({ type: ActionType.FETCHED_PERSONAL_NOTES, personalNotes: res.data });
      }
    });
  };

export const createPersonalNote = (note: PersonalNote): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    const {id, ...newNote} = note;
    const body = JSON.stringify(newNote);
    return fetchData(API_URLS.PERSONAL_NOTES, 
      { headers, method: 'POST', body}).then((res: FetchReturn): void  => {
      if (res.status === 201) {
        dispatch({ type: ActionType.CREATED_PERSONAL_NOTE, personalNote: res.data });
      }
    });
  };

export const updatePersonalNote = (note: PersonalNote): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify(note);

    return fetchData(API_URLS.PERSONAL_NOTES + note.id + '/',
      { headers, method: 'PATCH', body }).then((res: FetchReturn): void => {
      if (res.status === 200) {
        dispatch({ type: ActionType.UPDATED_PERSONAL_NOTE, personalNote: res.data});
      }
    });
  };

export const deletePersonalNote = (noteId: number): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);

    return fetchData(API_URLS.PERSONAL_NOTES + noteId + '/', { headers, method: 'DELETE'})
      .then((res: FetchReturn): void => {
        dispatch({ type: ActionType.DELETED_PERSONAL_NOTE, personalNoteId: noteId});
      });
  };