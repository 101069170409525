import React, { ReactElement } from "react";
import {
  Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, 
  DialogTitle, IconButton, Slide, SlideProps, styled, useMediaQuery, useTheme
} from '@mui/material';
import { CabComponentProps } from "../cabStyled";
import colors from "../../../colors";
import { CabIcon } from "../CabIcon";
import { IoClose } from "react-icons/io5";

// This is the way MUI documentation indicates Dialog transitions should be created
//  and implemented: https://mui.com/material-ui/react-dialog/#AlertDialogSlide.tsx
const DialogTransition = React.forwardRef(function DialogTransition(
  props: SlideProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface CabModalProps extends CabComponentProps<DialogProps>{
  children?: DialogProps['children'];
  open: DialogProps['open'];
  onClose?: () => void;
  title?: ReactElement | string;
  text?: string | ReactElement;
  actionButtons?: ReactElement; 
  closeIcon?: boolean;
  closeOnBackdropClick?: boolean;
  noFullScreen?: boolean;
  isAlert?: boolean;
  stickyBottomContent?: ReactElement;
}

export const CabModal = ({
  open, 
  className, 
  onClose, 
  sx, 
  title, 
  text, 
  overrides, 
  actionButtons,
  noFullScreen,
  isAlert,
  children, 
  closeIcon, 
  closeOnBackdropClick,
  stickyBottomContent
}: CabModalProps): ReactElement => {
  const props: DialogProps = {
    open,
    className,
    sx,
  };

  const theme = useTheme();

  if (!isAlert) {
    props.TransitionComponent = DialogTransition;
  }

  props.fullScreen = useMediaQuery(noFullScreen ? '(max-width:0px)' : theme.breakpoints.down('sm'));

  return (
    <DialogStyled {...props}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' && closeOnBackdropClick && onClose) {
          onClose();
        }
      }}
      {...overrides}
    >
      <DialogTitle>
        {title}
        {closeIcon ? (
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          >
            <CabIcon Icon={IoClose} alt='Close' />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
        {children}
      </DialogContent>
      <Box>
        {stickyBottomContent}
      </Box>
      {actionButtons && 
        <DialogActions>
          {actionButtons}
        </DialogActions>
      }
    </DialogStyled>
  );
};

const DialogStyled = styled(
  Dialog, {label: "DialogStyled"}
)<CabComponentProps<DialogProps>>(({ theme }) => ({
  paddingTop: 'env(safe-area-inset-top)',
  paddingBottom: 'env(safe-area-inset-bottom)',
  '& .MuiDialog-root': {
    display: 'flex',
    gap: 60,
  },
  '& .MuiDialog-paper': {
    width: 450,
  },
  '& .MuiDialogTitle-root': {
    fontWeight: 700,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 40,
    paddingLeft: 24,
    marginRight: 20,
  },
  ...(theme.palette.mode === 'dark' ? {
    '& .MuiIconButton-root': {
      color: colors.white800,
    }
  } : {
    '& .MuiIconButton-root': {
      color: colors.black800,
    }
  })
}));