import * as Sentry from '@sentry/react';
import { User } from '../store';
import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga';

import { 
  EVENT_TYPE, ANALYTICS_REPORT_TYPE, ANALYTICS_METRIC_TYPE, EXEC_CARD_TYPES, 
  ANALYTICS_OPTIONS, MEETING_STATUS_LABELS, BILLING_INTERVAL, MEETING_PRIORITY_LABELS, TIER 
} from '../constants';
import LogRocket from 'logrocket';

interface ExecCardExtra {
  category: typeof EXEC_CARD_TYPES[keyof typeof EXEC_CARD_TYPES]
}

export interface FilterExtra {
  executives: boolean, 
  labels: boolean,
  urgent: boolean, 
}

interface AnalyticsTimeExtra {
  option: typeof ANALYTICS_OPTIONS[keyof typeof ANALYTICS_OPTIONS]
}

interface AnalyticsMetricExtra {
  type: ANALYTICS_METRIC_TYPE
}

interface AnalyticsReportExtra {
  type: ANALYTICS_REPORT_TYPE
}

interface SchedulingStatusChangeExtra {
  status: typeof MEETING_STATUS_LABELS;
}
interface SchedulingPriorityChangeExtra {
  priority: typeof MEETING_PRIORITY_LABELS;
}

interface CabinetSubscriptionExtra {
  tier?: TIER | null;
  interval?: BILLING_INTERVAL | null;
  quantity?: number | null;
}

interface PurchaseExtra {
  transaction_id: string;
  value: number;
  currency: string;
  items: {
    id: number;
    name: string;
    brand: string;
    category: string;
    price: number;
    quantity: number;
  }[];
}

interface LearnMoreBookingExtra {
  organizationId: number
  organizationName: string
  userId: number
  meetingId: number
}

type TrackEventWithExtra = 
  { eventName: EVENT_TYPE.FILTER_TASKS; extra: FilterExtra; } | 
  { eventName: EVENT_TYPE.CREATE_EXEC_CATEGORY_CARD; extra: ExecCardExtra; } |
  { eventName: EVENT_TYPE.UPDATE_EXEC_CATEGORY_CARD; extra: ExecCardExtra; } |
  { eventName: EVENT_TYPE.DELETE_EXEC_CATEGORY_CARD; extra: ExecCardExtra; } |
  { eventName: EVENT_TYPE.CHANGE_ANALYTICS_TIME; extra: AnalyticsTimeExtra; } |
  { eventName: EVENT_TYPE.CHANGE_ANALYTICS_METRIC_TYPE; extra: AnalyticsMetricExtra; } |
  { eventName: EVENT_TYPE.CHANGE_ANALYTICS_REPORT_TYPE; extra: AnalyticsReportExtra; } |
  { eventName: EVENT_TYPE.SCHEDULING_CHANGE_STATUS; extra: SchedulingStatusChangeExtra; } |
  { eventName: EVENT_TYPE.SCHEDULING_CHANGE_PRIORITY; extra: SchedulingPriorityChangeExtra; } |
  { eventName: EVENT_TYPE.SCHEDULING_POLL_CHANGE_STATUS; extra: SchedulingStatusChangeExtra; } |
  { eventName: EVENT_TYPE.PROMO_TIER_CLICKED; extra: CabinetSubscriptionExtra; } |
  { eventName: EVENT_TYPE.SUBSCRIPTION_TIER_CLICKED; extra: CabinetSubscriptionExtra; } |
  { eventName: EVENT_TYPE.SUBSCRIPTION_TIER_CONFIRMED; extra: CabinetSubscriptionExtra; } |
  { eventName: EVENT_TYPE.SUBSCRIPTION_LEARN_MORE_CLICKED; extra: CabinetSubscriptionExtra; } |
  { eventName: EVENT_TYPE.SUBSCRIPTION_CHANGED; extra: CabinetSubscriptionExtra; } |
  { eventName: EVENT_TYPE.PURCHASE; extra: PurchaseExtra; } |
  { eventName: EVENT_TYPE.SCHEDULING_CHANGE_DATE_SCHEDULED, extra: Record<string, void>} |
  { eventName: EVENT_TYPE.MEETING_BOOKED_SIGNUP, extra: LearnMoreBookingExtra} |
  { eventName: EVENT_TYPE.MEETING_POLL_SIGNUP, extra: LearnMoreBookingExtra} |
  { eventName: EVENT_TYPE.ONBOARD_TIER_SELECTION, extra: CabinetSubscriptionExtra} |
  { eventName: EVENT_TYPE.ONBOARD_CONTINUE_WITH_PLAN, extra: CabinetSubscriptionExtra}; 

export const trackEventWithExtra = (
  {eventName, extra}: TrackEventWithExtra, callback?: (...args: unknown[]) => unknown
): void => {
  if (window.MIXPANEL_ENABLED) {
    mixpanel.track(eventName, extra, callback);
  } else if (callback) {
    callback();
  }
};

export const trackEvent = (
  eventName: typeof EVENT_TYPE[keyof typeof EVENT_TYPE], callback?: (...args: unknown[]) => unknown
): void => {
  if (window.MIXPANEL_ENABLED) {
    mixpanel.track(eventName, callback);
  } else if (callback) {
    callback();
  }
};

export const trackPageView = (page: string, extra: {[key: string]: string} | undefined = undefined): void => {
  if (window.MIXPANEL_ENABLED) {
    mixpanel.track(page, extra);
  }

  // Using GA just for attribution and real-time view
  if (import.meta.env.VITE_GOOGLE_ANALYTICS_ID) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};

export const trackSignupComplete = (): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'signup-complete',
  });
};

export const setUserForMetrics = (user: User): void => {
  // Some services (e.g. Logrocket) may only start after the user has been logged in.
  //   This delay is ensure those systems can first start before the user is configured.

  setTimeout(() => {
    // Sentry User ID and tags
    const sentryScope = Sentry.getCurrentScope();
    sentryScope.setUser({
      id: user.id.toString(),
      username: user.email,
      email: user.email,
    });

    LogRocket.identify(user.id.toString(), {
      displayName: user.first_name + " " + user.last_name,
      email: user.email
    });
    
    if (window.MIXPANEL_ENABLED) {
      mixpanel.identify(user.id.toString());
      mixpanel.people.set({
        '$email': user.email,
        'USER_ID': user.id.toString(),
        // Setting source here will override what we set in mixpanel, so don't do it here
        //'SOURCE': user.profile.source || '',
      });
    }
  }, 1000);
};