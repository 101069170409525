type AppPlatform = "android" | "ios" | "mobile" | "desktop" | "mobileweb";

// This replaces the isPlatform function from Ionic, but is not 1:1
export const isPlatform = (qPlatorm: AppPlatform): boolean => {
  // These window variables are loaded on init using Capacitor's Device.getInfo() function
  switch (qPlatorm) {
    case "android":
      return window.IS_NATIVE_ANDROID;
    case "ios":
      return window.IS_NATIVE_IOS;
    case "mobile":
      return window.IS_MOBILE;
    case "desktop":
      return !window.IS_MOBILE;
    case "mobileweb":
      return window.IS_MOBILEWEB;
    default:
      return false;
  }
};

// This replaces the getPlatforms function from Ionic, but is not 1:1
export const getPlatforms = (): AppPlatform[] => {
  const platforms: Set<AppPlatform> = new Set();
  if (window.IS_NATIVE_ANDROID) {
    platforms.add('android');
    platforms.add('mobile');
  }
  if (window.IS_NATIVE_IOS) {
    platforms.add('ios');
    platforms.add('mobile');
  }
  if (window.IS_MOBILE) {
    platforms.add('mobile');
  }
  if (window.IS_MOBILEWEB) {
    platforms.add('mobileweb');
  }
  if (window.IS_DESKTOP) {
    platforms.add('desktop');
  }

  return Array.from(platforms.keys());
};