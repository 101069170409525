import { Box, Modal } from '@mui/material';
import { ReactElement } from 'react';
import { IoClose } from 'react-icons/io5';
import { CabIcon } from '@CabComponents/CabIcon';
import colors from '../../colors';

export interface GlobalModalProps {
  open: boolean;
  component: ReactElement;
  onClose: () => void;
}

export const GlobalModal = ({ open, component, onClose }: GlobalModalProps): ReactElement => {
  return <Modal
    disableEnforceFocus
    disableAutoFocus
    open={open}
    onClose={(event, reason) => {
      if (reason === 'backdropClick') {
        onClose();
      }
    }}
    sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '40px'}}
  >
    <Box position='absolute' padding={4} textAlign='center' overflow='auto' 
      borderRadius='6px' sx={{backgroundColor: colors.black100}}>
      <Box position='absolute' top={0} right={0} onClick={onClose} sx={{cursor: 'pointer'}}>
        <CabIcon Icon={IoClose} alt='Close' sx={{fontSize: '28px'}}/>
      </Box>
      {component}
    </Box>
  </Modal>;
};