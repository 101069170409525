import { API_URLS }  from '../../apiUrls';
import { ActionType } from '../actionTypes';
import { ThunkResult, ThunkDispatchType, FetchReturn } from '../types';
import { makeHeaders, makeParams, fetchData } from '../../utils/apiUtils';
import { RootState, ProfileNote, ProfileCategory } from '..';

export const fetchProfileCategories = (leader: number): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    dispatch({ type: ActionType.FETCHING_PROFILE_CATEGORIES });
    
    const headers = await makeHeaders(true);
    const params = makeParams({leader});

    const res = await fetchData(API_URLS.PROFILE_CATEGORIES + params, { headers, });
    if (res.status === 200) {
      dispatch({ type: ActionType.FETCHED_PROFILE_CATEGORIES, profileCategories: res.data });
    }
  };

export const createProfileCategory = (category: ProfileCategory): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    const {id, ...newCategory} = category;
    if (newCategory.order <= 0) {
      // If no order was specified, put it at the end
      const currentOrders = Object.values(getState().profileNotes.categories).map(c => c.order);
      const maxOrder = currentOrders.length > 0 ? Math.max(...currentOrders) : 0;
      newCategory.order = maxOrder + 1;
    }
    const body = JSON.stringify(newCategory);
    return fetchData(API_URLS.PROFILE_CATEGORIES, 
      { headers, method: 'POST', body}).then((res: FetchReturn): void  => {
      if (res.status === 201) {
        dispatch({ type: ActionType.CREATED_PROFILE_CATEGORY, profileCategory: res.data });
      }
    });
  };

export const updateProfileCategory = (category: ProfileCategory): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify(category);

    return fetchData(API_URLS.PROFILE_CATEGORIES + category.id + '/',
      { headers, method: 'PATCH', body }).then((res: FetchReturn): void => {
      if (res.status === 200) {
        dispatch({ type: ActionType.UPDATED_PROFILE_CATEGORY, profileCategory: res.data});
      }
    });
  };

export const deleteProfileCategory = (category: ProfileCategory): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);

    return fetchData(API_URLS.PROFILE_CATEGORIES + category.id + '/', { headers, method: 'DELETE'})
      .then((res: FetchReturn): void => {
        dispatch({ type: ActionType.DELETED_PROFILE_CATEGORY, profileCategory: category});
      });
  };

export const fetchProfileNotes = (leader: number): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(true);
    const params = makeParams({leader});

    const res = await fetchData(API_URLS.PROFILE_NOTES + params, { headers, });
    if (res.status === 200) {
      dispatch({ type: ActionType.FETCHED_PROFILE_NOTES, profileNotes: res.data });
    }
  };

export const createProfileNote = (note: ProfileNote): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    const {id, ...newNote} = note;
    const body = JSON.stringify(newNote);
    return fetchData(API_URLS.PROFILE_NOTES, 
      { headers, method: 'POST', body}).then((res: FetchReturn): void  => {
      if (res.status === 201) {
        dispatch({ type: ActionType.CREATED_PROFILE_NOTE, profileNote: res.data });
      }
    });
  };

export const updateProfileNote = (note: ProfileNote): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify(note);

    return fetchData(API_URLS.PROFILE_NOTES + note.id + '/',
      { headers, method: 'PATCH', body }).then((res: FetchReturn): void => {
      if (res.status === 200) {
        dispatch({ type: ActionType.UPDATED_PROFILE_NOTE, profileNote: res.data});
      }
    });
  };

export const deleteProfileNote = (noteId: number): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);

    return fetchData(API_URLS.PROFILE_NOTES + noteId + '/', { headers, method: 'DELETE'})
      .then((res: FetchReturn): void => {
        dispatch({ type: ActionType.DELETED_PROFILE_NOTE, profileNoteId: noteId});
      });
  };