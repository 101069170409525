import { ReactElement } from 'react';
import { Paper, Grid, Typography, Box } from '@mui/material';
import { BILLING_INTERVAL } from '../../../constants';
import { IoCheckmark } from 'react-icons/io5';
import { CabButton, CabIcon } from '@CabComponents';
import colors from '../../../colors';

interface Props { 
  name: string;
  monthlyPrice: number;
  annualPrice: number;
  features: string[];
  buttonText: string;
  interval: BILLING_INTERVAL;
  planNumber: number;
  monthlyPriceString?: string;
  onPlanSelected: (planNumber: number) => void;
}

const PaymentPlan = ({
  name, monthlyPrice, annualPrice, features, buttonText, interval, planNumber, monthlyPriceString, onPlanSelected
}: Props): ReactElement => {
      
  const priceToShow = interval === BILLING_INTERVAL.YEAR ? Math.floor(annualPrice / 12) : monthlyPrice;

  return (
    <Paper sx={{padding: '40px 32px', boxShadow: 'none', justifyContent: 'space-between', display: 'flex',
      flexDirection: 'column'}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h1'>{name}</Typography>
        </Grid>
        <Grid item xs={12}>
          {
            features.map((feature, key) => (
              <Box key={key} display='flex' marginTop={1} gap={2} fontSize={20}>
                <CabIcon Icon={IoCheckmark} alt={feature}/>
                <Typography variant='body1'> 
                  {feature}
                </Typography>
              </Box>
            ))
          }
        </Grid>
      </Grid>
      <Box marginTop={3} width='100%'>
        <Grid container item xs={6}>
          <Typography variant='h1'>
            {monthlyPriceString ? monthlyPriceString : priceToShow > 0 ? `$${priceToShow}/month` : 'Free'}
          </Typography>
        </Grid>
        <CabButton 
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => onPlanSelected(planNumber)}  
          buttonType="primary"
          sx={{width: '100%', marginTop: 2}}
        >
          <Typography variant='h4' color={colors.white900}>{buttonText}</Typography>
        </CabButton>
      </Box>
    </Paper>
  );
};

export default PaymentPlan;