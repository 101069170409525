import { ReactElement, useMemo, useState } from 'react';
import { 
  PickersDay, DatePicker, DatePickerProps,
  PickersDayProps, DatePickerToolbarProps
} from '@mui/x-date-pickers-pro';
import {DateTime} from 'luxon';
import { getWeekRange } from '../../../utils/scheduleUtils';
import { CabComponentProps } from '../cabStyled';
import colors from "../../../colors";
import { CabInput } from '../CabInput';
import { Box, InputProps, styled, TextFieldProps, Typography } from '@mui/material';
import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar/PickersActionBar';
import { CabTimePicker } from '@CabComponents/CabTimePicker';


export interface CabDatePickerProps extends CabComponentProps<DatePickerProps<DateTime>> {
  value: string | null;
  onChange: (newDate: string | null) => void;
  clearable?: boolean;
  disabled?: boolean;
  type?: 'week' | 'day';
  timezone?: string;
  futureOnly?: boolean;
  size?: InputProps['size']
  onUpdate?: () => void;
  showTimePicker?: boolean;
  onClose?: () => void;
  allowedDates?: DateTime[];
}

const CabInputStyled = styled(CabInput, {
  label: "CabInputStyled" }
)<TextFieldProps>(({ size }) => ({
  '& .MuiOutlinedInput-root': {
    height: 40,
    '.MuiInputBase-input.Mui-disabled': {
      backgroundColor: 'transparent'
    }
  },
  '&:disabled': {
    backgroundColor: 'red'
  },
  ...(size === 'small' && {
    '& .MuiOutlinedInput-root': {
      height: 32,
      fontSize: 14,
    },
    '& .MuiInputAdornment-root': {
      margin: 0,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        paddingLeft: 0
      }
    }
  })
}));

const Day = (pickersDayProps: PickersDayProps<DateTime> & {
  highlightedDays: DateTime[]; hoverDays: DateTime[]; onHoverDate: ((day?: DateTime) => void); rangeType: 'day'|'week'
}) => {
  const { onHoverDate, highlightedDays, hoverDays, rangeType, ...props } = pickersDayProps;
  const newProps = { ...props };
  const day = newProps.day;

  if (rangeType === 'week') {
    newProps.autoFocus = false;
    if (highlightedDays[1]?.hasSame(day.plus({ day: 1 }), 'week')) {
      newProps.disableMargin = true;
      newProps.sx = (theme) => ({
        borderTopLeftRadius: highlightedDays[0].hasSame(day, 'day') ? undefined : 0,
        borderBottomLeftRadius: highlightedDays[0].hasSame(day, 'day') ? undefined : 0,
        borderTopRightRadius: highlightedDays[1].hasSame(day, 'day') ? undefined : 0,
        borderBottomRightRadius: highlightedDays[1].hasSame(day, 'day') ? undefined : 0,
        width: 40,
        backgroundColor: colors.navyPrimary,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
          backgroundColor: colors.blueLight,
        },
        '&.Mui-selected': {
          backgroundColor: colors.navyPrimary,
          '&:hover': {
            backgroundColor: colors.blueLight,
          }
        },
        '&.MuiPickersDay-root:not(.Mui-selected)':{
          borderWidth: 2,
          borderTop: 'none',
          borderBottom: 'none',
          borderColor: colors.white900
        }
      });
    } else if (hoverDays[1]?.hasSame(day.plus({ day: 1 }), 'week')) {
      newProps.disableMargin = true;
      newProps.sx = (theme) => ({
        borderTopLeftRadius: hoverDays[0].hasSame(day, 'day') ? undefined : 0,
        borderBottomLeftRadius: hoverDays[0].hasSame(day, 'day') ? undefined : 0,
        borderTopRightRadius: hoverDays[1].hasSame(day, 'day') ? undefined : 0,
        borderBottomRightRadius: hoverDays[1].hasSame(day, 'day') ? undefined : 0,
        width: 40,
        backgroundColor: colors.navyPrimary,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
          backgroundColor: colors.blueLight,
        },
        '&.Mui-selected': {
          backgroundColor: colors.navyPrimary
        }
      });
    }

    newProps.onMouseEnter = () => onHoverDate(day);
    newProps.onMouseLeave = () => onHoverDate(undefined);
  } else if (rangeType === 'day') {
    newProps.disableMargin = true;
    newProps.sx = (theme) => ({
      width: 40,
      '&:hover, &:focus': {
        backgroundColor: colors.blueLight,
      },
      '&.MuiPickersDay-root.Mui-selected': {
        backgroundColor: colors.navyPrimary,
        '&:hover': {
          backgroundColor: colors.blueLight,
        }
      },
    });
  }

  return <PickersDay {...newProps} />;
};


export const CabDatePicker = ({ 
  value, onChange, clearable, disabled, type, size, timezone, sx, futureOnly, onUpdate, onClose,
  showTimePicker = false, allowedDates,
}: CabDatePickerProps): ReactElement => {
  let highlightedDays: DateTime[] = [];
  let hoverDays: DateTime[] = [];
  let weekString: string | undefined;

  const [open, setOpen] = useState(false);
  const [hoverDate, setHoverDate] = useState<DateTime | undefined>();

  if (value && type === 'week') {
    const { start, end } = getWeekRange(DateTime.fromISO(value), timezone); 
    highlightedDays = [start, end];

    weekString = `${start.toFormat('MM/dd')} to ${end.toFormat('MM/dd')}`;
  }

  if (hoverDate && type === 'week') {
    const { start, end } = getWeekRange(hoverDate, timezone); 
    hoverDays = [start, end];
  }

  const placeholderText = type === 'week' ? 'Select week' : 'Select date';
  
  const actions = useMemo(() => {
    const pickerActions: PickersActionBarAction[] = showTimePicker ? [] : ["today"];
    if (clearable) {
      pickerActions.push("clear");
    }
    return pickerActions;
  }, [clearable, showTimePicker]);

  const handleOnChange = onUpdate ? (e: DateTime|null) => {
    onChange(e?.toISO() || null);
    onUpdate();
  } : (e: DateTime|null) => onChange(e?.toISO() || null);

  return <DatePicker
    open={disabled ? false : open}
    shouldDisableDate={allowedDates ? (date => !allowedDates.find(d => date.hasSame(d, 'day'))) : undefined}
    format={'MM/dd/yyyy'}
    onOpen={() => setOpen(true)}
    onClose={() => {
      setOpen(false);
      if (onClose) {
        onClose();
      }
    }}
    disabled={disabled}
    value={value ? DateTime.fromISO(value) : null}
    onChange={handleOnChange}
    closeOnSelect={!showTimePicker}
    showDaysOutsideCurrentMonth
    minDate={futureOnly ? DateTime.now() : undefined}
    slots={{
      textField: CabInputStyled,
      // @ts-expect-error need custom props
      day: Day,
      toolbar: (props: DatePickerToolbarProps<DateTime>) =>  showTimePicker ? <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          bottom: 12,
          right: 12,
          zIndex: 10
        }}
      >
        <Typography sx={{marginRight: 1}} >Select a Time</Typography>
        <CabTimePicker
          pickerTime={value ? `${DateTime.fromISO(value).hour}:${DateTime.fromISO(value).minute}` : ""}
          onChange={(newTime) => {
            if (value) {
              const dateTime = DateTime.fromISO(value);
              const [hours, minutes] = newTime.split(":");
              const timeUpdate = DateTime.fromObject({
                ...dateTime.toObject(),
                hour: Number(hours),
                minute: Number(minutes)
              });
              handleOnChange(timeUpdate);
            }
          }}
        />
      </Box> : <></>,
    }}
    slotProps={{
      actionBar: {
        actions
      },
      desktopPaper: {
        sx: {
          display: "flex",
          flexDirection: "column",
        }
      },
      textField: {
        placeholder: placeholderText,
        onClick: () => setOpen(true),
        size,
        inputProps: weekString ? {
          readOnly: true,
          value: weekString || undefined,
          sx,
        } : {
          readOnly: true,
          sx,
        }
      },
      day: {
        // @ts-expect-error need custom props
        onHoverDate: (day) => setHoverDate(day), highlightedDays, hoverDays, rangeType: type,
      },
    }}
  />;
};

export default CabDatePicker;
