import React, { ReactElement, ReactNode } from 'react';
import { cabCaptureException } from '../../utils/logging';

class ErrorBoundary extends React.Component<{children: ReactElement}> {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error): { hasError: boolean} {
    if (error?.message !== 'ResizeObserver loop limit exceeded') {
      cabCaptureException(error);
    }

    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // NOTE: This does not get triggered using react-router 6.21.1
    // Error logging should happen from this method instead of the method above

    // if (error?.message === 'ResizeObserver loop limit exceeded') {
    //   return;
    // }
    // console.log('Error', error);
    // console.log('Error Info', errorInfo);
    // cabCaptureException(error);
  }

  render(): ReactElement | ReactNode {
    return this.props.children; 
  }
}

export default ErrorBoundary;