import { API_URLS }  from '../../apiUrls';
import { ThunkResult, ThunkDispatchType, FetchReturn } from '../types';
import { makeHeaders, fetchData} from '../../utils/apiUtils';
import { RootState } from '..';
import { Feedback } from './types';
import { GlobalMessage } from '../globalMessages/types';
import { sendMessage } from '../globalMessages/actions';

export const createFeedback = (feedback: Feedback): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify(feedback);

    return fetchData(API_URLS.SEND_FEEDBACK, { headers, method: 'POST', body}).then((res: FetchReturn): void  => {
      if (res.status === 201) {
        const message: GlobalMessage = {
          timeout: 2000,
          message: 'Thank you for your feedback!',
          autoDismiss: true,
          header: '',
          position: undefined,
          active: true,
          severity: "success"
        }; 
        sendMessage(message)(dispatch, getState, null);
      }
    });
  };