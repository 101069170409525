import { forwardRef, ReactElement, ReactNode } from "react";
import { Button, ButtonProps, lighten, styled } from '@mui/material';
import { CabComponentProps } from "../cabStyled";
import colors from "../../../colors";


export interface CabButtonProps extends CabComponentProps<ButtonProps> {
  children?: ButtonProps['children'];
  buttonType?: 'primary'|'secondary'|'tertiary'|'text';
  color?: ButtonProps['color'];
  size?: ButtonProps['size'];
  disabled?: ButtonProps['disabled'];
  id?: ButtonProps['id'];
  icon?: ReactNode;
  onClick?: ButtonProps['onClick']
  type?: ButtonProps['type']
  startIcon?: ButtonProps['startIcon']
  endIcon?: ButtonProps['endIcon']
  hideIconSmall?: boolean,
  HTML5DataAttributes?:{[attribute: string]: string}
  onMouseEnter?: ButtonProps["onMouseEnter"]
  onMouseLeave?: ButtonProps["onMouseLeave"]
}

export const CabButton = forwardRef<HTMLButtonElement, CabButtonProps>(({
  children, buttonType, color, disabled, size, icon, endIcon, overrides, sx, className, onClick, type, id, 
  HTML5DataAttributes, onMouseEnter, onMouseLeave
}, ref): ReactElement => {
  const buttonProps: ButtonProps = {
    size,
    disabled,
    startIcon: icon,
    endIcon,
    id,
    onClick,
    onMouseEnter,
    onMouseLeave
  };

  switch (buttonType) {
    case 'secondary':
      buttonProps.variant = 'contained';
      buttonProps.color = 'secondary';
      break;

    case 'tertiary':
      buttonProps.variant = 'outlined';
      break;

    case 'text':
      buttonProps.variant = 'text';
      buttonProps.color = 'accent';
      break;
  
    default: // primary
      buttonProps.variant = 'contained';
      buttonProps.color = 'primary';
      break;
  }

  if (color) { // allow color to override buttonType set colors
    buttonProps.color = color;
  }

  return (
    <ButtonStyled ref={ref} type={type} {...buttonProps} {...overrides} {...HTML5DataAttributes} 
      className={className} sx={sx} >
      <span className="button-text">
        {children}
      </span>
    </ButtonStyled>
  );
});

const ButtonStyled = styled(
  Button, { label: "ButtonStyled" }
)<CabButtonProps>(({ theme, color, variant, size, hideIconSmall, disabled, onMouseEnter, onMouseLeave }) => {
  return {
    ...(size === 'small' && {
      height: 32,
      fontSize: 14,
      fontWeight: "normal"
    }),
    ...(theme.palette.mode === 'dark' ? {} : {
      '&:hover': {
        backgroundColor: color && color !== 'inherit' && theme.palette[color]
          ? lighten(theme.palette[color].main, variant === 'text' || variant === 'outlined' ? 0.9 : 0.1)
          : undefined,
      },
      ...(variant === "text" && {
        '& svg': {
          color: color && color !== 'inherit' && theme.palette[color] && !disabled
            ? theme.palette[color].main 
            : disabled ? colors.black300 : theme.palette.accent.main
        }
      })
    }),
    textTransform: 'none',
    ...(hideIconSmall ?
      {
        [theme.breakpoints.down('sm')]: {
          "& .button-text": {
            display: "none"
          }
        }
      }
      :
      {}
    )
  };
});