import { FC, ReactElement, useMemo } from "react";
import { 
  FilterOptionsState, styled, TextField, TextFieldProps, InputProps, lighten, darken, Typography
} from "@mui/material";
import { CabComponentProps } from "../cabStyled";
import { CabAutocomplete, CabAutocompleteProps, GenericAutocompleteOption } from "../CabAutocomplete";
import { allTimeZones, formatTimezoneOption, TimeZone } from "../../../utils/scheduleUtils";
import { RootState, SchedulingPreferences } from "../../../store";
import { useSelector } from "react-redux";

export interface CabTimezoneInputProps extends CabComponentProps<TextFieldProps>{
  onChange: (value: string | null) => void;
  value?: string;
  defaultValue?: string;
  label?: string;
  required?: boolean;
  additionalOptions?: TimeZoneOption[];
  size?: InputProps['size'];
  hideArrow?: boolean;
  shortFormat?: boolean;
  disabled?: boolean;
  id?: string;
  alignRight?: boolean;
}

interface TimeZoneMeta {
  allAbbreviations: TimeZone['allAbbreviations'];
  mainCities: TimeZone['mainCities'];
  group: "automatic" | "recent" | "popular" | "standard";
}

type TimeZoneOption = GenericAutocompleteOption<string, TimeZoneMeta>;

const tzRenderOptionValueMap: {[value: string]: string} = {};
const tzRenderShortOptionValueMap: {[value: string]: string} = {};

const priorityTZList = new Set([
  "Europe/London",
  "Europe/Dublin",
  "America/Toronto",
  "America/New_York", 
  "America/Chicago",
  "America/Denver", 
  "America/Edmonton",
  "America/Los_Angeles"
]);


const getTzOptions = (recentlyUsed?: SchedulingPreferences['recently_used_timezones']) => {
  const newTzOptions: TimeZoneOption[] = [];
  const popularOptions: TimeZoneOption[] = [];
  const recentlyUsedOptions: {[tzName: string]: TimeZoneOption} = {};

  allTimeZones.forEach(timezone => {
    const formats = formatTimezoneOption(timezone);
    const value = timezone.name;
    
    tzRenderOptionValueMap[timezone.name] = formats.medium;
    tzRenderShortOptionValueMap[timezone.name] = formats.short;

    const option: TimeZoneOption & {meta: Required<TimeZoneOption>['meta']} = {
      value,
      label: formats.long,
      meta: {
        allAbbreviations: timezone.allAbbreviations,
        mainCities: timezone.mainCities,
        group: "standard"
      }
    };

    if (recentlyUsed && recentlyUsed[value]) {
      recentlyUsedOptions[value] = {...option, meta: {...option.meta, group: "recent"}};
    }
    if (priorityTZList.has(value)) {
      // The list is in order of offset (i.e. GMT-11 first, GMT+14 last)
      popularOptions.push({...option, meta: {...option.meta, group: "popular"}});
    } 

    newTzOptions.push(option);
  });

  const recentlyUsedOptionsOrdered: TimeZoneOption[] = [];

  if (recentlyUsed) {
    // Reorder "recently used" timezones by reverse-frequency
    let recentlyUsedOrdered = Object.keys(recentlyUsed).map(tzName => ({name: tzName, ...recentlyUsed[tzName]}));
    recentlyUsedOrdered = recentlyUsedOrdered.sort((a, b) => (b.frequency - a.frequency));
      
    
    recentlyUsedOrdered.forEach(rtz => {
      if (recentlyUsedOptions[rtz.name]) {
        recentlyUsedOptionsOrdered.push(recentlyUsedOptions[rtz.name]);
      }
    });
  }

  return recentlyUsedOptionsOrdered.concat(popularOptions, newTzOptions);
};

const handleFilterOptions = (
  options: TimeZoneOption[], state: FilterOptionsState<TimeZoneOption>
): TimeZoneOption[] => {
  const lowerInput = state.inputValue.toLowerCase();
  return options.filter(option => {
    if (!lowerInput || option.label.toLowerCase().includes(lowerInput)) {
      return true;
    } else {
      if (option.meta?.allAbbreviations.some(abbr => abbr.toLowerCase().includes(lowerInput))) {
        return true;
      } else if (option.meta?.mainCities.some(city => city.toLowerCase().includes(lowerInput))) {
        return true;
      }
    }
    return false;
  });
};

export const CabTimezoneInput = ({
  required, onChange, value, defaultValue, sx, size, hideArrow, shortFormat, disabled, alignRight, 
  additionalOptions = [], id,
}: CabTimezoneInputProps): ReactElement => {

  const recentlyUsedTimezones = useSelector((root: RootState) => 
    root.schedule.schedulingPrefs.user_prefs?.recently_used_timezones);

  const options = useMemo(() => (
    [...additionalOptions, ...getTzOptions(recentlyUsedTimezones)]
  ), [additionalOptions, recentlyUsedTimezones]);

  return (
    <TimezoneAutocompleteStyled
      id={id}
      onChange={v => onChange(v as string)}
      options={options}
      getOptionLabel={option => {
        // Need to cast type here because it isn't inferred correctly. Technically
        //    it's still wrong as it doesn't provide the meta object, just value and label
        const o = option as TimeZoneOption;
        const optionValueMap = shortFormat ? tzRenderShortOptionValueMap : tzRenderOptionValueMap;
        return optionValueMap[o.value] ?? o.label;
      }}
      filterOptions={handleFilterOptions}
      value={value}
      defaultValue={defaultValue}
      renderInput={(params) => <TextField {...params} />}
      disabled={disabled}
      size={size}
      hideArrow={hideArrow}
      sx={sx}
      alignRight={alignRight}
      groupBy={option => option.meta?.group || "standard"}
      renderGroup={(params) => {
        let groupName: null | string = null;
        if (params.group === "standard") {
          groupName = "All Timezones";
        } else if (params.group === "recent") {
          groupName = "Recently Used";
        } else if (params.group === "popular") {
          groupName = "Popular Timezones";
        } else if (params.group === "automatic") {
          groupName = "Automatic";
        }

        return <li key={params.group}>
          {groupName && <GroupHeader variant="body2">{groupName}</GroupHeader>}
          <GroupItems>{params.children}</GroupItems>
        </li>;
      }}
    />
  );
};

const GroupHeader = styled(Typography, {label: "CabTimezone-GroupHeader"})(({ theme }) => ({
  padding: '4px 10px',
  color: theme.palette.primary.dark,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul', {label: "CabTimezone-GroupHeader"})({
  padding: 0,
});

const TimezoneAutocompleteStyled = styled<FC<CabAutocompleteProps<string, TimeZoneMeta>>>(
  CabAutocomplete, { 
    label: 'CabTimezoneAutocompleteStyled' 
  })(({theme, hideArrow}) => ({
  '& .MuiOutlinedInput-root':{
    height: '40px',
    paddingRight: hideArrow ? '6px !important' : '32px !important'
  },
})) as typeof CabAutocomplete;