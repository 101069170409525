import { createListenerMiddleware, configureStore, Tuple } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';
// import { createNormalizationMiddleware } from '@normy/rtk-query';

import rootReducer from './root-reducer';
import { cabinetApi } from './cabinetApi/api';
import { initialGlobalState } from './globalInitialState';
import { ActionType } from './actionTypes';
import { GlobalMessage } from './globalMessages/types';

// Since most of the data in this application is non-public, we don't want to report the state, and for most actions 
///  we just report the action type, not the data with it.
//   If necessary, we can identify specific actions for which we want the data reported
const sentryReduxEnhancer = createReduxEnhancer({
  actionTransformer: action => {
    return { type: action.type };
    // switch (action.type) {
    //   case ActionType.FETCHED_AIRLINES:
    //   ...
    //   case ActionType.FETCHING_HOTELS:
    //     return action;

    //   default:
    //     return {type: action.type};
    // }
  },
  stateTransformer: state => {
    return null;
  },
});

type RootState = typeof initialGlobalState;

// TODO: this type of simple middleware is discouraged in modern redux since we are not modifying or
// cancelling the action. Instead we should move this to `extraReducers` on the appropriate slice
const permissionDeniedHandler = createListenerMiddleware();
permissionDeniedHandler.startListening({
  type: ActionType.SET_FETCH_ERROR,
  effect: (action, { dispatch }) => {
    const message: GlobalMessage = {
      active: true,
      severity: "error",
      timeout: 10000,
      // @ts-expect-error can't infer error prop
      message: action.error.message,
      //onDismiss: () => dispatch({type: ActionType.SET_FETCH_ERROR, error: {message: "", status_code: 0}}),
      autoDismiss: true,
      header: "",
      position: {
        vertical: "bottom",
        horizontal: "left"
      }
    };
    dispatch({type: ActionType.SEND_MESSAGE, message});
  },
});


export const fabricateStore = (intitialStore?: RootState) => (
  configureStore({
    // @ts-expect-error this is due to type incompatibilties between our older redux definitions
    // and the newer expected ones. Should be fixed once we upgrade all slices to new format
    reducer: {
      [cabinetApi.reducerPath]: cabinetApi.reducer,
      ...rootReducer,
    },
    // @ts-expect-error same as above error
    middleware: (getDefaultMiddleware) => (
      // immutability middleware takes a while and spits out warnings if we don't increase the default `warnAfter`.
      // May want to look into why it's taking so long, but it could just be due to having a large state.
      getDefaultMiddleware({ immutableCheck: { warnAfter: 200 } })
        .prepend(permissionDeniedHandler.middleware)
        // can't use normalization library until we have unique IDs across types (prefix IDs with type names)
        // may need this for normalization lib -- @ts-expect-error related to typing in above error
        .concat(cabinetApi.middleware/*, createNormalizationMiddleware(cabinetApi)*/)
    ),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(new Tuple(sentryReduxEnhancer)),
    preloadedState: intitialStore,
  })
);



export default fabricateStore();
