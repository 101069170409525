import { fetchData, makeHeaders, makeParams } from "./utils/apiUtils";
import { API_URLS, OTHER_URLS } from "./apiUrls";
import {
  FetchReturn, IdentityResponse, MeetingFilter, NormalizedExternalParticipant,
  OrgUserAutocompleteOption, OrganizationLeader, ProfileCategory, SharedLeaderGrant, PrivateExternalParticipantUpdate,
  PrivateExternalParticipantCreate, PrivateExternalParticipant, MeetingLeaderUpdate, MeetingLeader,
  FetchMeetingsResponse
} from "./store";
import { 
  AnalyticsEvent,
  ConsolidatedLocation,
  ContactAnalysisResponse,
  CreatePollSelectionsResponse, EventAnalysisResponse, EventCategory, EventType, ExecutiveContact,
  ExecutiveContactCategory, MeetingRoom, NoSelectionCreate,
  Organization, OrganizationLicense, PollSelectionCreate, PollSelectionDelete,
  PollSelectionUpdate,
  FetchEventAnalysisPayload,
  FetchLeaderAnalysisQuery,
  FetchAnalyticsEventResponse,
  RecurrenceType,
  ExecutiveContactType,
  AnalyticsEventsMeta,
  AnalysisInclusion,
  AnalyticsEventColor
} from "./store";
import { PublicExternalParticipant } from "./store";
import { PaginationState } from "./utils/paginationUtils";
import { DEFAULT_PAGINATION_COUNT } from "./constants";
import { PermissionError } from "./utils/permissionUtils";


type Lookups = 'contains'|'icontains'|'eq'|'gt'|'gte'|'lt'|'lte'|'in';

type FilterKey<T extends string> = `${T}`|`${T}__${Lookups}`;
type OrderBy<T extends string> = `${T}`|`-${T}`;

export type FilterSortParams<T extends object> = {
  [k in FilterKey<Extract<keyof T, string>>]?: string | number | boolean | string[] | number[] | boolean[];
} & {
  topology?: 'and' | 'or';
  order_by?: OrderBy<Extract<keyof T, string>>[];
};

export class CabinetAPI {

  async listOrganizationLicenses(value: number): Promise<FetchReturn<{[id: number]: OrganizationLicense}>>  {
    const headers = await makeHeaders(true);
    const res = await fetchData<{[id: number]: OrganizationLicense}>(
      `${API_URLS.ORGANIZATION_LICENSES()}${value}/list-by-org/`, {headers, method: 'GET'}
    );
    return res;
  }

  async createOrganizationLicenses(
    value: {[id: number]: OrganizationLicense}
  ): Promise<FetchReturn<{created: {[id: number]: OrganizationLicense}}>> {
    const headers = await makeHeaders(true);
    const data = JSON.stringify(Object.values(value));
    const res = await fetchData<{created: {[id: number]: OrganizationLicense}}>(
      `${API_URLS.ORGANIZATION_LICENSES()}bulk-create/`, {headers, method: 'POST', body: data}
    );
    return res;
  }
  async bulkUpdateOrganizationLicenses(
    value: { [id: number]: Partial<OrganizationLicense> }
  ): Promise<FetchReturn<{updated: {[id: number]: OrganizationLicense}}>> {
    const headers = await makeHeaders(true);
    const data = JSON.stringify(Object.values(value));
    const res = await fetchData<{updated: {[id: number]: OrganizationLicense}}>(
      `${API_URLS.ORGANIZATION_LICENSES()}bulk-update/`, {headers, method: 'PATCH', body: data}
    );
    return res;
  }

  async deleteOrganizationLicenses(data: number[]): Promise<FetchReturn<{deleted: number[]}>> {
    const headers = await makeHeaders(true);
    const stringData = JSON.stringify(Object.values(data));
    const res = await fetchData<{deleted: number[]}>(
      `${API_URLS.ORGANIZATION_LICENSES()}bulk-delete/`, {headers, method: 'DELETE', body: stringData}
    );
    return res;
  }

  async updateOrganizationLicense(license: Partial<OrganizationLicense> & {id: number}) {
    const headers = await makeHeaders(true);
    const res = await fetchData<OrganizationLicense>(
      `${API_URLS.ORGANIZATION_LICENSES(license.id)}`, {headers, method: 'PATCH', body: JSON.stringify(license)}
    );
    return res;

  }

  async createMeetingExternalParticipant(
    participant: PrivateExternalParticipantCreate
  ): Promise<FetchReturn<PrivateExternalParticipant>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<PrivateExternalParticipant>(
      `${API_URLS.MEETING_EXTERNAL_PARTICIPANT}`, {headers, method: 'POST', body: JSON.stringify(participant)}
    );
    return res;
  }

  async fetchOrCreateMeetingExternalParticipant(
    participant: Omit<PublicExternalParticipant, "id" | "email_hash">
  ): Promise<FetchReturn<PublicExternalParticipant>> {
    const headers = await makeHeaders(false);
    const res = await fetchData<PublicExternalParticipant>(
      `${API_URLS.MEETING_EXTERNAL_PARTICIPANT}`, {headers, method: 'POST', body: JSON.stringify(participant)}
    );
    return res;
  }

  async fetchOrganization(id: number): Promise<FetchReturn<Organization>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<Organization>(
      `${API_URLS.ORGANIZATION(id)}`, {headers, method: 'GET'}
    );
    return res;
  }

  async fetchMeetingRooms(): Promise<FetchReturn<{[id: string]: MeetingRoom}>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<{[id: string]: MeetingRoom}>(
      `${API_URLS.MEETING_ROOMS}`, {headers, method: 'GET'}
    );
    return res;
  }

  async createMeetingPollSelections(externalId: string, selections: Array<PollSelectionCreate | NoSelectionCreate>,) {
    const headers = await makeHeaders(false);
    const body = JSON.stringify({ externalId, selections });
    const res = await fetchData<CreatePollSelectionsResponse>(
      API_URLS.POLL_SELECTIONS, { headers, body, method: 'POST' }
    );
    return res;
  }

  async deleteMeetingPollSelections(selections: PollSelectionDelete[]): Promise<FetchReturn> {
    const headers = await makeHeaders(false);
    const body = JSON.stringify(selections);
    const res = await fetchData(API_URLS.DELETE_POLL_SELECTIONS, { headers, body, method: 'POST' });
    return res;
  }

  async updateMeetingPollSelections(
    externalId: string, selections: PollSelectionUpdate[]
  ) : Promise<FetchReturn<CreatePollSelectionsResponse>> {
    const headers = await makeHeaders(false);
    const res = await fetchData<CreatePollSelectionsResponse>(
      API_URLS.UPDATE_POLL_SELECTIONS,
      {method: "PATCH", headers:headers, body: JSON.stringify({ externalId, selections })}
    );
    return res;
  }

  async deleteMeetingParticipant(participantId: number): Promise<FetchReturn> {
    const headers = await makeHeaders(true);
    const res = await fetchData(
      `${API_URLS.MEETING_EXTERNAL_PARTICIPANT}${participantId}/`,
      { headers, method: 'DELETE' },
    );
    return res;
  }

  async updateMeetingParticipant(
    data: PrivateExternalParticipantUpdate,
  ): Promise<FetchReturn> {
    const { id, ...payload } = data;
    const headers = await makeHeaders(true);
    // const body = JSON.stringify({ email, name, required });
    const body = JSON.stringify(payload);
    const res = await fetchData(
      `${API_URLS.MEETING_EXTERNAL_PARTICIPANT}${id}/`,
      { headers, body, method: 'PATCH' },
    );
    return res;
  }

  async updateMeetingLeader(data: MeetingLeaderUpdate) {
    const { id, ...payload } = data;
    const headers = await makeHeaders(true);
    const body = JSON.stringify(payload);
    const res = await fetchData<MeetingLeader>(
      `${API_URLS.MEETING_LEADER}${id}/`,
      { headers, body, method: 'PATCH' },
    );
    return res;
  }

  async cancelExternalMeeting(
    meetingId: number,
    meetingToken: string,
    message: string
  ): Promise<FetchReturn> {
    const headers = await makeHeaders(false);
    const body = JSON.stringify({
      meeting: meetingId,
      meeting_token: meetingToken,
      message: message
    });
    const res = await fetchData(
      `${API_URLS.CANCEL_EXTERNAL}`,
      { headers, body, method: 'POST' },
    );
    return res;
  }

  private eventAnalyticsPagination = new PaginationState(0, DEFAULT_PAGINATION_COUNT);

  async fetchAnalyticsEvent(
    page: number, paginationLimit: number, query: Record<string, string | string[] | undefined | number | number[]>

  ): Promise<FetchReturn<FetchAnalyticsEventResponse, PermissionError> | undefined> {
    const headers = await makeHeaders(true);
    
    const {limit, offset} = this.eventAnalyticsPagination.getOffsetLimit(
      page,
      DEFAULT_PAGINATION_COUNT,
      JSON.stringify(query)
    );

    if (limit !== null && offset !== null) {
      const params = makeParams({
        ...query,
        limit: limit,
        offset: offset,
      });

      const res = await fetchData<FetchAnalyticsEventResponse, PermissionError>(
        `${API_URLS.FETCH_ANALYTICS_EVENTS()}` + params,
        { headers },
      );
      return res;
    }
    return undefined;
  }

  async fetchAnalyticsEventUnpaginated(
    query: Record<string, string | string[] | undefined | number | number[]>
  ): Promise<FetchReturn<
      {data: {[key: number]: AnalyticsEvent}, meta: {contacts: {[key: number]: ExecutiveContact}}},
      PermissionError
    >> {
    const headers = await makeHeaders(true);
    const params = makeParams(query);
    const res = await fetchData<
      {data: {[key: number]: AnalyticsEvent}, meta: {contacts: {[key: number]: ExecutiveContact}}},
      PermissionError>(
      `${API_URLS.FETCH_ANALYTICS_EVENTS()}` + params,
      { headers },
    );
    return res;
  }

  async updateAnalyticsEvent(
    data: Partial<AnalyticsEvent> & Pick<AnalyticsEvent, 'id'>, updateAll: boolean
  ): Promise<FetchReturn<{[key: number]: AnalyticsEvent}, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<{[key: number]: AnalyticsEvent}, PermissionError>(
      `${API_URLS.FETCH_ANALYTICS_EVENTS(data.id)}`,
      {
        headers,
        body: JSON.stringify({...data, update_all: updateAll}),
        method: "PATCH"
      },
    );
    return res;
  }

  async updateAnalyticsExecutiveContact(
    data: Partial<ExecutiveContact> & {id: number}
  ): Promise<FetchReturn<ExecutiveContact, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<ExecutiveContact, PermissionError>(
      `${API_URLS.FETCH_ANALYTICS_EXECUTIVE_CONTACT(data.id)}`,
      {
        headers,
        body: JSON.stringify(data),
        method: "PATCH"
      },
    );
    return res;
  }

  async fetchConsolidateLocations(): Promise<
    FetchReturn<{[key: string]: ConsolidatedLocation}, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<{[key: string]: ConsolidatedLocation}, PermissionError>(
      `${API_URLS.FETCH_CONSOLIDATED_LOCATIONS}`,
      { headers },
    );
    return res;
  }

  async fetchRecurrenceTypes(): Promise<
    FetchReturn<{[key: string]: RecurrenceType}, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<{[key: string]: RecurrenceType}, PermissionError>(
      `${API_URLS.FETCH_RECURRENCE_TYPES}`,
      { headers },
    );
    return res;
  }

  async fetchAnalyticsEventCategories(): Promise<
    FetchReturn<{[key: string]: EventCategory}, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<{[key: string]: EventCategory}, PermissionError>(
      `${API_URLS.FETCH_EVENT_CATEGORIES()}`,
      { headers },
    );
    return res;
  }

  async createAnalyticsEventCategory(data: EventCategory): Promise<
    FetchReturn<EventCategory, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<EventCategory, PermissionError>(
      `${API_URLS.FETCH_EVENT_CATEGORIES()}`,
      { 
        headers,
        body: JSON.stringify(data),
        method: "POST"
      },
    );
    return res;
  }

  async updateAnalyticsEventCategory(data: Partial<EventCategory> & Pick<EventCategory, 'id'>): 
  Promise<FetchReturn<EventCategory, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<EventCategory, PermissionError>(
      `${API_URLS.FETCH_EVENT_CATEGORIES(data.id)}`,
      { 
        headers,
        body: JSON.stringify(data),
        method: "PATCH"
      },
    );
    return res;
  }

  async deleteAnalyticsEventCategory(categoryId: number): 
  Promise<FetchReturn<EventCategory, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<EventCategory, PermissionError>(
      `${API_URLS.FETCH_EVENT_CATEGORIES(categoryId)}`,
      { 
        headers,
        method: "DELETE"
      },
    );
    return res;
  }

  async fetchAnalyticsEventTypes(): Promise<
    FetchReturn<{[key: string]: EventType}, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<{[key: string]: EventType}, PermissionError>(
      `${API_URLS.FETCH_EVENT_TYPES()}`,
      { headers },
    );
    return res;
  }
  async createAnalyticsEventType(data: EventType): Promise<
    FetchReturn<EventType, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<EventType, PermissionError>(
      `${API_URLS.FETCH_EVENT_TYPES()}`,
      { 
        headers,
        body: JSON.stringify(data),
        method: "POST"
      },
    );
    return res;
  }

  async updateAnalyticsEventType(data: Partial<EventType> & Pick<EventType, 'id'>): 
  Promise<FetchReturn<EventType, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<EventType, PermissionError>(
      `${API_URLS.FETCH_EVENT_TYPES(data.id)}`,
      { 
        headers,
        body: JSON.stringify(data),
        method: "PATCH"
      },
    );
    return res;
  }

  async deleteAnalyticsEventType(typeId: number): 
  Promise<FetchReturn<EventType, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<EventType, PermissionError>(
      `${API_URLS.FETCH_EVENT_TYPES(typeId)}`,
      { 
        headers,
        method: "DELETE"
      },
    );
    return res;
  }

  async fetchAnalyticsExecutiveContactCategory(): Promise<
    FetchReturn<{[key: string]: ExecutiveContactCategory}, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<
      {[key: string]: ExecutiveContactCategory}, PermissionError>(
      `${API_URLS.FETCH_EXECUTIVE_CONTACT_CATEGORIES()}`,
      { headers },
    );
    return res;
  }

  async createAnalyticsExecutiveContactCategory(
    data: ExecutiveContactCategory
  ): Promise<FetchReturn<ExecutiveContactCategory, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<ExecutiveContactCategory, PermissionError>(
      `${API_URLS.FETCH_EXECUTIVE_CONTACT_CATEGORIES()}`,
      { 
        headers,
        body: JSON.stringify(data),
        method: "POST"
      },
    );
    return res;
  }

  async updateAnalyticsExecutiveContactCategory(data: Partial<ExecutiveContactCategory> 
  & Pick<ExecutiveContactCategory, 'id'>): 
    Promise<FetchReturn<ExecutiveContactCategory, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<ExecutiveContactCategory, PermissionError>(
      `${API_URLS.FETCH_EXECUTIVE_CONTACT_CATEGORIES(data.id)}`,
      { 
        headers,
        body: JSON.stringify(data),
        method: "PATCH"
      },
    );
    return res;
  }

  async deleteAnalyticsExecutiveContactCategory(categoryId: number): 
  Promise<FetchReturn<ExecutiveContactCategory, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<ExecutiveContactCategory, PermissionError>(
      `${API_URLS.FETCH_EXECUTIVE_CONTACT_CATEGORIES(categoryId)}`,
      { 
        headers,
        method: "DELETE"
      },
    );
    return res;
  }

  async fetchEventAnalysis(
    data: FetchEventAnalysisPayload
  ): Promise<FetchReturn<EventAnalysisResponse, PermissionError>> {
    const headers = await makeHeaders(true);
    const res = await fetchData<EventAnalysisResponse, PermissionError>(
      `${API_URLS.FETCH_EVENT_ANALYSIS}`,
      { 
        headers,
        body: JSON.stringify(data),
        method: "POST"
      },
    );
    return res;
  }

  private contactAnalysisPagination = new PaginationState(0, DEFAULT_PAGINATION_COUNT);

  async fetchContactAnalysis(
    data: FetchLeaderAnalysisQuery
  ): Promise<FetchReturn<ContactAnalysisResponse, PermissionError> | undefined> {
    const headers = await makeHeaders(true);
    const {limit, offset} = this.contactAnalysisPagination.getOffsetLimit(
      0, DEFAULT_PAGINATION_COUNT, JSON.stringify(data)
    );
    if (limit && offset !== null) {
      const res = await fetchData<ContactAnalysisResponse, PermissionError>(
        `${API_URLS.FETCH_CONTACT_ANALYSIS}`,
        { 
          headers,
          body: JSON.stringify({...data, limit, offset}),
          method: "POST"
        },
      );
      return res;
    }
  }

  async fetchLatestVersion() {
    try {
      const headers = await makeHeaders(false);
      // This is used outside the core app context, so just using basic fetch instead of fetchData
      const res = await fetch(
        `${OTHER_URLS.FETCH_LATEST_VERSION}`,
        {
          headers,
          method: "GET"
        },
      );
      if (res.status === 200) {
        return await res.text();
      }
    } catch (e) {
      // pass
    }
    return import.meta.env.VITE_APP_FRONTEND_VERSION;
  }
  async fetchAnalyticsExecutiveContactTypes() {
    const headers = await makeHeaders(true);
    const res = await fetchData<{[key: string]: ExecutiveContactType}, PermissionError>(
      API_URLS.FETCH_EXECUIVE_CONTACT_TYPES,
      {
        headers: headers,
        method: "GET"
      }
    );
    return res;
  }

  async fetchAnalyticsAnalysisInclusions() {
    const headers = await makeHeaders(true);
    const res = await fetchData<{[key: string]: AnalysisInclusion}, PermissionError>(
      API_URLS.FETCH_ANALYSIS_INCLUSIONS,
      {
        headers: headers,
        method: "GET"
      }
    );
    return res;
  }

  private meetingPagination = new PaginationState(0, 20);


  async resyncAnalyticsEvents() {
    const headers = await makeHeaders(true);
    const res = await fetchData<
      Omit<AnalyticsEventsMeta, "min_start_date" | "max_end_date">, PermissionError>(
      API_URLS.FETCH_ANALYTICS_EVENTS() + "resync/",
      {
        headers: headers,
        method: "GET"
      }
    );
    return res;
  }

  async fetchAnalyticsEventColors(
    colorGridFilterParameters: Record<string, string | string[] | undefined | number | number[]>
  ) {
    const headers = await makeHeaders(true);
    const params = makeParams(colorGridFilterParameters);
    const res = await fetchData<
      {data: {[key: string]: AnalyticsEventColor}}, PermissionError>(
      API_URLS.FETCH_ANALYTICS_COLORS() + params,
      {
        headers: headers,
        method: "GET"
      }
    );
    return res;
  }

  async updateAnalyticsEventColor(data: Partial<AnalyticsEventColor> & {id: number}) {
    const headers = await makeHeaders(true);
    const res = await fetchData<AnalyticsEventColor, PermissionError>(
      API_URLS.FETCH_ANALYTICS_COLORS(data.id),
      {
        headers: headers,
        method: "PATCH",
        body: JSON.stringify(data)
      }
    );
    return res;
  }

  async fetchOrgUserAutocompleteOptions(q?: string): Promise<
    FetchReturn<OrgUserAutocompleteOption[], PermissionError>> {
    const headers = await makeHeaders(true);
    const params = makeParams({q});
    return await fetchData<OrgUserAutocompleteOption[], PermissionError>(
      API_URLS.ORG_USER_AUTOCOMPLETE + params, {headers, method: 'GET'}
    );
  }

  async fetchMeetings(
    query: Partial<MeetingFilter>,
    checkMeetingIds?: number[], page?: number,
  ) {
    const headers = await makeHeaders(true);
    const paramsObject = {
      ...query,
      ...(checkMeetingIds ? { check_meeting_id_list: checkMeetingIds.join(',') } : {}),
      include_removed: 1,
    };

    let params: string | undefined;

    if (page != null) {
      const { limit, offset } = this.meetingPagination.getOffsetLimit(page, 20, JSON.stringify(query));
      if (limit != null && offset != null) {
        params = makeParams({
          ...paramsObject,
          limit: limit,
          offset: offset,
        });
      }
    } else {
      params = makeParams(paramsObject);
    }

    if (params) {
      const res = await fetchData<FetchMeetingsResponse>(API_URLS.MEETINGS + params, { headers, method: 'GET' });
      return res;
    }
  }

  async changeEmail(newEmail: string) {
    const headers = await makeHeaders(true);
    return await fetchData<{detail: {changed_email: string}}>(
      API_URLS.CHANGE_EMAIL, { 
        headers,
        method: 'POST',
        body: JSON.stringify({
          email: newEmail
        })
      });
  }

  async sendNoTimesWork(data: NormalizedExternalParticipant) {
    const headers = await makeHeaders(false);
    return await fetchData<{detail: {message: string}}>(
      API_URLS.MEETING_EXTERNAL_PARTICIPANT + "no-times-work/", { 
        headers,
        method: 'POST',
        body: JSON.stringify(data)
      });
  }

  async fetchOrganizationLeaders() {
    const headers = await makeHeaders(true);
    return await fetchData<{data: OrganizationLeader[]}>(
      API_URLS.LEADERS + "admin-list/", { 
        headers,
        method: 'GET'
      });
  }
  async fetchOrganizationSharedLeaderGrants() {
    const headers = await makeHeaders(true);
    return await fetchData<{data: SharedLeaderGrant[]}>(
      API_URLS.LEADERS + "admin-shared-leader-grants/", { 
        headers,
        method: 'GET'
      });
  }
  async fetchOrganizationLeaderProfileCategory() {
    const headers = await makeHeaders(true);
    return await fetchData<{data: {[key: number]: ProfileCategory}}>(
      API_URLS.LEADERS + "admin-leader-profile-categories/", { 
        headers,
        method: 'GET'
      });
  }
  async resendLicenseInvite(license: OrganizationLicense) {
    const headers = await makeHeaders(true);
    return await fetchData<{detail: string}>(
      API_URLS.ORGANIZATION_LICENSES(license.id) + "resend-invite/", { 
        headers,
        method: 'GET'
      });
  }
  async checkIdentity(email: string) {
    const headers = await makeHeaders(false);
    return await fetchData<IdentityResponse>(
      API_URLS.AUTH_IDENTITY + `?email=${email}`, { 
        headers,
        method: 'GET'
      });
  }
  async bulkUpsertMeetingLeaders(meetingLeaders: MeetingLeader[]) {
    const headers = await makeHeaders(true);
    
    return fetchData<{ data: MeetingLeader[] }>(
      API_URLS.MEETING_LEADER + "bulk-update-or-create/", { 
        headers,
        method: 'POST',
        body: JSON.stringify(meetingLeaders.map((meetingLeaderItr) => {
          if (meetingLeaderItr.id < 0) {
            const {id, ...cleanData} = meetingLeaderItr;
            return cleanData;
          } else {
            return meetingLeaderItr;
          }
        }))
      });
  }
  async listMeetingParticipants(params: {meeting_external_id?: string, preregistered?: boolean}) {
    const headers = await makeHeaders(true);
    const stringParams = makeParams(params);
    return await fetchData<PublicExternalParticipant[]>(
      API_URLS.MEETING_EXTERNAL_PARTICIPANT + stringParams, { 
        headers,
        method: 'GET',
      });
  }
}

const api = new CabinetAPI();

export default api;