import { ReactElement, useState, useEffect } from 'react';
import { RootState } from '../store';

import { GlobalMessage } from '../store/globalMessages/types';
import { getGlobalMessageTemplate } from '../store/templates';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { Portal } from "@mui/base";
import { useSelector } from 'react-redux';
import { useWithDispatch } from '../store/hooks';
import { removeMessage } from '../store/globalMessages/actions';

export const GlobalToast = (): ReactElement => {
  
  const messages = useSelector((root: RootState) => root.globalMessages);
  const handleRemoveMessage = useWithDispatch(removeMessage);
  
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState<GlobalMessage>(getGlobalMessageTemplate());


  useEffect(() => {
    if (messages.length > 0) {
      setMessage(messages[0]);
      setShow(true);
    }
  }, [messages]);

  const handleDismiss = (): void => {
    setShow(false);
    if (message.autoDismiss) {
      handleRemoveMessage();
    }
  };
  
  return (
    // Wrapping with Portal is necessary to show message above any modal/dialog backdrops
    <Portal>
      <Snackbar  
        open={message.active && show}
        autoHideDuration={message.timeout}
        onClose={message.autoDismiss ? handleDismiss : undefined}
        anchorOrigin={message.position}
      >
        <Alert 
          onClose={handleDismiss}
          severity={message.severity} 
          action={message.action} sx={{zIndex: 999999}}
        >
          {message.header && <AlertTitle>{message.header}</AlertTitle>}
          {message.message}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

export default GlobalToast;