import React, { ReactElement, useCallback, useState } from 'react';
import { Paper, styled } from '@mui/material';
import { MAINTENANCE_GRAPHIC } from '../resourceUrls';
import { DateTime } from 'luxon';
import { getLocalTimeZone } from '../utils/scheduleUtils';
import { useMountEffect } from '../utils/hooks';
import { CabButton } from '@CabComponents/CabButton';

// Check if maintenance is done every 15 minutes
const CHECK_UPDATE_INTERVAL = 1000 * 60 * 15;

const reloadRoot = () => window.location.replace("/");

interface MaintenanceState {
  message?: string;
  expectedResolutionTime?: string;
}

export const MaintenanceMode = (): ReactElement => {
  const [showRefresh, setShowRefresh] = useState(false);

  const localTz = getLocalTimeZone();
  const localTzAbbr = localTz?.abbreviation || "GMT";
  const localTzName = localTz?.name || "UTC";
  const offsetMinutes = localTz?.currentTimeOffsetInMinutes || 0;
  const offsetHours = offsetMinutes / 60;
  const tzDisplay = `${localTzAbbr} (GMT${offsetHours >= 0 ? '+' : ''}${offsetHours})`;

  const maintenanceStateStr = sessionStorage.getItem('maintenance_state');
  const maintenanceState: MaintenanceState = maintenanceStateStr ? JSON.parse(maintenanceStateStr) : null;
  const expectedResolutionTime = maintenanceState?.expectedResolutionTime;

  // In case we don't have an expected resolution time, show resolution in 30 minutes, rounded to a 30 minute interval.
  const now = DateTime.now();
  const twentyMinutesLater = now.plus({ minutes: 30 });
  const defaultResolutionTime = twentyMinutesLater.plus({ minutes: 30 - (twentyMinutesLater.minute % 30) });

  const resolutionTime = expectedResolutionTime 
    ? DateTime.fromISO(expectedResolutionTime, { zone: localTzName }) 
    : defaultResolutionTime;
  
  let dateDisplay = '';

  if (resolutionTime) {
    dateDisplay = resolutionTime.toFormat('MMM dd yyyy @ hh:mma') + ` ${tzDisplay}`;
  }

  // Check if the current time is greater than expected resolution time and show refresh
  const checkResolutionTime = useCallback(() => {
    if (resolutionTime && DateTime.local() > resolutionTime) {
      setShowRefresh(true);
    }
  }, [resolutionTime]);

  useMountEffect(() => {
    checkResolutionTime();

    // Automatically refresh the root page every so often to pull users out of maintenance if it's done
    setInterval(() => {
      reloadRoot();
    }, CHECK_UPDATE_INTERVAL);
  
    setInterval(() => {
      checkResolutionTime();
    }, 5000);

  });


  return (
    <Paper sx={{ height: '100%' }}>
      <title>Cabinet is down for maintenance</title>
      <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{display: 'grid', textAlign: 'center', alignItems: 'center'}}>
          <h1>We'll be right back!</h1>
          <StyledImg src={MAINTENANCE_GRAPHIC} alt={'MAINTENANCE'}/>
          {window.history.state?.message
            ? <p>{window.history.state.message}</p>
            : <p>Maintenance on Cabinet is currently in progress. Please check back soon!</p>
          } 
          {showRefresh 
            ? <CabButton buttonType='primary' onClick={reloadRoot} sx={{width: "200px", margin: "auto"}}>
              Refresh Cabinet
            </CabButton>
            : dateDisplay && (
              <p>Cabinet should be up and running by <b>{dateDisplay}</b></p>
            )
          }
        </div>
      </div>
    </Paper>
  );
};

const StyledImg = styled('img', {label: 'StyledImg'})(({ theme }) => ({
  maxHeight: "auto",
  marginRight: "auto",
  marginLeft: "auto",
  textAlign: "center",
  width: "50%",
  height: "auto",
}));

export default MaintenanceMode;