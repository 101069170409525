import { ReactElement, useCallback, useMemo } from 'react';
import { GlobalModal } from './GlobalModal';
import CabinetPromo from './CabinetPromo';
import { actions, GlobalModalComponentName, RootState, ThunkDispatchType } from '../../store';

import { useDispatch, useSelector } from 'react-redux';
import TrialExpired from './TrialExpired';


export const GlobalModalContainer = (): ReactElement => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const closeModal = useCallback(() => dispatch(actions.globalModal.closeModal()), [dispatch]);
  const {open, componentName} = useSelector((root: RootState) => root.globalModal );
  
  const component = useMemo(() => {
    switch (componentName) {
      case GlobalModalComponentName.CABINET_PROMO:
        return <CabinetPromo onClose={closeModal}/>;
      case GlobalModalComponentName.TRIAL_EXPIRED:
        return <TrialExpired />;
      default:
        return <CabinetPromo onClose={closeModal}/>;
    }
  }, [closeModal, componentName]);
  
  return <GlobalModal open={open} component={component} onClose={closeModal}/>;
};

export default GlobalModalContainer;