import { ReactElement } from "react";
import { FormControl, InputAdornment, SxProps, TextFieldProps } from "@mui/material";
import { CabComponentProps } from "../cabStyled";
import { CabInput } from "../CabInput";

export interface CabTextInputProps extends CabComponentProps<TextFieldProps>{
  autoComplete?: TextFieldProps['autoComplete'];
  disabled?: TextFieldProps['disabled'];
  required?: TextFieldProps['required'];
  label?: TextFieldProps['label'];
  placeholder?: TextFieldProps['placeholder'];
  value?: TextFieldProps['value'];
  onChange?: TextFieldProps['onChange'];
  variant?: TextFieldProps['variant'];
  focused?: TextFieldProps['focused'];
  type?: TextFieldProps['type'];
  fullWidth?: TextFieldProps['fullWidth'];
  leftIcon?: ReactElement;
  endIcon?: ReactElement;
  inputMode?: TextFieldProps['inputMode'];
  inputProps?: TextFieldProps['inputProps']
  InputProps?: TextFieldProps['InputProps']
  inputRef?: TextFieldProps['inputRef'];
  multiline?: TextFieldProps['multiline'];
  minRows?: TextFieldProps['minRows'];
  maxRows?: TextFieldProps['maxRows'];
  size?: TextFieldProps['size'] | "xsmall" | "text";
  maxLength?: number;
  formControlSx?: SxProps;
  onBlur?: TextFieldProps["onBlur"];
  onKeyDown?: TextFieldProps["onKeyDown"];
  onClick?: TextFieldProps["onClick"];
  onFocus?: TextFieldProps["onFocus"];
  id?: TextFieldProps["id"];
}

export const CabTextInput = ({
  autoComplete, required, disabled, label, placeholder, type, value, focused, inputMode, onChange, leftIcon, endIcon, 
  variant, inputProps, InputProps, inputRef, fullWidth, maxLength, overrides, sx, multiline, minRows, maxRows,
  formControlSx, onClick, onFocus, id,
  onBlur, onKeyDown, size = "small",
  ...props
}: CabTextInputProps): ReactElement => {
  const xsmall = size === "xsmall";
  const text = size === "text";

  const cabInputProps: TextFieldProps = {
    autoComplete,
    disabled,
    required,
    label,
    placeholder,
    value,
    inputMode,
    variant,
    focused,
    type,
    fullWidth,
    onChange,
    onBlur,
    onKeyDown,
    sx,
    size: xsmall || text ? "small" : size,
    inputProps,
    InputProps,
    inputRef,
    multiline,
    minRows,
    maxRows,
    onClick,
    onFocus
  };

  return (
    <FormControl {...props} sx={formControlSx} fullWidth={fullWidth} id={id}>
      {
        leftIcon || endIcon
          ? leftIcon
            ? <CabInput {...cabInputProps} {...overrides} xsmall={xsmall} text={text}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {leftIcon}
                  </InputAdornment>
                ),
                ...cabInputProps.InputProps,
              }}
              inputProps={{
                maxLength: maxLength,
                ...cabInputProps.inputProps,
              }}
            /> 
            : <CabInput {...cabInputProps} {...overrides} xsmall={xsmall} text={text}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {endIcon}
                  </InputAdornment>
                ),
                ...cabInputProps.InputProps,
              }}
              inputProps={{
                maxLength: maxLength,
                ...cabInputProps.inputProps,
              }}
            /> 
          : <CabInput {...cabInputProps} {...overrides} xsmall={xsmall} text={text}
            inputProps={{
              maxLength: maxLength,
              ...cabInputProps.inputProps,
            }}
          />
      }
    </FormControl>
  );
};
