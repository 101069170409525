import { ActionType } from '../actionTypes';
import { ThunkResult, ThunkDispatchType } from '../types';
import { GlobalMessage } from './types';

export const sendMessage = (message: GlobalMessage): ThunkResult<Promise<void>> =>
  async ( dispatch: ThunkDispatchType ): Promise<void> => {
    dispatch({type: ActionType.SEND_MESSAGE, message: message});
    
  };

export const removeMessage = (): ThunkResult<Promise<void>> =>
  async ( dispatch: ThunkDispatchType ): Promise<void> => {
    dispatch({type: ActionType.REMOVE_MESSAGE});
  };



