
export type OffsetLimitState = { 
  limit: number | null,
  offset: number | null
};

export class PaginationState {
  fetchedPages: number[];
  paginationLimit: number;
  page: number | null;
  query: string;

  constructor(page: number, paginationLimit: number) {
    this.fetchedPages = [];
    this.paginationLimit = paginationLimit;
    this.page = page;
    this.query = "";
  }

  private _getLimitOffset(page: number, limit: number): OffsetLimitState {
    if (this.fetchedPages.includes(page)) {
      return {
        limit: null,
        offset: null,
      };
    }
    this.fetchedPages.push(page);
    return {
      limit: this.paginationLimit,
      offset: this.paginationLimit * page,
    };
  }

  private _recalculateFetchPages(limit: number) {
    throw Error("Implementing this logic later");
  }

  getOffsetLimit(
    page: number, limit: number, query: string
  ): OffsetLimitState {
    if (query !== this.query) {
      this.fetchedPages = [];
    } else if (page === 0) {
      this.fetchedPages = [];
    }

    this.query = query;
    if (limit !== this.paginationLimit) {
      page = 0;
      //this._recalculateFetchPages(limit);
    }
    return this._getLimitOffset(page, limit);
  }
}
