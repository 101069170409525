const greens = {
  BorealForest100: '#D0E5DB',
  BorealForest200: '#A6C8B7',
  BorealForest300: '#73AE93',
  BorealForest400: '#27966D',
  BorealForest500: '#128160',
  BorealForest600: '#007854',
  BorealForest700: '#005A3F',
  BorealForest800: '#003D2B',
  BorealForest900: '#002318',
  mint100: '#FBFEFC',
  mint200: '#F7FEFA',
  mint300: '#EFFDF5',
  mint400: '#E7FCF0',
  mint500: '#DEFBEB',
  mint600: '#CDECDB',
  mint700: '#B1CCBE',
  mint800: '#91A79B',
  mint900: '#66766D',
};

export const colors = {
  redError: '#E21616',
  black900: '#2A2A39',
  black800: '#50505D',
  black700: '#6C6C76',
  black600: '#83838C',
  black500: '#9D9DA4',
  black400: '#B9B9BE',
  black300: '#CCCCCF',
  black200: '#DFDFE1',
  black100: '#EEEEEF',
  black50: '#F6F6F7',
  white900: '#FFFFFF',
  white800: '#DDDDDF',
  white700: '#C1C1C6',
  white600: '#A6A6AC',
  white500: '#8C8C94',
  white400: '#72727C',
  white300: '#5B5B67',
  white200: '#484855',
  white100: '#2A2A39',
  white50: '#333341',
  purplePrimary: '#8282D8',
  purpleDark: '#4D4D80',
  purpleLight: '#F0F0FC',
  greenPrimary: greens.BorealForest500,
  greenDark: greens.BorealForest700,
  greenLight: greens.BorealForest300,
  darkThemeGreenPrimary: greens.BorealForest400,
  coralPrimary: '#FF8F6D',
  coralLight: '#F99C80',
  neutral: '#F0F0F2',
  navyDark: '#252D49',
  navyPrimary: '#3A4877',
  navyLight: '#4F66A5',
  blueLight: '#5CB9F2',
  greyBackdrop: '#FAFAFD',
  lightBackgroundGreen: greens.mint300,
  
};

export default colors;