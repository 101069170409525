import {
  getCurrentScope as getSentryScope,
} from '@sentry/react';

import { Device } from '@capacitor/device';

import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga';
// import { App } from '@capacitor/app';

import { CapacitorException, ExceptionCode } from '@capacitor/core';

import { cabCaptureException } from './utils/logging';
import { getPlatforms } from './utils/platformUtils';

export const initAnalytics = (): void => {
  if (import.meta.env.VITE_MIXPANEL_TOKEN) {
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN);
    window.MIXPANEL_ENABLED = true;
  } else {
    window.MIXPANEL_ENABLED = false;
  }

  // Using GA just for attribution tracking and real-time view of traffic
  if (import.meta.env.VITE_GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_ID);
  }
};


export const configureDeviceMeta = async (): Promise<void> => {
  const deviceInfo = await Device.getInfo().then(info => {
    return info;
  }).catch((e: CapacitorException) => {
    if (e.code !== ExceptionCode.Unimplemented) {
      cabCaptureException(e);
    }
    return null;
  });
  // We were using appInfo to get the appVersion but that's sort of redundant since we were
  //   using the env var as a backup, which is more universal across web and mobile.
  // Leaving this code here because if we do need it, it needs to have this exception handler.
  // const appInfo = await App.getInfo().then((appInfo) => {
  //   return appInfo;
  // }).catch((e: CapacitorException) => {
  //   if (e.code !== ExceptionCode.Unimplemented) {
  //     captureException(e);
  //   }
  //   return null;
  // });    
    
  const deviceMeta = {
    appVersion: import.meta.env.VITE_APP_FRONTEND_VERSION, 
    osVersion: deviceInfo?.osVersion, 
    platform: deviceInfo?.platform,
    allPlatforms: getPlatforms().join(',') || 'unknown',
    model: deviceInfo?.model
  };

  const sentryScope = getSentryScope();
  sentryScope.setTags(deviceMeta);

  if (window.MIXPANEL_ENABLED) {
    mixpanel.register(deviceMeta);
  }
};

export const checkIsMobileWeb = (): boolean => {
  return function() {
    let check = false;
    (function(a) {
      // eslint-disable-next-line no-useless-escape, max-len
      if (/(android|bb\d+|meego|ipad).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }();
};

export const browserCompatibilityCheck = (): boolean => {
  // Always redirect to the community for all users on IE/Edge
  const userAgentString = navigator.userAgent;
  // IE 8, 9, and 10 use the "MSIE" user agent string
  const isOldIE = userAgentString.search("MSIE ") > -1;
  // IE 11 uses the "Trident" user agent string
  const isIE11 = userAgentString.search("Trident/") > -1;
  // The old non-Chromium version of Edge uses the "Edge" user agent string
  const isEdge = userAgentString.search("Edge/") > -1;
  if (isOldIE || isIE11 || isEdge) {
    console.log(`Browser incompatible - redirecting to https://${import.meta.env.VITE_COMMUNITY_HOST}`);
    // Make sure we only alert the user once per week instead of every time
    const alertTimeKey = 'cab_browserIncompatibleLastAlertTime';
    const lastAlertTime = localStorage.getItem(alertTimeKey);
    if (!lastAlertTime || Date.now() > (parseInt(lastAlertTime) + 1000 * 60 * 60 * 24 * 7)) {
      localStorage.setItem(alertTimeKey, Date.now().toString());
      alert("Cabinet Pro currently works on Chrome, Firefox, and Safari. " +
        "We're hoping to support IE and Edge very soon! For now, click OK " +
        "to be redirected to the Cabinet Community which works on all browsers.");
    }
    window.location.replace(`https://${import.meta.env.VITE_COMMUNITY_HOST}`);
    return false;
  }
  return true;
};

// This function was taken from Google Tag Manager docs and modified slightly to 
//   be removed from inline JS in the index.html file. Typing and functionality are 
//   not well understood, but this function should never need to be altered.
export const googleTagManagerStart = (
  /* eslint-disable @typescript-eslint/no-explicit-any */
  w: any, // Should be Window but there are resulting type errors
  d: any, // Should be Document but there are resulting type errors
  s: any, 
  l: string, 
  i: string
  /* eslint-enable @typescript-eslint/no-explicit-any */
): void => {
  w[l] = w[l] || [];
  w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
  const f = d.getElementsByTagName(s)[0];
  const j = d.createElement(s);
  const dl = l !== 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
};